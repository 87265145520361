import { createStyles } from "@mui/material";
import { Theme } from "Theme";

export const styles = () =>
  createStyles({
    "@global": {
      a: {
        color: "inherit",
        textDecoration: "none",
      },
      body: {
        padding: 0,
        margin: 0,
        fontFamily: "'Be Vietnam Pro', sans-serif",
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        color: `${Theme.palette.secondary.main} !important`,
      },
      "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
        backgroundColor: `${Theme.palette.secondary.contrastText} !important`,
        opacity: "1 !important",
      },
      "& .MuiCheckbox-root.Mui-checked": {
        color: `${Theme.palette.secondary.contrastText} !important`,
      },
      "& .grp-table": {
        "& .MuiTablePagination-actions": {
          "& svg": {
            color: "#fff",
          },
        },
      },
    },
    authLayoutMainWrap: {
      position: "relative",
      padding: "0 !important",
      overflow: "hidden",

      "& .section-content": {
        position: "relative",
        width: "100%",
        height: "100vh",

        "& .form-container": {
          position: "relative",
          width: "100%",
          height: "100%",

          "& .form-holder": {
            position: "relative",
            width: "100%",
            height: "100%",

            "& .left-part": {
              position: "relative",
              backgroundColor: Theme.palette.secondary.light,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
              padding: "20px 80px",

              "& .top-pattern": {
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                zIndex: 0,

                "&.top-pattern img": {
                  position: "relative",
                  width: "115%",
                },
              },
              "& .bottom-pattern": {
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                zIndex: 0,

                "&.bottom-pattern img": {
                  position: "relative",
                  width: "80%",
                },
              },
              "& .left-inner": {
                position: "relative",
                // width: 'max-width',
                zIndex: 5,

                "& .top-logo": {
                  position: "relative",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",

                  "&.top-logo img": {
                    position: "relative",
                    width: "250px",
                    height: "auto",
                  },
                },
              },
            },
            "& .right-part": {
              position: "relative",
              height: "100%",
              flex: 1,
              padding: "20px 80px",

              "& .sec-bg": {
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",

                "&.sec-bg img": {
                  position: "relative",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                },
              },
              "& .sec-inner": {
                position: "relative",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",

                "& .inner-items": {
                  position: "relative",
                  top: "-106px",
                  "@media(max-width: 800px)": {
                    marginBottom: "257px",
                  },

                  "& .h1": {
                    position: "relative",
                    fontSize: "48px",
                    fontWeight: 700,
                    lineHeight: 1,
                    color: "#f9f9f9",

                    "&::after": {
                      content: '""',
                      position: "absolute",
                      bottom: "-18px",
                      left: 0,
                      width: "370px",
                      height: "4px",
                      backgroundColor: "#EE8234",
                      borderRadius: "20px",
                    },
                  },
                  "& p": {
                    position: "relative",
                    marginTop: "50px",
                    fontSize: "18px",
                    lineHeight: "28px",
                    fontWeight: 500,
                    color: "#f9f9f9",
                  },
                },
              },
            },
          },
        },
      },
      "& .loginViewLayout": {
        margintop: "25px",

        "& .user-name": {
          marginTop: "25px",

          "& .MuiOutlinedInput-root": {
            padding: "4px 6px",

            "& .MuiOutlinedInput-input": {
              backgroundColor: "#FAFAFA",
            },
          },
        },
        "& .user-pass": {
          marginTop: "2px",

          "& .MuiOutlinedInput-root": {
            padding: "4px 6px",
          },
        },

        "& .sub-btn": {
          marginTop: "40px",
          borderRadius: "8px",
        },

        "& .f-pass": {
          marginTop: "30px",
          color: "#452778",
          fontSize: "14px",
          fontWeight: 700,
        },
        "& .line": {
          height: "2px",
          color: "#040000",
          zIndex: 5000,
        },
      },
    },

    dashboardLayoutMainWrap: {
      "& .main-content": {
        backgroundColor: Theme.palette.secondary.light,
        height: "100vh",
        overflowX: "hidden",
        padding: 20,
        "& .content-except-footer": {
          minHeight: "calc(100vh - 80px)",
          marginBottom: 30,
        },

        "& .inside-footer": {
          position: "relative",
          width: "100%",
          height: "70px",
          background: "transparent",
          border: "none",

          "& .footer-inner": {
            position: "relative",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",

            "& p": {
              position: "relative",
              fontSize: "14px",
              fontWeight: 300,
              color: Theme.palette.primary.dark,
            },

            "&::before": {
              content: '""',
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "2px",
              background: "linear-gradient(90deg, #452778 0%, #ee8234 100%)",
            },
          },
        },
      },
    },
    sidebarDrawerMain: {
      "& .sidebarContent": {
        overflowY: "auto",
        height: "calc(100vh - 70px)",

        "& .active": {
          backgroundColor: Theme.palette.primary.main,
          "& *": {
            color: `${Theme.palette.primary.contrastText} !important`,
          },
          "&:hover": {
            backgroundColor: `${Theme.palette.primary.main} !important`,
          },
        },
        "& .logoCon": {
          padding: "60px 0 0",
          textAlign: "center",
          "& img": {
            width: "200px",
          },
        },
        "& .menuCon": {
          height: "calc(100% - 135px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          "& ul": {
            padding: "0",
            marginTop: "30px",
            "& li": {
              padding: "0",
              margin: "10px 0",
              "& a": {
                width: "calc(100% - 20px)",
                borderRadius: "0 15px 15px 0",
                padding: "15px 30px 15px 30px",
                display: "flex",
                alignItems: "center",
                "& div": {
                  minWidth: "46px",
                },
                "& *": {
                  color: Theme.palette.primary.dark,
                },
                "&:hover": {
                  backgroundColor: Theme.palette.secondary.light,
                },
              },
            },
          },
          "& .bottomMenuCon": {
            "& li": {
              width: "calc(100% - 20px)",
              borderRadius: "0 15px 15px 0",
              padding: "15px 30px 15px 30px",
              display: "flex",
              alignItems: "center",
              "& div": {
                minWidth: "46px",
              },
              "& *": {
                color: Theme.palette.primary.dark,
              },
              "&:hover": {
                backgroundColor: Theme.palette.secondary.light,
              },
            },
          },
        },
        '& .submenu': {
          marginTop: '18px !important',
          '& li': {
            '& .MuiListItemIcon-root': {
              minWidth: 30,
              '& svg': {
                fontSize: 16,
              }
            },
            '& .submenuLink': {
              fontSize: 14,
              padding: '5px 20px 5px 30px',
            },
            '& .MuiTypography-root': {
              fontSize: 14,
              fontWeight: 500,
            }
          }
        }
      },

      "& .sidebarCollapseBtn": {
        "&:hover": {
          backgroundColor: Theme.palette.primary.dark,
        },
      },
      "& .sidebarMainWrap": {
        "& .logoCon": {
          "& img": {
            width: 150,
            marginLeft: "15px",
          },
        },
      },
    },
    ".heading-dropdown": {
			flexDirection: "row",
			display: "flex",
			justifyContent: "end",
		},
  });
