import React, { Component } from 'react';
import { DialogProps } from '@mui/material';
import { MfaVerifyState } from 'Redux/States';
import { MuiBox, MuiButton, MuiDialog, MuiDialogActions, MuiDialogContent, MuiDialogTitle, MuiDivider, MuiIconButton, MuiInputField, MuiTypography } from 'Components/MUI';
import { StyledCreateModal } from './style';
import { CloseIcon } from 'Helpers/Icons';
import { AuthService } from 'Services';
import { Formik, FormikHelpers } from 'formik';
import { MfaVerifyValidator } from 'Helpers/Validators';
import { States } from 'Redux/Models';

interface MfaVerifyDialogProps extends DialogProps {
    title: string,
    onFormSubmit: Function
}

class MfaVerifyModal extends Component<MfaVerifyDialogProps> {

    public state: MfaVerifyState = new MfaVerifyState();

    public componentDidMount(): void {
        this.getMfaSecret();
    }

    public getMfaSecret = () => {
        AuthService.getMFASecret().then((resp) => {
            if (resp.isSuccess()) {
                const data = resp.getData();
                this.setState((prevState: MfaVerifyState) => {
                    prevState.mfaModal.base32 = data.base32;
                    prevState.mfaModal.qr = data.qr;
                    return prevState;
                })
            }
        }).catch((err) => {
            return err;
        })
    }

    public handleVerifyCode = (values: States, actions: FormikHelpers<States>) => {
        const { onFormSubmit } = this.props;
        actions.setSubmitting(true);
        if (onFormSubmit) onFormSubmit(values, actions)
    }

    render() {
        const { mfaModal }: MfaVerifyState = this.state;
        const { open, onClose, title, onFormSubmit, ...restProps }: MfaVerifyDialogProps = this.props;
        return (
            <MuiDialog open={open} onClose={onClose} maxWidth='lg' {...restProps}>
                <StyledCreateModal>
                    <Formik initialValues={mfaModal} validationSchema={MfaVerifyValidator} onSubmit={this.handleVerifyCode}>
                        {(props) => (
                            <MuiBox component='form' noValidate onSubmit={props.handleSubmit}>
                                <MuiBox display='flex' alignItems='center' justifyContent='space-between' className='heading'>
                                    <MuiDialogTitle className='title'>{title}</MuiDialogTitle>
                                    {onClose &&
                                        <MuiIconButton onClick={() => onClose({}, 'backdropClick')}>
                                            <CloseIcon />
                                        </MuiIconButton>
                                    }
                                </MuiBox>
                                <MuiDialogContent>
                                    <MuiBox>
                                        <MuiBox display='flex' justifyContent='center' alignItems='center'>
                                            {mfaModal.qr &&
                                                <img
                                                    style={{ border: '1px solid #000', borderRadius: '10px' }}
                                                    src={mfaModal.qr}
                                                    alt='qrCode'
                                                />
                                            }
                                        </MuiBox>
                                        <MuiBox my={2}>
                                            <MuiTypography variant='h5'>QR</MuiTypography>
                                            <MuiTypography>Enter this setup key in your authentication app to generate secret code</MuiTypography>
                                        </MuiBox>
                                        <MuiBox mt={2} mb={0.8}>
                                            <MuiBox component="label" className="fieldTopLabel">Setup Key</MuiBox>
                                            <MuiTypography variant='h6' fontSize='18px !important'>{mfaModal.base32}</MuiTypography>
                                        </MuiBox>
                                        <MuiDivider />
                                        <MuiBox my={2}>
                                            <MuiBox component="label" className="fieldTopLabel">Secret Key</MuiBox>
                                            <MuiInputField
                                                name='code'
                                                placeholder='Secret Key'
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={props.values.code}
                                                error={props.touched.code && !!props.errors.code}
                                                helperText={props.touched.code && props.errors.code && String(props.errors.code)}
                                            />
                                        </MuiBox>
                                    </MuiBox>
                                </MuiDialogContent>
                                <MuiDialogActions className='button'>
                                    <MuiBox className='submit-btn'>
                                        <MuiButton type='submit' loading={props.isSubmitting} disabled={props.isSubmitting} className='btn create-btn'>Verify</MuiButton>
                                        {onClose &&
                                            <MuiButton
                                                disabled={props.isSubmitting}
                                                variant="outlined"
                                                className='btn cancel-btn'
                                                onClick={() => onClose({}, 'backdropClick')}
                                            >
                                                Cancel
                                            </MuiButton>
                                        }
                                    </MuiBox>
                                </MuiDialogActions>
                            </MuiBox>
                        )}
                    </Formik>
                </StyledCreateModal>
            </MuiDialog>
        )
    }
}

export default MfaVerifyModal;