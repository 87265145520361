import { BaseStateModel, DataTableModel, UserModel } from "Redux/Models";
import { EmployeeService } from "Services";


export class EmployeeListState extends BaseStateModel {
    public isEmployeeCreateModalOpen: boolean = false;
    public isEmployeeEditing: boolean = false;
    public editEmployeeData: UserModel | null = null;
    public dataTable: DataTableModel = {
        pagination: true,
        searchable: true,
        limit: 10,
        active: 'createdAt',
        direction: 'desc',
        actions: [],
        request: EmployeeService.getList,
        headers: [
            { key: 'firstName', label: 'Name', isSort: true, render: (item: UserModel) => `${item.firstName} ${item.lastName}` },
            { key: 'email', label: 'Email', isSort: true },
            { key: 'phoneNumber', label: 'Phone number', isSort: true },
            { key: 'role.name', label: 'Role', isSort: false },
        ],
    };
}

export class EmployeeCreateState extends BaseStateModel {
    public employee: UserModel = {
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        phoneNumber: ''
    }
}