import { findIndex, map, filter, forEach, find, sumBy, sortBy, isEqual } from 'lodash';

export const FindIndex = findIndex;
export const Map = map;
export const Find = find;
export const Filter = filter;
export const ForEach = forEach;
export const SumBy = sumBy;
export const SortBy = sortBy;
export const IsEqual = isEqual
