import * as yup from 'yup';

const EMAIL = (name = 'Email') => yup
    .string()
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, `${name} is not valid email`)
    .required(`${name} is required`)
    .max(150, `${name} must be maximum 150 characters`);

const PASSWORD = yup
    .string()
    .min(6, "Password should be of minimum 6 characters length")
    .max(18, "Password should not contain more than 18 characters ")
    .required("Password is required");

const CONFIRM_PASSWORD = yup
    .string()
    .required("Confirm password is required")
    .min(6, "Confirm password must be minimum 6 characters")
    .max(18, "Confirm password must be maximum 18 characters ")
    .oneOf([yup.ref("password"), ''], "Both passwords must match");

const FIRST_NAME = (name = 'First name') => yup
    .string()
    .matches(/^[A-Za-z]*$/gm, `${name} should not contains numbers, spaces & special characters`)
    .required(`${name} is required`)
    .nullable();

const LAST_NAME = (name = 'Last name') => yup
    .string()
    .matches(/^[A-Za-z]*$/gm, `${name} should not contains numbers, spaces & special characters`)
    .required(`${name} is required`)
    .nullable();

const STRING_REQUIRED = (name: string, length: number = 100) =>
    yup
        .string()
        .trim()
        .required(`${name} is required`)
        .max(length, `${name} should have maximum ${length} characters`);

const PHONE_NUMBER_REQUIRED = (name: string) =>
    yup.string().required(`${name} is required`).min(8, `${name} should be minimum 8 characters`);

const COUNTRY = (name = 'Country') => yup.object().typeError(`${name} is not valid`).required(`${name} is required`);

const NUMBER_REQUIRED = (name: string) => yup.number().typeError(`${name} is not valid`).required(`${name} is required`)

export const LoginValidator = yup.object({
    email: EMAIL('Email'),
    password: PASSWORD
});

export const ForgotPasswordValidator = yup.object({
    email: EMAIL('Email')
});

export const ResetPasswordValidator = yup.object({
    password: PASSWORD,
    confirmPassword: CONFIRM_PASSWORD
})

export const RoleCreateValidator = yup.object({
    name: STRING_REQUIRED('Role name'),
    isInsightsOn: yup.boolean().required('Insigts is required'),
    ipPermissions: yup.array().when('isInsightsOn', ([isInsightsOn], sch) => {
        return isInsightsOn ?
            sch.min(1, 'Minimum one module is required').required('Minimum one module is required ')
            : sch.notRequired();
    }),
    moduleRolePermissions: yup.array().min(1, 'Minimum one permission required').required('Minimum one permission required'),
})

export const AddEmployeeValidator = yup.object({
    firstName: FIRST_NAME('First name'),
    lastName: LAST_NAME('Last name'),
    email: EMAIL('Email'),
    role: yup.string().required('Role is required'),
    phoneNumber: yup.string().required('Phone number is required').min(6, 'The phone no field must be at least 6 characters')
})

export const ChangePasswordValidator = yup.object({
    currentPassword: PASSWORD,
    password: PASSWORD,
    confirmPassword: CONFIRM_PASSWORD
})

export const MfaVerifyValidator = yup.object({
    code: yup.string().trim().required('Secret key is required')
})

export const CreateCompanyValidator = (isKeyNotReq = false) => yup.object({
    administrativeEmail: EMAIL('Administrative email'),
    administrativeFirstName: FIRST_NAME('First name'),
    administrativeLastName: LAST_NAME('Last name'),
    administrativePhoneNumber: PHONE_NUMBER_REQUIRED('Phone number'),
    administrativeCountry: isKeyNotReq ? yup.object().notRequired().nullable() : COUNTRY('Country'),
    hqAddress: STRING_REQUIRED('Headquarter address', 80),
    hqCity: STRING_REQUIRED('Headquarter city', 30),
    hqCountry: COUNTRY('Headquarter country'),
    hqDeliveryAddress: STRING_REQUIRED('Headquarter delivery address', 80),
    hqPostalCode: STRING_REQUIRED('Postal code', 30),
    invoicingAddress: STRING_REQUIRED('Invoicing address', 30),
    invoicingEmail: EMAIL('Invoicing email'),
    invoicingName: STRING_REQUIRED('Invoicing name'),
    invoicingPhone: PHONE_NUMBER_REQUIRED('Invoicing phone number'),
    name: STRING_REQUIRED('Name', 50),
    VAT: yup.string().required('VAT is required').min(8, 'VAT must be minimum 8 characters').max(14, 'VAT must be maximum 14 characters'),
    network: isKeyNotReq ? yup.string().notRequired().nullable() : STRING_REQUIRED('Network', 200),
    selectprice: STRING_REQUIRED('Price', 200),
})


export const CompanyBranchCreateValidator = () => yup.object({
    name: STRING_REQUIRED('Name'),
    postalCode: STRING_REQUIRED('Postal code', 30),
    deliveryAddress: STRING_REQUIRED('Delivery address', 80),
    address: STRING_REQUIRED('Address', 80),
    city: STRING_REQUIRED('City', 30),
    country: COUNTRY('Country'),

})

export const CreatePriceValidator = () => yup.object({
    name: STRING_REQUIRED('Name'),
    baseMargin: NUMBER_REQUIRED('Base margin'),
    baseMonthlyCost: NUMBER_REQUIRED('Base monthly cost'),
    baseSMSCost: NUMBER_REQUIRED('Base SMS cost'),
    pricePerDevice: NUMBER_REQUIRED('Price per device'),
    zones: yup.array().of(
        yup.object({
            name: STRING_REQUIRED('Zone name', 100),
            cost: NUMBER_REQUIRED('Zone cost'),
            sellingCost: NUMBER_REQUIRED('Zone selling cost'),
            plmn: STRING_REQUIRED('Zone cost', 400),
            country: yup.string().notRequired().nullable(),
        })
    )
})

export const RechargeValidator = () => yup.object({
    amount: yup.string().matches(/^\d+$/, 'Amount is not valid').required('Amount is required')
})

export const AddAutomationValidator = () => yup.object({
    name: STRING_REQUIRED('Automation name'),
    type: yup.string().oneOf(['DATA_USAGE', 'IMEI_CHANGE'], 'Automation type is not valid').required('Automation type is required'),
    action: yup.string().oneOf(['DEACTIVE_SIM', 'ACTIVE_SIM', 'SEND_EMAIL'], 'Automation action is not valid').required('Automation action is required'),
    followUpAction: yup.string().oneOf(['NEXT_MONTH', 'NEXT_DAY'], 'Follow up action is not valid').required('Follow up action is required'),
    value: yup.string().when('type', ([type]) => type === 'DATA_USAGE' ? yup.string().matches(/^\d+$/, 'Automation value is not valid').required('Automation value is required')
        : yup.string().notRequired().nullable()
    ),
    event: yup.string().when('type', ([type]) => type === 'DATA_USAGE' ?
        yup.string().oneOf(['LIMIT_MB', 'LIMIT_PRICE'], 'Automation event is not valid').required('Automation event is required')
        : yup.string().notRequired().nullable(),
    ),
    emailIds: yup.array().when('action', ([action]) => action === 'SEND_EMAIL' ?
        yup.array(EMAIL('Email')).min(2, 'Minimum two email is required').required('Minimum one email is required')
        : yup.array(EMAIL('Email')).nullable(),
    ),
})

export const AddInsightDeviceValidator = () => yup.object({
    name: STRING_REQUIRED('Name', 100),
    model: yup.object().typeError('Model is not valid').required('Model is required'),
    group: yup.string().required('Group is required')
})

export const AddSimToCompanyValidator = () => yup.object({
    seletedSims: yup.array(yup.string()).min(1, 'Select minimum one sim').required('Sim is required')
})