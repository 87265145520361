import { Sidebar } from 'Components'
import { MuiBox, MuiCssBaseline, MuiDrawer, MuiHidden, MuiIconButton, MuiTypography } from 'Components/MUI'
import { Map } from 'Helpers/Array'
import { LoadPermissions, LoadRoles, LoadUser, ToggleMenu } from 'Redux/Actions'
import { Props, Response, UserModel } from 'Redux/Models'
import { RouteInterface, routes } from 'Routes/Dashboard'
import { AuthService } from 'Services'
import { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import { Theme } from 'Theme'
import { DashboardState } from 'Redux/States';
import BarChartIcon from '@mui/icons-material/BarChart';
import { ToastService } from 'Helpers/Alert'

const drawerWidth = 272;

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '5px 10px',
}));

export class DashboardLayout extends Component<Props> {

    public state: DashboardState = new DashboardState();

    public handleDrawerToggle = () => {
        const { dispatch } = this.props;
        const { mobileOpen } = this.state;
        this.setState((prevState: DashboardState) => ({
            mobileOpen: !prevState.mobileOpen
        }), () => dispatch(ToggleMenu(mobileOpen)));

    }

    public componentDidMount(): void {
        const {socket} = this.props;
        this.getMe();
        socket.on('connect', () => {
            console.log('Connected');
        });
    }

    public componentDidUpdate(): void {
        const {socket} = this.props;
        const { user } = this.props;
        socket.on(`notification_${user._id}`, (data: any) => {
            ToastService.info(`You have a new notification`)
        })

    }

    public componentWillUnmount(): void {
        const { socket } = this.props;
        socket.disconnect();
        console.log('socket disconnected')
    }

    public getMe = () => {
        const { dispatch } = this.props;
        AuthService.getMe().then((resp: Response<UserModel>) => {
            if (resp.isSuccess()) {
                dispatch(LoadUser(resp.data.user))
                if (resp.data.permissions) {
                    dispatch(LoadPermissions(resp.data.permissions))
                }
                if (resp.data.roles) {
                    dispatch(LoadRoles(resp.data.roles));
                }
            }
        }).catch((err) => {
            return err;
        })
    }

    render() {
        const { user, classes, window } = this.props;
        const { mobileOpen } = this.state

        const container = window !== undefined ? () => window().document.body : undefined;
        return (
            <>

                <MuiBox className={classes.dashboardLayoutMainWrap} >
                    <MuiCssBaseline />
                    {/* start sidebar drawer for mobile */}
                    <MuiIconButton onClick={this.handleDrawerToggle} className='sidebarCollapseBtn' sx={{
                        position: 'fixed',
                        left: 0,
                        top: 10,
                        background: Theme.palette.primary.main,
                        borderRadius: '0 5px 5px 0',
                        color: '#fff',
                        padding: '10px',
                        zIndex: !mobileOpen ? 999 : 999999,
                        '& svg': {
                            fontSize: 30,
                        }
                    }}>
                        <BarChartIcon />
                    </MuiIconButton>
                    <MuiHidden mdUp>
                        <MuiDrawer
                            container={container}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={this.handleDrawerToggle}
                            ModalProps={{
                                keepMounted: true,
                            }}
                            className={classes.sidebarDrawerMain}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                                '& .MuiDrawer-paper': {
                                    boxSizing: 'border-box',
                                    width: drawerWidth,
                                    borderRight: '1px solid #0000000d',
                                },
                            }}
                        >
                            <MuiBox className='sidebarLogoWraper'>
                                <DrawerHeader>
                                    <MuiIconButton onClick={this.handleDrawerToggle} className='sidebarCollapseBtn' sx={{
                                        background: Theme.palette.primary.main,
                                        borderRadius: '50%',
                                        color: '#fff',
                                        padding: '10px',
                                        margin: '8px 5px 20px auto',
                                        '& svg': {
                                            fontSize: 30,
                                        }
                                    }}>
                                        <BarChartIcon />
                                    </MuiIconButton>
                                </DrawerHeader>
                            </MuiBox>

                            <Sidebar {...this.props} />
                        </MuiDrawer>
                    </MuiHidden>
                    {/* end sidebar drawer for mobile */}

                    {/* start sidebar drawer for desktop */}
                    <MuiHidden mdDown>
                        <MuiDrawer
                            className={classes.sidebarDrawerMain}
                            variant="permanent"
                            sx={{
                                display: { xs: 'none', md: 'block' },
                                '& .MuiDrawer-paper': {
                                    boxSizing: 'border-box',
                                    width: drawerWidth,
                                    ml: mobileOpen ? `-${drawerWidth}px` : 0,
                                    transition: '0.4s',
                                    overflow: 'unset',
                                    borderRight: '1px solid #0000000d',
                                    boxShadow: '0 0 15px #0000003b',
                                    [Theme.breakpoints.down('md')]: {
                                        zIndex: 99999,
                                    }
                                },
                            }}
                            open={mobileOpen}
                        >
                            <MuiBox className='sidebarLogoWraper'>
                                <DrawerHeader>
                                    <MuiIconButton onClick={this.handleDrawerToggle} className='sidebarCollapseBtn' sx={{
                                        position: 'absolute',
                                        right: -15,
                                        top: 10,
                                        background: Theme.palette.primary.main,
                                        borderRadius: '50%',
                                        color: '#fff',
                                        padding: '5px',
                                        zIndex: !mobileOpen ? 999 : 999999,
                                        display: mobileOpen ? 'none' : 'flex',
                                        '& svg': {
                                            fontSize: 20,
                                        }
                                    }}>
                                        <BarChartIcon />
                                    </MuiIconButton>
                                </DrawerHeader>
                            </MuiBox>

                            <Sidebar {...this.props} />
                        </MuiDrawer>
                    </MuiHidden>
                    {/* end sidebar drawer for desktop */}

                    <MuiBox
                        component="main"
                        sx={{
                            flexGrow: 1,
                            bgcolor: 'background.default',
                            width: { xs: '100%', md: !mobileOpen ? `calc(100% - ${drawerWidth}px)` : '100%' },
                            marginLeft: `auto`,
                            transition: '0.4s',
                        }}>

                        <MuiBox className="main-content">

                            <MuiBox className='content-except-footer'>
                                <Switch>
                                    {Map(routes, (route: RouteInterface, index: number) => {
                                        const title = { title: route.name };
                                        if (route.module) {
                                            return route.module && route.permissions && user.hasPermission(route.module, ...route.permissions) &&
                                                (<Route
                                                    key={index}
                                                    path={route.path}
                                                    exact={route.exact}
                                                    render={(props: any) => <route.component {...this.props} {...props} {...title} />}
                                                />)
                                        } else {
                                            return (<Route
                                                key={index}
                                                path={route.path}
                                                exact={route.exact}
                                                render={(props: any) => <route.component {...this.props} {...props} {...title} />}
                                            />)
                                        }
                                    })}
                                    <Redirect exact to='/dashboard' />
                                </Switch>
                            </MuiBox>

                            <MuiBox className='inside-footer'>
                                <div className="footer-inner">
                                    <MuiTypography className='p'>© Ondigo {new Date().getFullYear()}</MuiTypography>
                                </div>
                            </MuiBox>

                        </MuiBox>
                    </MuiBox>
                </MuiBox>


            </>
        )
    }
}

export default DashboardLayout;