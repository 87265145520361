import { Http } from "Http";
import BaseService from "./BaseService";
import { Options, Response, ResponseList, SimModel, SimConnectivityEvent } from "Redux/Models";

export default class SimService extends BaseService {

    public static getList(params: Object = {}, options: Options = {}): Promise<ResponseList<SimModel>> {
        let url: string = '/sims';
        if (options?.root) {
            url = options.root + url;
        }
        SimService.initCancelToken();
        if (options?.source) {
            SimService.source = options?.source
        }
        return new Promise((resolve, reject) => {
            Http.get(url, { params, cancelToken: SimService.source?.token })
                .then(res => {
                    let data = new ResponseList<SimModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static getById(id: String, params = {}, options: Options = {}): Promise<Response<SimModel>> {
        SimService.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.get('/sims/' + id, { params, cancelToken: SimService.source?.token }).then(resp => {
                let data = new Response<SimModel>(resp?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static getSimsListByCompany(id: string, params: Object = {}, options: Options = {}): Promise<ResponseList<SimModel>> {
        SimService.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.get('/companies/' + id + '/sims', { params, cancelToken: SimService.source?.token }).then(resp => {
                let data = new ResponseList<SimModel>(resp.data);
                return resolve(data);
            }).catch(err => {
                return reject(err)
            })
        })
    }

    public static getSimsHistoryById(id: string, params: Object = {}, options: Options = {}): Promise<Response<SimModel>> {
        SimService.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.get('/sim-usage/' + id, { params, cancelToken: SimService.source?.token }).then(resp => {
                let data = new Response<SimModel>(resp.data);
                return resolve(data);
            }).catch(err => {
                return reject(err)
            })
        })
    }

    public static getSimsUsageById(id: string, params: Object = {}, options: Options = {}): Promise<Response<any>> {
        SimService.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.get('/sim-usage-cost/' + id, { params, cancelToken: SimService.source?.token }).then(resp => {
                let data = new Response<any>(resp.data);
                return resolve(data);
            }).catch(err => {
                return reject(err)
            })
        })
    }

    public static simSummary(params: Object = {}): Promise<Response<SimModel>> {
        SimService.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.get('/sim-summary', params).then(resp => {
                let data = new Response<SimModel>(resp.data);
                return resolve(data);
            }).catch(err => {
                return reject(err)
            })
        })
    }

    public static downloadSimHistoryPdf(id: string = '', params: Object = {}): Promise<Response<any>> {
        SimService.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.get(`/sim-usage-doc/${id}/?type=pdf`, { responseType: 'blob', ...params }).then(resp => {
                return resolve(resp.data);
            }).catch(err => {
                return reject(err)
            })
        })
    }

    public static simConnectivityEventList(id: string = ''): Promise<ResponseList<SimConnectivityEvent>> {
        SimService.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.get(`/sims/get-sim-connectivity/${id}`).then(resp => {
                let data = new ResponseList<SimConnectivityEvent>(resp.data);
                return resolve(data);
            }).catch(err => {
                return reject(err)
            })
        })
    }

    public static changeStateOfSim(id: string, params: Object = {}): Promise<Response<SimModel>> {
        SimService.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.patch(`/sims/${id}/state`, params).then(resp => {
                let data = new Response<SimModel>(resp.data);
                return resolve(data);
            }).catch(err => {
                return reject(err)
            })
        })
    }

    public static updateSim(id: string, params: Object = {}): Promise<Response<SimModel>> {
        SimService.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.patch(`/sim/${id}`, params).then(resp => {
                let data = new Response<SimModel>(resp.data);
                return resolve(data);
            }).catch(err => {
                return reject(err)
            })
        })
    }

    public static totalUsageDataSim(id: string): Promise<Response<string>> {
        SimService.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.get(`sims/total-data-usage/${id}`).then(resp => {
                let data = new Response<string>(resp.data);
                return resolve(data);
            }).catch(err => {
                return reject(err)
            })
        })
    }

    public static getListForCompany(): Promise<ResponseList<SimModel>> {
        let url: string = '/sims/add-company';
        return new Promise((resolve, reject) => {
            Http.get(url)
                .then(res => {
                    let data = new ResponseList<SimModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static addSimToCompany(companyId: string | number, sims: Array<string>): Promise<Response<null>> {
        let url: string = `/companies/${companyId}/sims`;
        return new Promise((resolve, reject) => {
            Http.post(url, {sims})
                .then(res => {
                    let data = new Response<null>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static countryWiseConsumptionReport(): Promise<Response<any>> {
        let url: string = `/data-consumption-report-country`;
        return new Promise((resolve, reject) => {
            Http.get(url)
                .then(res => {
                    let data = new Response<any>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

}