import { Props, States } from 'Redux/Models';
import { Theme } from 'Theme';
import { Component } from 'react'
import Chart from "react-apexcharts";

interface ChartProps extends Props {
    data: Array<number | []>;
    categories: Array<number | []>;
    seriesName: string;
    formatterLabel: string;
}

export class LastUsageChart extends Component<ChartProps> {

    public state: States;
    constructor(props: ChartProps) {
        super(props);
        this.state = {
            options: {
                fill: {
                    colors: [Theme.palette.secondary.contrastText],
                },
                yaxis: {
                    labels: {
                        formatter: (value: number) => `${value.toFixed(2)} ${props.formatterLabel || ''}`
                    }
                },
                dataLabels: {
                    enabled: false
                },
                tooltip: {
                    x: {
                        show: false,
                    },
                    y: {
                        formatter: undefined,
                        title: {
                            formatter: (val: string) => null,
                        },
                    },
                },
                chart: {
                    id: "basic-bar",
                    width: '100%',
                    toolbar: {
                        show: false
                    }
                },
                xaxis: {
                    categories: []
                }
            },
            series: [
                {
                    name: 'series-1',
                    data: []
                }
            ]
        };
    }

    public componentDidUpdate(prevProps: Readonly<ChartProps>, prevState: any, snapshot?: any): void {
        const { data, seriesName } = this.props;
        if (JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState({
                options: {
                    ...this.state.options,
                    xaxis: {
                        ...this.state.options.xaxis,
                        categories: Array.from(Array(24).keys())
                    }
                },
                series: prevState.series.map((seriesItem: any) => {
                    if (seriesItem.name === 'series-1') {
                        return {
                            name: seriesName,
                            data: data,
                        };
                    }
                    return seriesItem;
                }),
            });

        }
    }

    render() {
        const { options, series } = this.state;
        return (
            <Chart
                type="bar"
                height="400"
                width='100%'
                options={options}
                series={series}
            />
        )
    }
}

export default LastUsageChart