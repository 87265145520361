import { DialogProps } from '@mui/material'
import { MuiBox, MuiButton, MuiCheckbox, MuiChipInputField, MuiDialog, MuiDialogActions, MuiDialogContent, MuiDialogTitle, MuiFormControl, MuiFormControlLabel, MuiFormHelperText, MuiFormLabel, MuiGrid, MuiIconButton, MuiInputAdornment, MuiInputField, MuiListField, MuiRadio, MuiRadioGroup } from 'Components/MUI'
import React, { ChangeEvent, Component, SyntheticEvent } from 'react'
import { StyledCreateModal } from './style';
import { CloseIcon } from 'Helpers/Icons';
import { AutomationRuleModel } from 'Redux/Models';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { AddAutomationState } from 'Redux/States';
import { AddAutomationValidator } from 'Helpers/Validators';
import { AutomationService } from 'Services';

interface AddAutomationDialogProps extends DialogProps {
    title: string,
    isEditing: boolean,
    editData: AutomationRuleModel | null,
    getNewtableData: (resp: number) => void
}

const ACTIONS = [
    { label: 'Deactivate SIM', value: 'DEACTIVE_SIM' },
    { label: 'Activate SIM', value: 'ACTIVE_SIM' },
    { label: 'Send Email', value: 'SEND_EMAIL' },
]

const FOLLOW_UP_ACTIONS = [
    { label: 'Activate at the start of next month', value: 'NEXT_MONTH' },
    { label: 'Activate at the start of next day', value: 'NEXT_DAY' },
]

export class AddAutomationModal extends Component<AddAutomationDialogProps> {

    public state: AddAutomationState = new AddAutomationState();

    public handleCreateAutomation = (values: AutomationRuleModel, actions: FormikHelpers<AutomationRuleModel>) => {
        actions.setSubmitting(true);
        const { getNewtableData, onClose, isEditing, editData } = this.props;
        const { _id }: any = editData;
        (isEditing && _id ?
            AutomationService.update(_id, values) :
            AutomationService.create(values)).then((resp) => {
                if (resp.isSuccess()) {
                    actions.setSubmitting(false);
                    getNewtableData(200);
                    if (onClose) onClose({}, 'backdropClick');
                }
            }).catch((err) => {
                actions.setSubmitting(false);
                return err;
            })
    }

    public componentDidUpdate(prevProps: Readonly<AddAutomationDialogProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if (prevProps.editData !== this.props.editData) {
            this.getAutomation();
        }
    }

    public getAutomation = () => {
        const { editData, isEditing } = this.props;
        if (isEditing && editData) {
            const { addedBy, createdAt, deletedAt, status, updatedAt, __v, _id, ...rest } = editData;
            this.setState((prevState: AddAutomationState) => ({
                automation: { ...rest }
            }))
        }
    }

    public handleEmailInputChange = (value: any[], props: FormikProps<AutomationRuleModel>) => {
        props.setFieldValue('emailIds', value);
    }

    public handleActionChange = (event: ChangeEvent<HTMLInputElement>, props: FormikProps<AutomationRuleModel>) => {
        const { value } = event.target;
        if (value) {
            props.setFieldValue('action', value);
        }
        if (value === 'SEND_EMAIL') {
            props.setFieldValue('sendEmail', true);
        } else {
            props.setFieldValue('sendEmail', false);
        }
    }

    public formatHelperText = (errors: string | Array<string>) => {
        if (errors && Array.isArray(errors) && errors.length > 0) {
            return errors.map((err) => err = 'Some of the email is not valid (please remove the red marked emails)');
        } else if (errors && typeof errors === 'string') return errors;
    }

    render() {
        const { open, onClose, title, isEditing, editData, getNewtableData, ...restProps } = this.props;
        const { automation } = this.state;
        return (
            <MuiDialog onClose={onClose} open={open} {...restProps}>
                <StyledCreateModal>
                    <Formik enableReinitialize initialValues={automation} validationSchema={AddAutomationValidator} onSubmit={this.handleCreateAutomation}>
                        {(props) => (
                            <MuiBox component='form' onSubmit={props.handleSubmit}>
                                <MuiBox display='flex' alignItems='center' justifyContent='space-between' className='heading'>
                                    <MuiDialogTitle className='title'>{title}</MuiDialogTitle>
                                    {onClose &&
                                        <MuiIconButton onClick={() => onClose({}, 'backdropClick')}>
                                            <CloseIcon />
                                        </MuiIconButton>
                                    }
                                </MuiBox>
                                <MuiDialogContent>
                                    <MuiBox>
                                        <MuiGrid container spacing={2}>
                                            <MuiGrid item lg={12} xs={12} sm={12}>
                                                <MuiInputField
                                                    name="name"
                                                    placeholder='Name'
                                                    value={props.values.name}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.touched.name && !!props.errors.name}
                                                    helperText={props.touched.name && props.errors.name && String(props.errors.name)}
                                                />
                                            </MuiGrid>
                                            <MuiGrid item lg={props.values.type === 'DATA_USAGE' ? 6 : 12} xs={12} sm={12}>
                                                <MuiFormControl>
                                                    <MuiFormLabel>Type</MuiFormLabel>
                                                    <MuiRadioGroup onChange={props.handleChange} value={props.values.type} row aria-label="type" name="type">
                                                        <MuiFormControlLabel value='DATA_USAGE' control={<MuiRadio />} label="Data Uage" />
                                                        <MuiFormControlLabel value='IMEI_CHANGE' control={<MuiRadio />} label="IMEI Change" />
                                                    </MuiRadioGroup>
                                                </MuiFormControl>
                                                {props.touched.type && !!props.errors.type &&
                                                    <MuiFormHelperText error={props.touched.type && !!props.errors.type}>
                                                        {props.touched.type && props.errors.type && String(props.errors.type)}
                                                    </MuiFormHelperText>
                                                }
                                            </MuiGrid>
                                            {props.values.type === 'DATA_USAGE' &&
                                                <MuiGrid item lg={6} xs={12} sm={12}>
                                                    <MuiFormControl>
                                                        <MuiFormLabel>Event</MuiFormLabel>
                                                        <MuiRadioGroup onChange={props.handleChange} value={props.values.event} row aria-label="event" name="event">
                                                            <MuiFormControlLabel value='LIMIT_MB' control={<MuiRadio />} label="Limit MB" />
                                                            <MuiFormControlLabel value='LIMIT_PRICE' control={<MuiRadio />} label="Limit Price" />
                                                        </MuiRadioGroup>
                                                    </MuiFormControl>
                                                    {props.touched.event && !!props.errors.event &&
                                                        <MuiFormHelperText error={props.touched.event && !!props.errors.event}>
                                                            {props.touched.event && props.errors.event && String(props.errors.event)}
                                                        </MuiFormHelperText>
                                                    }
                                                </MuiGrid>
                                            }
                                            {props.values.type === 'DATA_USAGE' &&
                                                <MuiGrid mt={2} item lg={6} xs={12} sm={12}>
                                                    <MuiInputField
                                                        placeholder='Value'
                                                        name="value"
                                                        value={props.values.value}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        error={props.touched.value && !!props.errors.value}
                                                        helperText={props.touched.value && props.errors.value && String(props.errors.value)}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <MuiInputAdornment position="start">
                                                                    {props.values.event === 'LIMIT_MB' ? 'MB' : '€'}
                                                                </MuiInputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </MuiGrid>
                                            }
                                            <MuiGrid mt={2} item lg={props.values.type === 'DATA_USAGE' ? 6 : 12} xs={12} sm={12}>
                                                <MuiListField
                                                    items={ACTIONS}
                                                    placeholder='Action'
                                                    name="action"
                                                    value={props.values.action}
                                                    onChange={(event: ChangeEvent<HTMLInputElement>) => this.handleActionChange(event, props)}
                                                    onBlur={props.handleBlur}
                                                    error={props.touched.action && !!props.errors.action}
                                                    helperText={props.touched.action && props.errors.action && String(props.errors.action)}
                                                />
                                            </MuiGrid>
                                            {props.values.action &&
                                                <MuiGrid item lg={12} xs={12} sm={12}>
                                                    <MuiListField
                                                        items={FOLLOW_UP_ACTIONS}
                                                        placeholder='Follow Up Action'
                                                        name="followUpAction"
                                                        value={props.values.followUpAction}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        error={props.touched.followUpAction && !!props.errors.followUpAction}
                                                        helperText={props.touched.followUpAction && props.errors.followUpAction && String(props.errors.followUpAction)}
                                                    />
                                                </MuiGrid>
                                            }
                                            {props.values.action !== 'SEND_EMAIL' &&
                                                <MuiGrid item lg={12} xs={12} sm={12}>
                                                    <MuiCheckbox
                                                        label='Send Email'
                                                        name='sendEmail'
                                                        checked={props.values.sendEmail}
                                                        onChange={props.handleChange}
                                                    />
                                                    {props.touched.sendEmail && !!props.errors.sendEmail &&
                                                        <MuiFormHelperText error={props.touched.sendEmail && !!props.errors.sendEmail}>
                                                            {props.touched.sendEmail && props.errors.sendEmail && String(props.errors.sendEmail)}
                                                        </MuiFormHelperText>
                                                    }
                                                </MuiGrid>
                                            }
                                            {(props.values.sendEmail || props.values.action === 'SEND_EMAIL') &&
                                                <MuiGrid item lg={12} xs={12} sm={12}>
                                                    <MuiChipInputField
                                                        placeholder='Enter email'
                                                        bottomText="Press enter after adding a email"
                                                        name="emailIds"
                                                        onChange={(event: SyntheticEvent<Element, Event>, value: any[]) => this.handleEmailInputChange(value, props)}
                                                        value={props.values.emailIds}
                                                        error={(props.errors.emailIds || props.errors.emailIds?.length) ? true : false}
                                                        helperText={this.formatHelperText(props.errors.emailIds as any)}
                                                    />
                                                </MuiGrid>
                                            }
                                        </MuiGrid>
                                    </MuiBox>
                                </MuiDialogContent>
                                <MuiDialogActions className='btn' sx={{ marginTop: '10px', marginBottom: '8px' }}>
                                    {onClose && <MuiButton className='back-btn' onClick={() => onClose({}, "backdropClick")}>Cancel</MuiButton>}
                                    <MuiButton type='submit' className='save-btn'>{isEditing ? 'Update' : 'Create'}</MuiButton>
                                </MuiDialogActions>
                            </MuiBox>
                        )}
                    </Formik>
                </StyledCreateModal>
            </MuiDialog>
        )
    }
}

export default AddAutomationModal;