import { Http } from 'Http';
import BaseService from './BaseService';
import { CompanyModel, InvoicesModel, NetworkModel, Options, PriceModel, Response, ResponseList, UsageModel } from 'Redux/Models';

export default class CompanyService extends BaseService {

    public static getList(params: Object = {}, options: Options = {}): Promise<Response<CompanyModel>> {
        let url: string = '/companies';
        if (options?.root) {
            url = options.root + url;
        }
        CompanyService.initCancelToken();
        if (options?.source) {
            CompanyService.source = options?.source;
        }
        return new Promise((resolve, reject) => {
            Http.get(url, { params, cancelToken: CompanyService.source?.token })
                .then((res) => {
                    let data = new Response<CompanyModel>(res.data);
                    return resolve(data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }

    public static updateStatus(id: string, params: Object = {}, options: Options = {}): Promise<Response<CompanyModel>> {
        let url: string = '/companies/' + id + '/status';
        if (options?.root) {
            url = options.root + url;
        }
        this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.patch(url, params)
                .then(res => {
                    let data = new Response<CompanyModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static deleteCompany(id: string, params: Object = {}, options: Options = {}): Promise<Response<null>> {
        let url: string = '/companies/' + id;
        if (options?.root) {
            url = options.root + url;
        }
        this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.delete(url, params)
                .then(res => {
                    let data = new Response<null>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static getById(id: String, params = {}, options: Options = {}): Promise<Response<CompanyModel>> {
        let url: string = '/companies/' + id;
        if (options?.root) {
            url = options.root + url;
        }
        this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.get(url, { params }).then(res => {
                let data = new Response<CompanyModel>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static create(params: Object = {}, options: Options = {}): Promise<Response<CompanyModel>> {
        let url: string = '/companies';
        if (options?.root) {
            url = options.root + url;
        }
        this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.post(url, params)
                .then(res => {
                    let data = new Response<CompanyModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static update(id: string, params: Object = {}, options: Options = {}): Promise<Response<CompanyModel>> {
        let url: string = '/companies/' + id;
        if (options?.root) {
            url = options.root + url;
        }
        this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.patch(url, params)
                .then(res => {
                    let data = new Response<CompanyModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static getNetworks(): Promise<ResponseList<NetworkModel>> {
        this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.get('/networks').then(res => {
                let data = new ResponseList<NetworkModel>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static getPrices(): Promise<ResponseList<PriceModel>> {
        this.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.get('/prices').then(res => {
                let data = new ResponseList<PriceModel>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static getUsageListByCompany(id: string, params: Object = {}, options: Options = {}): Promise<ResponseList<UsageModel>> {
        CompanyService.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.get('/credit-usage/' + id + '/company', { params, cancelToken: CompanyService.source?.token }).then(resp => {
                let data = new ResponseList<UsageModel>(resp.data);
                return resolve(data);
            }).catch(err => {
                return reject(err)
            })
        })
    }



    public static getInvoicesListByCompany(id: string, params: Object = {}, options: Options = {}): Promise<ResponseList<InvoicesModel>> {
        CompanyService.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.get('/payments/' + id + '/company', { params, cancelToken: CompanyService.source?.token }).then(resp => {
                let data = new ResponseList<InvoicesModel>(resp.data);
                return resolve(data);
            }).catch(err => {
                reject(err)
            })
        })
    }

}