import { DialogProps } from "@mui/material";
import {
  MuiBox,
  MuiButton,
  MuiDialog,
  MuiDialogActions,
  MuiDialogContent,
  MuiDialogTitle,
  MuiIconButton,
  MuiTablePagination,
  MuiTypography,
} from "Components/MUI";
import { Map } from "Helpers/Array";
import { CloseIcon } from "Helpers/Icons";
import { SimModel, States } from "Redux/Models";
import React, { ChangeEvent, Component } from "react";
import { StyledCreateModal } from "./style";

interface SimHistoryDialogProps extends DialogProps {
  items: Array<SimModel>;
}

const headers = [
  { key: "time", label: "Date" },
  { key: "month", label: "Month" },
  { key: "dataUsage", label: "Data Usage(MB)" },
  { key: "cost", label: "Cost" },
];

class SimHistoryModal extends Component<SimHistoryDialogProps> {
  constructor(props: SimHistoryDialogProps) {
    super(props);
    this.state = {
      currentPage: 1,
      limit: 10,
    };
  }

  public handleChangePage = (event: any, value: any) => {
    this.setState((prevState: States) => ({
      currentPage: value + 1,
    }));
  };

  public handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    const limit = parseInt(event.target.value, 10);
    this.setState((prevState: States) => ({
      currentPage: 1,
      limit: limit,
    }));
  };

  render() {
    const { open, onClose, title, items, ...restProps } = this.props;
    const { currentPage, limit }: States = this.state;
    const indexOfLastItem = currentPage * limit;
    const indexOfFirstItem = indexOfLastItem - limit;
    const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);
    return (
      <MuiDialog open={open} onClose={onClose} {...restProps}>
        <StyledCreateModal>
          <MuiBox
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className="heading"
          >
            <MuiDialogTitle className="title">{title}</MuiDialogTitle>
            {onClose && (
              <MuiIconButton onClick={() => onClose({}, "backdropClick")}>
                <CloseIcon />
              </MuiIconButton>
            )}
          </MuiBox>
          <MuiDialogContent className="sim-table-content">
            <MuiBox className="list-content">
              <MuiBox className="list-title">
                {Map(headers, (head, headIndex) => (
                  <MuiTypography key={headIndex} className="list-title-text">
                    {head.label}
                  </MuiTypography>
                ))}
              </MuiBox>
              {currentItems && currentItems.length > 0 ? (
                Map(currentItems, (simHstry: any, simHstryIndex) => (
                  <MuiBox key={simHstryIndex} className="list-content-result">
                    {Map(headers, (headKey, headI) => (
                      <MuiTypography key={headI} className="list-main-text">
                        {simHstry[headKey.key]}
                      </MuiTypography>
                    ))}
                  </MuiBox>
                ))
              ) : (
                <MuiBox py={3}>
                  <MuiTypography textAlign="center">
                    No history found
                  </MuiTypography>
                </MuiBox>
              )}
            </MuiBox>
            {currentItems && (
              <MuiTablePagination
                className="sim-table"
                sx={{
                  "& .MuiSelect-select": {
                    fontSize: 16,
                  },
                }}
                count={items.length}
                page={currentPage - 1}
                onPageChange={this.handleChangePage}
                component="div"
                rowsPerPage={limit}
                rowsPerPageOptions={[10, 25, 100]}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              />
            )}
          </MuiDialogContent>
          <MuiDialogActions className="button">
            <MuiBox className="submit-btn">
              {onClose && (
                <MuiButton
                  variant="outlined"
                  className="btn cancel-btn"
                  onClick={() => onClose({}, "backdropClick")}
                >
                  Cancel
                </MuiButton>
              )}
            </MuiBox>
          </MuiDialogActions>
        </StyledCreateModal>
      </MuiDialog>
    );
  }
}

export default SimHistoryModal;
