import { styled } from "@mui/material/styles";

export const StyledNotificationTab = styled("div")(({ theme }) => ({
  paddingTop: "3.2rem",
  paddingLeft: "2rem",
  paddingRight: "2rem",
  height: "100%",
  position: "fixed",
  top: 0,
  left: "270px",
  width: "550px",
  zIndex: 999,
  overflowY: "auto",
  backgroundColor: theme.palette.secondary.light,
  transition: "all 0.7s",

  "& .active": {
    transition: "all 0.7s",
  },

  ".heading": {
    marginBottom: "1rem",

    ".page-title": {
      position: "relative",
      display: "inline-block",
      "& span": {
        position: "relative",
        zIndex: "9",
        fontSize: "48px",
        fontWeight: 700,
        color: theme.palette.primary.dark,
      },
      "&::after": {
        content: "''",
        position: "absolute",
        left: 0,
        bottom: "11px",
        width: "100%",
        height: "4px",
        backgroundColor: theme.palette.primary.light,
        borderRadius: "20px",
      },
    },
  },
  ".content": {
    ".filter-text": {
      marginBottom: "8px",
    },
    ".btn-grp": {
      display: "flex",
      gap: "2rem",
      margintop: "10px",

      ".btn": {
        padding: "5px 35px",
        fontSize: "18px",
        borderRadius: "10px",
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.main,
        border: `1px solid ${theme.palette.secondary.main}`,
      },
    },
    ".row": {
      marginTop: "2rem",

      ".row-content": {
        display: "flex",
        alignItems: "center",
        gap: "3%",
        padding: "4px 4px",
        marginBottom: "15px",
        ".left": {
          marginLeft: "10px",
        },

        ".right": {
          ".title": {
            fontSize: "22px",
            fontWeight: 600,
            textTransform: 'capitalize',
            color: theme.palette.primary.dark,
          },
          ".sub-title": {
            fontSize: "14px",
            color: theme.palette.primary.dark,
          },
        },
      },
    },
  },
}));
