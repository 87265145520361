import { Switch, SwitchProps } from '@mui/material';
import { Component, ReactNode } from 'react';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

export default class MuiSwitchButton extends Component<SwitchProps> {
    public render(): ReactNode {
        return <Switch {...label} {...this.props} />;
    }
}
