import { CircularProgress, DialogProps } from '@mui/material';
import { MuiBox, MuiButton, MuiDialog, MuiDialogActions, MuiDialogContent, MuiDialogTitle, MuiDivider, MuiGrid, MuiIconButton, MuiInputField, MuiTypography } from 'Components/MUI';
import { AliPayIcon, BanContactIcon, CloseIcon, CreditCardIcon, EpsPayIcon, GiroPayIcon, IdealIcon, P24PayIcon, PayPalIcon, SofortIcon, WeChatPay } from 'Helpers/Icons';
import React, { ChangeEvent, Component, RefObject, createRef } from 'react';
import { StyledCreateModal } from './style';
import { RechargeBalanceState } from 'Redux/States';
import { Map } from 'Helpers/Array';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { RechargeValidator } from 'Helpers/Validators';
import { AuthUserModel } from 'Redux/Models';
import { RechargeService } from 'Services';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import StripeCheckout from 'Components/Stripe/StripeCheckout';
import PayPalCheckout from 'Components/PayPal/PayPalCheckout';
import { History } from 'history';
interface AddBalanceDialogProps extends DialogProps {
    title: string,
    user: AuthUserModel,
    modalType: string,
    history: History<unknown>
}

const PRICES = [
    { label: 50, value: 50 },
    { label: 100, value: 100 },
    { label: 150, value: 150 },
    { label: 200, value: 200 },
    { label: 250, value: 250 },
    { label: 500, value: 500 },
]

class AddBalanceModal extends Component<AddBalanceDialogProps> {

    public state: RechargeBalanceState = new RechargeBalanceState();
    public stripeChildRef: RefObject<any>;
    public paypalChildRef: RefObject<any>;

    constructor(props: AddBalanceDialogProps) {
        super(props);
        this.initPaymentMethods();
        this.stripeChildRef = createRef();
        this.paypalChildRef = createRef();
    }

    public initPaymentMethods() {
        const country = this.props.user.country;
        this.state.paymentMethods.push(
            { key: 'CARD', label: 'Card', icon: CreditCardIcon as any },
            { key: 'PAYPAL', label: 'PayPal', icon: PayPalIcon },
        );
        if (country === 'China' || country === 'Hong Kong') {
            this.state.paymentMethods.push(
                { key: 'ALIPAY', label: 'Alipay', icon: AliPayIcon as any },
                { key: 'WECHAT_PAY', label: 'Wechat pay', icon: WeChatPay as any },
            );
        }
        else if (country === 'Austria') {
            this.state.paymentMethods.push(
                { key: 'EPS', label: 'Eps', icon: EpsPayIcon as any },
                { key: 'SOFORT', label: 'Sofort', icon: SofortIcon as any },
            );
        }
        else if (country === 'Belgium') {
            this.state.paymentMethods.push(
                { key: 'BANCONTACT', label: 'Bancontact', icon: BanContactIcon as any },
                { key: 'SOFORT', label: 'Sofort', icon: SofortIcon as any },
            );
        }
        else if (country === 'Germany') {
            this.state.paymentMethods.push(
                { key: 'GIROPAY', label: 'Giropay', icon: GiroPayIcon as any },
                { key: 'SOFORT', label: 'Sofort', icon: SofortIcon as any },
            );
        }
        else if (country === 'Netherlands') {
            this.state.paymentMethods.push(
                { key: 'IDEAL', label: 'Ideal', icon: IdealIcon as any },
                { key: 'SOFORT', label: 'Sofort', icon: SofortIcon as any },
            );
        }
        else if (country === 'Poland') {
            this.state.paymentMethods.push(
                { key: 'P24', label: 'P24', icon: P24PayIcon as any },
                { key: 'SOFORT', label: 'Sofort', icon: SofortIcon as any },
            );
        }
        else if (country === 'Italy' || country === 'Spain') {
            this.state.paymentMethods.push(
                { key: 'SOFORT', label: 'Sofort', icon: SofortIcon as any },
            );
        }
    }

    public getCharges() {
        const { balanceModel } = this.state;
        let vat: any = 0;
        let charge: any = 0;
        if (balanceModel.amount && balanceModel.method) {
            if (balanceModel.method === 'CARD') {
                const eu_countries = ["Austria", "Belgium", "Bulgaria", "Croatia", "Republic of Cyprus", "Czech Republic", "Denmark", "Estonia", "Finland", "France", "Germany", "Greece", "Hungary", "Ireland", "Italy", "Latvia", "Lithuania", "Luxembourg", "Malta", "Netherlands", "Poland", "Portugal", "Romania", "Slovakia", "Slovenia", "Spain", "Sweden"];
                if (eu_countries.includes(this.props.user.country)) {
                    charge = (parseFloat(balanceModel.amount) * (1.4 / 100)).toFixed(2);
                    charge = parseFloat(charge) + 0.25;

                }
                else if (this.props.user.country === 'United Kingdom') {
                    charge = (parseFloat(balanceModel.amount) * (1.1 / 100)).toFixed(2);
                    charge = parseFloat(charge) + 0.25;

                } else {
                    charge = (parseFloat(balanceModel.amount) * (2.9 / 100)).toFixed(2);
                    charge = parseFloat(charge) + 0.25;
                }
            }

            if (balanceModel.method === 'ALIPAY' || balanceModel.method === 'WECHAT_PAY') {
                charge = (parseFloat(balanceModel.amount) * (2.9 / 100)).toFixed(2);
                charge = parseFloat(charge) + 0.25;
            }

            if (balanceModel.method === 'BANCONTACT' || balanceModel.method === 'GIROPAY' || balanceModel.method === 'SOFORT') {
                charge = (parseFloat(balanceModel.amount) * (1.4 / 100)).toFixed(2);
                charge = parseFloat(charge) + 0.25;
            }

            if (balanceModel.method === 'EPS') {
                charge = (parseFloat(balanceModel.amount) * (1.6 / 100)).toFixed(2);
                charge = parseFloat(charge) + 0.25;
            }

            if (balanceModel.method === 'IDEAL') {
                charge = 0.29;
            }

            if (balanceModel.method === 'P24') {
                charge = (parseFloat(balanceModel.amount) * (2.2 / 100)).toFixed(2);
                charge = parseFloat(charge) + 0.25;
            }

            if (balanceModel.method === 'PAYPAL') {
                charge = (parseFloat(balanceModel.amount) * (3.5 / 100)).toFixed(2);
            }

            vat = (parseFloat(balanceModel.amount) * (21 / 100)).toFixed(2);
            let total: any = (parseFloat(balanceModel.amount) + parseFloat(charge) + parseFloat(vat)).toFixed(2);

            this.setState((prevState: RechargeBalanceState) => {
                prevState.balanceModel.charge = charge;
                prevState.balanceModel.vat = vat;
                prevState.balanceModel.total_amount = total;
                return prevState;
            });
        } else {
            this.setState((prevState: RechargeBalanceState) => {

                prevState.balanceModel.charge = charge;
                prevState.balanceModel.vat = vat;
                prevState.balanceModel.total_amount = '';
                return prevState;
            });
        }
    }

    public handleStep = (step: number) => {
        this.setState((prevState) => ({
            activeStep: step
        }))
    }

    public handleClickBack = () => {
        const { activeStep } = this.state;
        const { onClose } = this.props;
        if (activeStep === 0 && onClose) onClose({}, 'backdropClick')
        if (activeStep > 0) {
            this.handleStep(activeStep - 1);
        }
        if (activeStep > 1) {
            this.setState((prevState) => ({
                loading: false,
                showPaypal: false,
                clientSecret: ""
            }))
        }
    }

    public handleAmountClick = (amount: any, props: FormikProps<any>) => {
        if (!amount || !amount.value) return;
        props.setFieldValue('amount', amount.value);
        this.setState((prevState: RechargeBalanceState) => ({
            balanceModel: { ...prevState.balanceModel, amount: amount.value }
        }))
    }

    public handleRechargeSubmit = (values: any, actions: FormikHelpers<any>) => {
        const { activeStep } = this.state;
        actions.setSubmitting(false);
        if (activeStep < 1) {
            this.handleStep(activeStep + 1);
            this.getCharges()
        } else if (activeStep === 1) {
            this.handleNextClick()
        } else if (activeStep > 1) {
            this.handlePay(actions);
        }
    }

    public handleChangeCardType = (card: string) => {
        if (!card) return;
        this.setState((prevState: RechargeBalanceState) => ({
            cardType: card,
            balanceModel: { ...prevState.balanceModel, method: card },
            showPaypal: false,
            clientSecret: ""
        }), () => this.getCharges())
    }

    public handleAmountChange = (event: ChangeEvent<HTMLInputElement>, props: FormikProps<any>) => {
        const { name, value } = event.target;
        props.setFieldValue(name, value);
        this.setState((prevState: RechargeBalanceState) => ({
            balanceModel: { ...prevState.balanceModel, [name]: value }
        }))
    }

    public handleNextClick = () => {
        this.setState((prevState: RechargeBalanceState) => ({ loading: true }))
        const { balanceModel, activeStep } = this.state;
        const { modalType } = this.props;
        this.handleStep(activeStep + 1);
        if (balanceModel.method === 'PAYPAL') {
            this.setState((prevState) => ({
                loading: false,
                showPaypal: true
            }))
        } else {
            let params = {
                ...balanceModel,
                method: balanceModel.method.toLowerCase(),
                type: modalType === 'NAVIXY' ? 'insights' : modalType.toLowerCase()
            }
            RechargeService.initStripeRecharge(params).then((resp) => {
                this.setState((prevState: RechargeBalanceState) => ({
                    clientSecret: resp.data.clientSecret,
                    loading: false
                }))
            }).catch((err) => {
                this.setState((prevState: RechargeBalanceState) => ({ loading: true }))
                return err;
            })
        }
    }

    public handlePay = (actions: FormikHelpers<any>) => {
        if (this.stripeChildRef.current) {
            this.stripeChildRef.current.handleSubmit();
        }
    };

    render() {
        const { open, onClose, title, modalType, history, ...restProps } = this.props;
        const { activeStep, balanceModel, cardType, paymentMethods, showPaypal, clientSecret, loading } = this.state;
        const public_key: string = process.env.REACT_APP_STRIPE_PUBLIC_KEY ? process.env.REACT_APP_STRIPE_PUBLIC_KEY : '';
        const stripePromise = loadStripe(public_key);
        const appearance = { theme: 'stripe' };
        const options: any = { clientSecret, appearance };
        return (
            <MuiDialog open={open} onClose={onClose} {...restProps}>
                <StyledCreateModal>
                    <Formik validationSchema={RechargeValidator} initialValues={balanceModel} onSubmit={this.handleRechargeSubmit}>
                        {(props) => (
                            <MuiBox onSubmit={props.handleSubmit} component='form' className="balanceModal">
                                <MuiBox display='flex' alignItems='center' justifyContent='space-between' className='heading'>
                                    <MuiDialogTitle className='title'>{title}</MuiDialogTitle>
                                    {onClose &&
                                        <MuiIconButton onClick={() => onClose({}, 'backdropClick')}>
                                            <CloseIcon />
                                        </MuiIconButton>
                                    }
                                </MuiBox>
                                <MuiDialogContent sx={{ padding: '10px 24px' }} className='details-body'>
                                    {activeStep === 0 &&
                                        <MuiBox>
                                            <MuiBox className='btn-grp'>
                                                <MuiGrid container spacing={2}>
                                                    {Map(PRICES, (price, priceIndex) => (
                                                        <MuiGrid key={priceIndex} className='btnGrpGrid' item md={4} xs={6}>
                                                            <MuiButton onClick={() => this.handleAmountClick(price, props)} className='euro-btn'>
                                                                € {price.label}
                                                            </MuiButton>
                                                        </MuiGrid>
                                                    ))}
                                                </MuiGrid>
                                            </MuiBox>
                                            <MuiDivider sx={{ marginTop: 2, textAlign: 'center' }} />
                                            <MuiBox className='lower-content'>
                                                <MuiBox className="first-row" sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                                    <MuiTypography mr={2} className='euro-text'>€</MuiTypography>

                                                    <MuiInputField
                                                        className='val-txt'
                                                        name='amount'
                                                        onBlur={props.handleBlur}
                                                        onChange={(event: ChangeEvent<HTMLInputElement>) => this.handleAmountChange(event, props)}
                                                        value={props.values.amount}
                                                        variant="standard"
                                                        error={props.touched.amount && !!props.errors.amount}
                                                        helperText={props.touched.amount && props.errors.amount && String(props.errors.amount)}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                        }}
                                                    />

                                                </MuiBox>

                                                <MuiBox className="second-row" sx={{ display: 'flex', justifyContent: 'space-evenly', marginLeft: '3rem', alignItems: 'center' }}>
                                                    <MuiBox>
                                                        <MuiTypography className='normal-text'>Total</MuiTypography>
                                                    </MuiBox>
                                                    <MuiBox>
                                                        <MuiTypography className='euro-text'>€</MuiTypography>
                                                    </MuiBox>
                                                    <MuiBox>
                                                        <MuiTypography className='euro-text'>
                                                            {props.values.amount && Number(props.values.amount) ? `${props.values.amount}.00` : '00.00'}
                                                        </MuiTypography>
                                                    </MuiBox>
                                                </MuiBox>
                                            </MuiBox>
                                        </MuiBox>
                                    }
                                    {activeStep === 1 &&
                                        <MuiBox>
                                            <MuiBox className='btn-grp'>
                                                <MuiGrid container spacing={2}>
                                                    {paymentMethods && paymentMethods.length > 0 && Map(paymentMethods, (card, cardIndex) => (
                                                        <MuiGrid key={cardIndex} item md={4} xs={6}>
                                                            <MuiIconButton onClick={() => this.handleChangeCardType(card.key as string)}
                                                                className={`euro-btn ${cardType === card.key && 'selected'}`}
                                                            >
                                                                <card.icon sx={{ marginRight: '10px' }} /> {card.label}
                                                            </MuiIconButton>
                                                        </MuiGrid>
                                                    ))}
                                                </MuiGrid>
                                            </MuiBox>
                                            <MuiBox className='low-content'>
                                                <MuiGrid container spacing={2} sx={{ marginTop: '2rem' }}>
                                                    <MuiGrid item md={12} sm={12} className='right-box'>
                                                        <MuiBox className='first-row' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                            <MuiTypography className='first-txt'>Amount</MuiTypography>
                                                            <MuiBox className="secondRow">
                                                                <MuiTypography>€</MuiTypography>
                                                                <MuiTypography className='second-txt'>
                                                                    {props.values.amount && Number(props.values.amount) ? `${props.values.amount}.00` : '00.00'}
                                                                </MuiTypography>
                                                            </MuiBox>
                                                        </MuiBox>
                                                        <MuiBox className='first-row' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                            <MuiTypography className='first-txt'>Vat</MuiTypography>
                                                            <MuiBox className="secondRow">
                                                                <MuiTypography>€</MuiTypography>
                                                                <MuiTypography className='second-txt'>{balanceModel.vat ? `${balanceModel.vat}` : '00.00'}</MuiTypography>
                                                            </MuiBox>
                                                        </MuiBox>
                                                        <MuiBox className='first-row' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                            <MuiTypography className='first-txt'>Transaction Charge</MuiTypography>
                                                            <MuiBox className="secondRow">
                                                                <MuiTypography>€</MuiTypography>
                                                                <MuiTypography className='second-txt'>{balanceModel.charge ? `${balanceModel.charge}` : '00.00'}</MuiTypography>
                                                            </MuiBox>
                                                        </MuiBox>
                                                        <MuiDivider sx={{ marginBottom: '1rem', width: '100%', textAlign: 'center' }} />
                                                        <MuiBox className='second-row' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                            <MuiTypography className='first-txt'>Total</MuiTypography>
                                                            <MuiBox className="secondRow">
                                                                <MuiTypography>€</MuiTypography>
                                                                <MuiTypography className='second-txt'>
                                                                    {balanceModel.total_amount && Number(balanceModel.total_amount) ? `${balanceModel.total_amount}` : '00.00'}
                                                                </MuiTypography>
                                                            </MuiBox>
                                                        </MuiBox>
                                                    </MuiGrid>
                                                </MuiGrid>
                                            </MuiBox>

                                        </MuiBox>
                                    }
                                    {activeStep === 2 &&
                                        <MuiBox>
                                            {loading ?
                                                <MuiBox className="loadingBox">
                                                    <CircularProgress />
                                                </MuiBox> :
                                                <>
                                                    {clientSecret &&
                                                        <Elements stripe={stripePromise} options={options}>
                                                            <StripeCheckout ref={this.stripeChildRef} />
                                                        </Elements>
                                                    }
                                                    {showPaypal && <PayPalCheckout history={history} balanceModel={balanceModel} />}
                                                </>
                                            }

                                        </MuiBox>
                                    }
                                </MuiDialogContent>

                                <MuiDialogActions className='btn' sx={{ marginTop: '10px', marginBottom: '8px' }}>
                                    <MuiButton onClick={this.handleClickBack} className='bck-btn' disabled={loading}>{activeStep === 0 ? 'Cancel' : 'Back'}</MuiButton>
                                    <MuiButton type='submit' disabled={loading} className='nxt-btn'>{activeStep >= 2 ? 'Pay' : 'Next'}</MuiButton>
                                </MuiDialogActions>
                            </MuiBox>
                        )}
                    </Formik>
                </StyledCreateModal>
            </MuiDialog>
        )
    }
}

export default AddBalanceModal