
import { RoleModel, PermissionModel } from "../Models"

export enum ConfigActionTypes {
    LoadRoles = 'LoadRoles',
    AddRole = 'AddRole',
    UpdateRole = 'UpdateRole',
    DeleteRole = 'DeleteRole',
    LoadPermissions = 'LoadPermissions',
}

export const LoadRoles = (payload: Array<RoleModel>) => {
    return {
        type: ConfigActionTypes.LoadRoles,
        payload
    }
}

export const AddRole = (payload: RoleModel) => {
    return {
        type: ConfigActionTypes.AddRole,
        payload
    }
}

export const UpdateRole = (payload: RoleModel) => {
    return {
        type: ConfigActionTypes.UpdateRole,
        payload
    }
}

export const DeleteRole = (payload: string) => {
    return {
        type: ConfigActionTypes.DeleteRole,
        payload
    }
}

export const LoadPermissions = (payload: Array<PermissionModel>) => {
    return {
        type: ConfigActionTypes.LoadPermissions,
        payload
    }
}

export class ConfigActions {
    type: ConfigActionTypes;
    payload: any | RoleModel;
}

