import { GetDateTime, convertToMb, getNetworkNames } from "Helpers/String";
import { BaseStateModel, DataTableModel, GroupModel, SimModel, SimConnectivityEvent } from "Redux/Models";
import { SimService } from "Services";

export class SimListState extends BaseStateModel {
    public isAddSimToCompanyModalOpen: boolean = false;
    public groups: Array<GroupModel> = [];
    public dataTable: DataTableModel = {
        pagination: true,
        searchable: true,
        limit: 10,
        active: 'createdAt',
        direction: 'desc',
        actions: [],
        request: SimService.getList,
        headers: [
            { key: 'thingName', label: 'Name', isSort: true },
            { key: 'externalUniqueId', label: 'ICCID', isSort: true },
            { key: 'thingsGroupName', label: 'Group', isSort: true },
            { key: 'MNOname', label: 'Network', isSort: true, render: (item: SimModel) => item.MNOname ? item.MNOname : 'N/A'},
            { key: 'actualUsage.Data_', label: 'Data Usage', isSort: true, render: (item: SimModel) => convertToMb(Number(item.actualUsage.Data_)) },
            { key: 'UpdatedAt', label: 'Last Activity', isSort: true, render: (item: SimModel) => item.UpdatedAt && GetDateTime(item.UpdatedAt) },
            { key: 'state', label: 'Status', isSort: true },
        ],
    };
}

export class SimDetailState extends BaseStateModel {
    public simDetails: SimModel | null = null;
    public simState: string = 'ACTIVE';
    public simTotalDataUsage: string | number = '';
}

export class SimHistoryState extends BaseStateModel {
    public isSimHistoryModalOpen: boolean = false;
    public loading: boolean = false;
    public simHistories: Array<SimModel> = [];
    public insightDevice: any = {
        name: '',
        model: null,
        group: ''
    };
    public groupList: Array<GroupModel> = [];
    public modelsList: Array<any> = [];
}

export class SimUsageState extends BaseStateModel {
    public simUsages: Array<number | []> = [];
}

export class SimConnectivityEventState extends BaseStateModel {
    public isLoading: boolean = false;
    public connectivityData: Array<SimConnectivityEvent> = [];
    public currentPage: number = 1;
    public limit: number = 10;
}

export class AddSimToCompanyState extends BaseStateModel {
    public sims: Array<SimModel> = [];
    public companySimData: any = {
        seletedSims: []
    }
}

export class SimCountryUsageState extends BaseStateModel {
    public simCountryUsage: Array<any> = [];
    public isViewAllModalOpen: boolean = false;
    public isLoading: boolean = false;
}