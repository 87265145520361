import { MenuItem, StandardTextFieldProps, TextField, styled } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { Map } from 'Helpers/Array';
import { GetValue } from 'Helpers/Object';
import { Component, ReactNode } from 'react';
import { MuiBox } from './index';

interface TextListProps<T> extends StandardTextFieldProps {
    items: Array<T>;
    valuefield?: string;
    titlefield?: string;
}

const PlaceHolder = styled('span')(() => ({
    position: 'absolute',
    top: '20px',
    left: '10px',
    opacity: 0.5,
    right: 'auto',
    transform: 'translate(10px, -50%)',
    fontSize: '15px',
    cursor: 'pointer'
}));

export default class MuiListField<T extends {}> extends Component<TextListProps<T>> {
    public render(): ReactNode {
        const { items, titlefield = 'label', valuefield = 'value' }: TextListProps<T> = this.props;
        return (
            <MuiBox sx={{ position: 'relative' }}>
                <TextField {...this.props} select fullWidth className={'multiSelectFieldMainWrap'}
                    sx={{
                        '& .MuiSelect-select': { textAlign: 'left', padding: '4.5px 14px' }
                    }}
                >
                    {items && items.length > 0 ? (
                        Map(items, (option: T, i) => (
                            <MenuItem
                                key={i}
                                value={GetValue(option, valuefield)}
                                sx={[
                                    (theme: Theme) => ({
                                        px: 3.5,
                                        whiteSpace: 'break-spaces',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: '-webkit-box',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: 2,
                                        [theme.breakpoints.down("sm")]: {
                                            minHeight: 35,
                                        },
                                        '&:not(:last-child)': {
                                            mb: '5px',
                                        },
                                        '&.Mui-selected': {
                                            position: 'relative',
                                            backgroundColor: theme.palette.primary.light,
                                            color: theme.palette.secondary.dark,
                                            '&:hover': {
                                                backgroundColor: theme.palette.primary.light,
                                            }
                                        },
                                    })
                                ]}>
                                {GetValue(option, titlefield)}
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem value="" sx={{ pointerEvents: 'none', color: '#000' }} disabled={true}>
                            No Data Found
                        </MenuItem>
                    )}
                </TextField>
                {!this.props.value && this.props.placeholder && <PlaceHolder className='placeHolder'>{this.props.placeholder}</PlaceHolder>}
            </MuiBox>
        );
    }
}
