import { MuiBackdrop, MuiBox, MuiButton, MuiCard, MuiTypography } from "Components/MUI";
import { Component } from "react";
import { StyledNotificationTab } from "./style";
import { UserIcon } from "Helpers/CustomIcons";
import { BackdropProps } from "@mui/material";
import { NotificationService } from "Services";
import { NotificationState } from "Redux/States";
import { NotificationModel, Props } from "Redux/Models";

interface NotificationTabProps extends BackdropProps, Props {
    onClose: () => void
}

export class NotificationTab extends Component<NotificationTabProps> {
  public state: NotificationState = new NotificationState();

  public setLoading = (isLoad: boolean) => {
    this.setState((prevState) => ({
      isLoading: isLoad,
    }));
  };

  public componentDidMount(): void {
    this.getSNotificationList();
  }

  public getSNotificationList = () => {
    this.setLoading(true);
    NotificationService.getList()
      .then((resp) => {
        if (resp.isSuccess()) {
          this.setState((prevState: NotificationState) => ({
            notifications: [...prevState.notifications, ...(resp.data as any)],
          }));
          this.setLoading(false);
        }
      })
      .catch((err) => {
        this.setLoading(false);
        return err;
      });
  };

  public readNotification = (event: any, value: NotificationModel) => {
    if (!value || value.isRead) return;
    const { notifications } = this.state;

    const { socket } = this.props;
    socket.emit("readNotification", {
      _id: value._id,
      isRead: true,
    });
    const allNotifications = notifications.map((val) => {
      if (val && val._id === value._id) {
        val["isRead"] = true;
      }
      return val;
    });
    this.setState((prevState: NotificationState) => ({
      notifications: allNotifications,
    }));
  };

  render() {
    const { notifications } = this.state;
    const { onClose } = this.props;
    return (
      <MuiBackdrop
        open
        onClick={onClose}
        sx={{
          width: "calc(100% - 820px)",
          left: "auto",
          zIndex: 1,
        }}
      >
        <StyledNotificationTab onClick={(e) => e.stopPropagation()}>
          <MuiBox className="heading">
            <MuiTypography component="h2" className="page-title">
              <MuiTypography component="span">Notifications</MuiTypography>
            </MuiTypography>
          </MuiBox>
          <MuiBox className="content">
            <MuiTypography className="filter-text">Filters</MuiTypography>
            <MuiBox className="btn-grp">
              <MuiButton
                onClick={() => console.log("ALL")}
                variant="outlined"
                className="btn"
              >
                All
              </MuiButton>
              <MuiButton variant="outlined" className="btn">
                Invoice
              </MuiButton>
              <MuiButton variant="outlined" className="btn">
                Warning
              </MuiButton>
              <MuiButton variant="outlined" className="btn">
                Rule
              </MuiButton>
            </MuiBox>

            {notifications.length > 0 ? (
              <MuiBox>
                <MuiBox className="row">
                  {notifications.map(
                    (val: NotificationModel, index: number) => (
                      <MuiCard key={index} className="row-content">
                        <div className="left">
                          <div className="icon">
                            <UserIcon />
                          </div>
                        </div>
                        <div
                          className="right"
                          onClick={(e) =>
                            val.isRead
                              ? undefined
                              : this.readNotification(e, val)
                          }
                        >
                          <MuiTypography className="title">
                            {val.type}
                          </MuiTypography>
                          <MuiTypography
                            className="sub-title"
                            sx={{ fontWeight: val.isRead ? 300 : 600 }}
                          >
                            {val.message}
                          </MuiTypography>
                        </div>
                      </MuiCard>
                    )
                  )}
                </MuiBox>
              </MuiBox>
            ) : (
              <div className="not-found">
                <MuiTypography textAlign="center">
                  No notification found
                </MuiTypography>
              </div>
            )}
          </MuiBox>
        </StyledNotificationTab>
      </MuiBackdrop>
    );
  }
}

export default NotificationTab
