import loadable from "@loadable/component";
import { ComponentType } from "react";
import { Fallback } from "Components";

const LoginView = loadable(() => import('../Views/Auth/LoginView/LoginView'), { fallback: <Fallback /> })
const ResetPasswordView = loadable(() => import('../Views/Auth/ResetPasswordView/ResetPasswordView'), { fallback: <Fallback /> })
const ForgetPasswordView = loadable(() => import('../Views/Auth/ForgetPasswordView/ForgetPasswordView'), { fallback: <Fallback /> })
const ConfirmPasswordView = loadable(() => import('../Views/Auth/ConfirmPasswordView/ConfirmPasswordView'), { fallback: <Fallback /> })

export interface RouteInterface {
    key: string,
    path: string;
    exact?: boolean,
    name: string;
    component: ComponentType<any>,
}

export const routes: Array<RouteInterface> = [
    { key: 'login', path: '/login', exact: true, name: 'Login', component: LoginView },
    { key: 'forgetPassword', path: '/forget-password', exact: true, name: 'Forget Password', component: ForgetPasswordView },
    { key: 'resetPassword', path: '/reset-password/:token', exact: true, name: 'Reset Password', component: ResetPasswordView },
    { key: 'confirmPassword', path: '/set-navixy-password/:token', exact: true, name: 'Confirm Password', component: ConfirmPasswordView },
]