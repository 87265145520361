import { DialogProps } from '@mui/material'
import { MuiBox, MuiButton, MuiDialog, MuiDialogActions, MuiDialogContent, MuiDialogTitle, MuiGrid, MuiIconButton, MuiInputField } from 'Components/MUI'
import { Component } from 'react'
import { StyledCreateModal } from './style';
import { CloseIcon } from 'Helpers/Icons';
import { ChangePasswordState } from 'Redux/States';
import { Formik, FormikHelpers } from 'formik';
import { States } from 'Redux/Models';
import { ChangePasswordValidator } from 'Helpers/Validators';

interface ChangePasswordDialogProps extends DialogProps {
    title: string,
    onFormSubmit: Function
}

class ChangePasswordModal extends Component<ChangePasswordDialogProps> {
    public state: ChangePasswordState = new ChangePasswordState();

    public handlePasswordSubmit = (values: States, actions: FormikHelpers<States>) => {
        const { onFormSubmit } = this.props;
        actions.setSubmitting(true);
        if (onFormSubmit) onFormSubmit(values, actions);
    }

    render() {
        const { form } = this.state;
        const { open, onClose, title, onFormSubmit, ...restProps }: ChangePasswordDialogProps = this.props;
        return (
            <MuiDialog open={open} onClose={onClose} {...restProps}>
                <StyledCreateModal>
                    <Formik initialValues={form} onSubmit={this.handlePasswordSubmit} validationSchema={ChangePasswordValidator}>
                        {(props) => (
                            <MuiBox component='form' noValidate onSubmit={props.handleSubmit}>
                                <MuiBox display='flex' alignItems='center' justifyContent='space-between' className='heading'>
                                    <MuiDialogTitle className='title'>{title}</MuiDialogTitle>
                                    {onClose &&
                                        <MuiIconButton onClick={() => onClose({}, 'backdropClick')}>
                                            <CloseIcon />
                                        </MuiIconButton>
                                    }
                                </MuiBox>
                                <MuiDialogContent className='content'>
                                    <MuiGrid container spacing={2}>
                                        <MuiGrid item sm={6} xs={12}>
                                            <MuiBox component="label" className="fieldTopLabel">Current Password</MuiBox>
                                            <MuiInputField
                                                placeholder='Current Password'
                                                name='currentPassword'
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={props.values.currentPassword}
                                                error={props.touched.currentPassword && !!props.errors.currentPassword}
                                                helperText={props.touched.currentPassword && props.errors.currentPassword && String(props.errors.currentPassword)}
                                            />
                                        </MuiGrid>
                                        <MuiGrid item sm={6} xs={12}>
                                            <MuiBox component="label" className="fieldTopLabel">New Password</MuiBox>
                                            <MuiInputField
                                                placeholder='New Password'
                                                name='password'
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={props.values.password}
                                                error={props.touched.password && !!props.errors.password}
                                                helperText={props.touched.password && props.errors.password && String(props.errors.password)}
                                            />
                                        </MuiGrid>
                                        <MuiGrid item sm={12} xs={12}>
                                            <MuiBox component="label" className="fieldTopLabel">Confirm Password</MuiBox>
                                            <MuiInputField
                                                placeholder='Confirm Password'
                                                name='confirmPassword'
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={props.values.confirmPassword}
                                                error={props.touched.confirmPassword && !!props.errors.confirmPassword}
                                                helperText={props.touched.confirmPassword && props.errors.confirmPassword && String(props.errors.confirmPassword)}
                                            />
                                        </MuiGrid>
                                    </MuiGrid>
                                </MuiDialogContent>
                                <MuiDialogActions className='button'>
                                    <MuiBox className='submit-btn'>
                                        <MuiButton loading={props.isSubmitting} disabled={props.isSubmitting} type='submit' className='btn create-btn'>
                                            Update
                                        </MuiButton>
                                        {onClose &&
                                            <MuiButton variant="outlined" className='btn cancel-btn' onClick={() => onClose({}, 'backdropClick')}>
                                                Back
                                            </MuiButton>
                                        }
                                    </MuiBox>
                                </MuiDialogActions>
                            </MuiBox>
                        )}
                    </Formik>
                </StyledCreateModal>
            </MuiDialog>
        )
    }
}

export default ChangePasswordModal;