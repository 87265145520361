import { Http } from "Http";
import BaseService from "./BaseService";
import { GroupModel, Response, UserModel } from "Redux/Models";

export default class NavixyService extends BaseService {

    public static getNavixyReports(): Promise<Response<any>> {
        return new Promise((resolve, reject) => {
            Http.get('/navixy/reports').then((res) => {
                if (res?.data.status === 200) {
                    return resolve(new Response(res?.data));
                }
            }).catch((err) => {
                return reject(err);
            });
        });
    }

    public static getNavixyLoginUrl(): Promise<Response<any>> {
        return new Promise((resolve, reject) => {
            Http.get('/navixy/user-login').then((res) => {
                if (res?.data.status === 200) {
                    return resolve(new Response(res?.data));
                }
            }).catch((err) => {
                return reject(err);
            });
        });
    }

    public static registerInNavixy(): Promise<Response<UserModel>> {
        return new Promise((resolve, reject) => {
            Http.post('/navixy/create-user').then((res) => {
                if (res?.data.status === 200) {
                    return resolve(new Response(res.data));
                }
            }).catch((err) => {
                return reject(err);
            })
        })
    }

    public static setNavixyPassword(params: Object): Promise<Response<UserModel>> {
        return new Promise((resolve, reject) => {
            Http.post('/navixy/set-password', params).then(res => {
                let data = new Response<UserModel>(res?.data);
                return resolve(data);
            }).catch(err => {
                return reject(err);
            })
        })
    }

    public static groupListDevice(params = {}): Promise<Response<GroupModel>> {
        NavixyService.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.get('/navixy/group-list', params).then((res) => {
                if (res?.data.status === 200) {
                    return resolve(new Response(res.data));
                }
            }).catch((err) => {
                return reject(err);
            })
        })
    }

    public static modelListDevice(params = {}): Promise<Response<any>> {
        NavixyService.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.get('/navixy/list_models', params).then((res) => {
                if (res?.data.status === 200) {
                    return resolve(new Response(res.data));
                }
            }).catch((err) => {
                return reject(err);
            })
        })
    }

    public static addDevice(params = {}): Promise<Response<any>> {
        NavixyService.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.post('/navixy/add-device', params).then((res) => {
                if (res?.data.status === 200) {
                    return resolve(new Response(res.data));
                }
            }).catch((err) => {
                return reject(err);
            })
        })
    }

}