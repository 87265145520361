import { BaseStateModel, DataTableModel, PriceModel, ZoneModel } from "Redux/Models";
import { PriceService } from "Services";

export class PriceListState extends BaseStateModel {
    public dataTable: DataTableModel = {
        pagination: true,
        searchable: false,
        limit: 10,
        active: 'createdAt',
        direction: 'desc',
        actions: [],
        request: PriceService.getList,
        headers: [
            { key: 'name', label: 'Name', isSort: true },
            { key: 'price.margin', label: 'Margin', isSort: true },
            { key: 'price.monthlyCost', label: 'Monthly Cost', isSort: true },
            { key: 'price.smsCost', label: 'SMS Cost', isSort: true },
            { key: 'price.pricePerDevice', label: 'Price Per Device', isSort: true },
        ],
    };
}

export class PriceCreateState extends BaseStateModel {
    public isLoading: boolean = false;
    public prevZones: ZoneModel[] = [];
    public price: PriceModel = {
        name: '',
        baseMargin: '',
        baseMonthlyCost: '',
        baseSMSCost: '',
        pricePerDevice: '',
        zones: [],
    }
}