import { Box, styled as MuiStyle } from '@mui/material';

export const RootContainer = MuiStyle(Box)(({ theme }) => ({
    '& .loader': {
        position: 'relative',
        border: `2px solid ${theme.palette.primary.dark}`,
        WebkitAnimation: 'counterClockwise 3s linear infinite',
        animation: 'counterClockwise 3s linear infinite',
        width: 90,
        height: 90,
        borderRadius: '50%',
        '&:before': {
            content: '""',
            position: 'absolute',
            borderRadius: '50%',
            width: 16,
            height: 16,
            background: theme.palette.primary.dark,
            WebkitAnimation: 'clockwise 3s linear infinite',
            animation: 'clockwise 3s linear infinite',
            top: 10,
        },
        '&:after': {
            content: '""',
            position: 'absolute',
            borderRadius: '50%',
            width: 8,
            height: 8,
            top: 25,
            left: 16,
            background: theme.palette.primary.dark,
            transformOrigin: '-8px -8px',
            WebkitAnimation: 'counterClockwise 3s linear infinite',
            animation: 'counterClockwise 3s linear infinite',
        }
    },
    '@keyframes clockwise': {
        'to': {
            transform: 'rotate(1turn)'
        },
    },
    '@keyframes counterClockwise': {
        'to': {
            transform: 'rotate(-1turn)'
        },
    },

}));
