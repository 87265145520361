import loadable from "@loadable/component";
import { Fallback } from "Components";
import { MODULES, PERMISSIONS } from "Configs/Constants";

const UserListView = loadable(
  () => import("../Views/Dashboard/UserView/UserListView"),
  { fallback: <Fallback /> }
);
const PriceListView = loadable(
  () => import("../Views/Dashboard/PriceView/PriceListView"),
  { fallback: <Fallback /> }
);
const DashboardView = loadable(
  () => import("../Views/Dashboard/DashboardView/DashboardView"),
  { fallback: <Fallback /> }
);
const PriceEditView = loadable(
  () => import("../Views/Dashboard/PriceView/PriceEditView"),
  { fallback: <Fallback /> }
);
const CompanyListView = loadable(
  () => import("../Views/Dashboard/CompanyView/CompanyListView"),
  { fallback: <Fallback /> }
);
const CompanyDetailsView = loadable(
  () => import("../Views/Dashboard/CompanyView/CompanyDetailsView"),
  { fallback: <Fallback /> }
);
const SimListView = loadable(
  () => import("../Views/Dashboard/SimView/SimListView"),
  { fallback: <Fallback /> }
);
const SimDetailsView = loadable(
  () => import("../Views/Dashboard/SimView/SimDetailsView"),
  { fallback: <Fallback /> }
);
const CompanyCreateView = loadable(
  () => import('../Views/Dashboard/CompanyView/CompanyCreateView'),
  { fallback: <Fallback /> }
)

const AutomationListView = loadable(
  () => import('../Views/Dashboard/Automation/AutomationListView'),
  { fallback: <Fallback /> }
)

const CustomerUsageReport = loadable(
  () => import('../Views/Dashboard/Reports/CustomerUsageReportView'), 
  { fallback: <Fallback />}
);

const CustomerUsageReportView = loadable(
  () => import('../Views/Dashboard/Reports/HighUsageCustomerReport.View'), 
  { fallback: <Fallback />}
);

const ProviderDataUsageReport = loadable(
  () => import('../Views/Dashboard/Reports/ProviderDataUsageReport.View'), 
  { fallback: <Fallback />}
);

const CountryWiseDataUsageReport = loadable(
  () => import('../Views/Dashboard/Reports/CountrywiseTotalConsumptionReport.View'), 
  { fallback: <Fallback />}
);

const GeographicDataConsumptionReport = loadable(
  () => import('../Views/Dashboard/Reports/GeographicDataConsumptionReport.View'), 
  { fallback: <Fallback />}
);

const DataUsageTrendReport = loadable(
  () => import('../Views/Dashboard/Reports/DataUsageTrendReport.View'), 
  { fallback: <Fallback />}
);

const DeviceUsageReport = loadable(
  () => import('../Views/Dashboard/Reports/DeviceUsageReport.View'), 
  { fallback: <Fallback />}
);

const CustomerProviderPrefarenceReport = loadable(
  () => import('../Views/Dashboard/Reports/CustomerProviderPrefarenceReport.View'), 
  { fallback: <Fallback />}
);

const CostAnalysisReport = loadable(
  () => import('../Views/Dashboard/Reports/CostAnalystReport.View'), 
  { fallback: <Fallback />}
);

const HighUsageCustomerReport = loadable(
  () => import('../Views/Dashboard/Reports/ProviderPerformanceReport.View'),
  { fallback: <Fallback /> }
);

const ProviderCostAnalysisReport = loadable(
  () => import('../Views/Dashboard/Reports/ProviderCostAnalysisReport.View'),
  { fallback: <Fallback /> }
);

const CustomerDetailReport = loadable(
  () => import('../Views/Dashboard/Reports/CustomerDetailReport.View'),
  { fallback: <Fallback /> }
);

const ProviderTotalConReport = loadable(
  () => import('../Views/Dashboard/Reports/ProviderTotalConsumptionReport.View'),
  { fallback: <Fallback /> }
);

const GroupDetails = loadable(
  () => import('../Views/Dashboard/UserView/Tabs/GroupTab/GroupDetails'),
  { fallback: <Fallback /> }
);


export interface RouteInterface {
  key: string;
  path: string;
  exact?: boolean;
  name: string;
  module?: string;
  permissions?: Array<string>;
  component: any;
}

export const routes: Array<RouteInterface> = [
  {
    key: "dashboard",
    path: "/dashboard",
    exact: true,
    name: "Dashboard",
    component: DashboardView,
  },
  {
    key: "userList",
    path: "/users",
    exact: true,
    module: MODULES.USER_MANAGEMENT,
    permissions: [PERMISSIONS.READ],
    name: "Users",
    component: UserListView,
  },
  {
    key: "priceList",
    path: "/prices",
    exact: true,
    module: MODULES.PRICING_MANAGEMENT,
    permissions: [PERMISSIONS.READ],
    name: "Prices",
    component: PriceListView,
  },
  {
    key: "priceAdd",
    path: "/prices/add",
    exact: true,
    module: MODULES.PRICING_MANAGEMENT,
    permissions: [PERMISSIONS.READ_WRITE],
    name: "Create Price",
    component: PriceEditView,
  },
  {
    key: "priceEdit",
    path: "/prices/:id",
    exact: true,
    module: MODULES.PRICING_MANAGEMENT,
    permissions: [PERMISSIONS.READ_WRITE],
    name: "Update Price",
    component: PriceEditView,
  },
  {
    key: "companies",
    path: "/companies",
    exact: true,
    module: MODULES.COMPANY_MANAGEMENT,
    permissions: [PERMISSIONS.READ],
    name: "Companies",
    component: CompanyListView,
  },
  {
    key: "companyCreate",
    path: "/companies/add",
    exact: true,
    module: MODULES.COMPANY_MANAGEMENT,
    permissions: [PERMISSIONS.READ_WRITE],
    name: "Create Company",
    component: CompanyCreateView
  },
  {
    key: "CompaniesDetails",
    path: "/companies/:id",
    exact: true,
    module: MODULES.COMPANY_MANAGEMENT,
    permissions: [PERMISSIONS.READ_WRITE],
    name: "View Companies",
    component: CompanyDetailsView,
  },
  {
    key: "CompaniesUpdate",
    path: "/companies/edit/:id",
    exact: true,
    module: MODULES.COMPANY_MANAGEMENT,
    permissions: [PERMISSIONS.READ_WRITE],
    name: "Edit Companies",
    component: CompanyCreateView,
  },
  {
    key: "SimList",
    path: "/sims",
    exact: true,
    module: MODULES.SIMS_MANAGEMENT,
    permissions: [PERMISSIONS.READ],
    name: "Sims",
    component: SimListView,
  },
  {
    key: "SimDetails",
    path: "/sims/:id",
    exact: true,
    module: MODULES.SIMS_MANAGEMENT,
    permissions: [PERMISSIONS.READ_WRITE],
    name: "Sims Details",
    component: SimDetailsView,
  },
  {
    key: "AutomationList",
    path: "/automation",
    exact: true,
    module: MODULES.AUTOMATION_MANAGEMENT,
    permissions: [PERMISSIONS.READ],
    name: "Automation",
    component: AutomationListView,
  },
  {
    key: "customerUsageReport",
    path: "/customer-usage-report",
    exact: true,
    module: MODULES.REPORTS,
    permissions: [PERMISSIONS.READ],
    name: "Customer Usage Report",
    component: CustomerUsageReport,
  },
  {
    key: "customerHighUsageReport",
    path: "/high-usage-customer-report",
    exact: true,
    module: MODULES.REPORTS,
    permissions: [PERMISSIONS.READ],
    name: "Customer high Usage Report",
    component: CustomerUsageReportView,
  },
  {
    key: "providerDataUsageReport",
    path: "/provider-data-usage-report",
    exact: true,
    module: MODULES.REPORTS,
    permissions: [PERMISSIONS.READ],
    name: "Provider data Usage Report",
    component: ProviderDataUsageReport,
  },
  {
    key: "countryWiseDataUsageReport",
    path: "/countrywise-total-consumption-report",
    exact: true,
    module: MODULES.REPORTS,
    permissions: [PERMISSIONS.READ],
    name: "Country wise data Usage Report",
    component: CountryWiseDataUsageReport,
  },
  {
    key: "geographicDataUsageReport",
    path: "/geographic-data-consumption-report",
    exact: true,
    module: MODULES.REPORTS,
    permissions: [PERMISSIONS.READ],
    name: "Geographic data Usage Report",
    component: GeographicDataConsumptionReport,
  },
  {
    key: "dataUsageTrendReport",
    path: "/data-usage-trends-report",
    exact: true,
    module: MODULES.REPORTS,
    permissions: [PERMISSIONS.READ],
    name: "Data Usage Trend Report",
    component: DataUsageTrendReport,
  },
  {
    key: "deviceUsageReport",
    path: "/device-usage-report",
    exact: true,
    module: MODULES.REPORTS,
    permissions: [PERMISSIONS.READ],
    name: "Device Usage Report",
    component: DeviceUsageReport,
  },
  {
    key: "customerProviderReport",
    path: "/customer-provider-preference-report",
    exact: true,
    module: MODULES.REPORTS,
    permissions: [PERMISSIONS.READ],
    name: "Customer provider Usage Report",
    component: CustomerProviderPrefarenceReport,
  },
  {
    key: "costAnalysisreport",
    path: "/cost-analysis-report",
    exact: true,
    module: MODULES.REPORTS,
    permissions: [PERMISSIONS.READ],
    name: "Cost analysis Usage Report",
    component: CostAnalysisReport,
  },
  {
    key: "providerPerformanceReport",
    path: "/provider-performance-report",
    exact: true,
    module: MODULES.REPORTS,
    permissions: [PERMISSIONS.READ],
    name: "Provider performance Report",
    component: HighUsageCustomerReport,
  },
  {
    key: "providerCostAnalysisReport",
    path: "/provider-cost-analysis-report",
    exact: true,
    module: MODULES.REPORTS,
    permissions: [PERMISSIONS.READ],
    name: "Provider performance Report",
    component: ProviderCostAnalysisReport,
  },
  {
    key: "customerDetailReport",
    path: "/customer-detail-report",
    exact: true,
    module: MODULES.REPORTS,
    permissions: [PERMISSIONS.READ],
    name: "Provider performance Report",
    component: CustomerDetailReport,
  },
  {
    key: "customerconsumptionReport",
    path: "/provider-total-consumption-report",
    exact: true,
    module: MODULES.REPORTS,
    permissions: [PERMISSIONS.READ],
    name: "Provider total consumption performance Report",
    component: ProviderTotalConReport,
  },
  {
    key: "GroupDetails",
    path: "/group/:id",
    exact: true,
    module: MODULES.GROUP_MANAGEMENT,
    permissions: [PERMISSIONS.READ_WRITE],
    name: "View group information",
    component: GroupDetails,
  },
];
