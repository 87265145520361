import { FormHelperText, FormControlLabel, Checkbox, SxProps } from "@mui/material";
import { Component, ReactNode } from "react";

interface IProps {
    checked?: boolean;
    label: string;
    name: string;
    onChange?: (event: React.SyntheticEvent, checked: boolean) => void;
    sx?: SxProps;
    value?: string | number | boolean;
    disabled?: boolean;
    error?: boolean;
}
class MuiCheckbox extends Component<IProps> {
    public render(): ReactNode {
        const { label, checked, disabled, error, ...rest } = this.props;

        return (
            <FormControlLabel
                disabled={disabled}
                checked={checked}
                {...rest}
                control={<Checkbox />}
                label={<FormHelperText children={label} error={!!error} />}
            />
        );
    }
}

export default MuiCheckbox;
