import { TextField, TextFieldProps } from '@mui/material';
import { Component, ReactNode } from 'react';

class MuiInputField extends Component<TextFieldProps> {
    public render(): ReactNode {
        return <TextField {...this.props} fullWidth></TextField>;
    }
}

export default MuiInputField;
