import { Http } from "Http";
import BaseService from "./BaseService";
import { ModuleModel, PermissionModel, Response } from "Redux/Models";

export default class ModuleService extends BaseService {

    public static getModules(params: Object = {}): Promise<Response<Array<ModuleModel>>> {
        return new Promise((resolve, reject) => {
            Http.get('/modules', { params }).then((res) => {
                if (res.data.status === 200) {
                    let data = new Response<Array<ModuleModel>>(res?.data);
                    return resolve(data);
                }
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static getPermissions(params: Object = {}): Promise<Response<Array<PermissionModel>>> {
        return new Promise((resolve, reject) => {
            Http.get('/permissions', { params }).then((res) => {
                if (res.data.status === 200) {
                    let data = new Response<Array<PermissionModel>>(res?.data);
                    return resolve(data);
                }
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }
}
