import { convertToMb } from "Helpers/String";
import { BaseStateModel, DataTableModel, GroupModel } from "Redux/Models";
import { GroupService } from "Services";

export class GroupListState extends BaseStateModel {
    public groups: Array<GroupModel> = [];
    public dataTable: DataTableModel = {
        pagination: true,
        searchable: true,
        limit: 10,
        active: 'createdAt',
        direction: 'desc',
        actions: [],
        request: GroupService.getList,
        headers: [
            { key: 'thingsGroupName', label: 'Group Name', isSort: false, style: { width: '20%' } },
            { key: 'thingsGroupDescription', label: 'Group Description', isSort: true, style: { width: '20%' } },
            { key: 'active', label: 'Active', isSort: true, render: (item: GroupModel) => `${item.active}/${item.total}` },
            { key: 'data', label: 'Data Usage', isSort: true, style: { width: '20%' }, render: (item: GroupModel) => convertToMb(Number(item.data)) }
        ],
    };
}