import Configs from "../Configs";

export const GetDate = (date?: string | Date) => {
    if (!date) {
        return '';
    }
    const utcDate = new Date(date);
    return utcDate.toISOString().slice(0, 10);
};

export const GetDateTime = (date?: string | Date) => {
    if (!date) {
        return '';
    }
    const utcDate = new Date(date);
    return utcDate.toISOString().slice(0, 10) + ' ' + utcDate.toLocaleTimeString();
};

export const GetImage = (img?: string): string => {
    if (!img) {
        return '';
    }
    return Configs.API_ROOT + img;
};

export const CountryToFlag = (isoCode: string): string => {
    //return "https://www.countryflags.io/" + isoCode + "/flat/64.png";
    return "https://cdn.ip2location.com/assets/img/flags/" + isoCode + ".png";
    //return typeof String.fromCodePoint !== 'undefined' ? isoCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397)) : isoCode;
}

export const stringAvatar = (name: string) => {
    const splitName = name.split(' ');
    return {
        children: `${splitName.length > 0 ? splitName[0][0] : ''}${splitName.length > 1 ? splitName[1][0] : ''}`,
    };
}

export const convertToMb = (usage: number) => {
    if (usage === null || usage === undefined) return;
    const n = usage.valueOf();
    const result = (n / 1048576).toFixed(2)
    return `${result} MB`;
}

export const formatMbCount = (usage: number) => {
    if (usage === null || usage === undefined) return;

    return usage;
}

export const getNetworkNames = (networks: any) => {
    if (!networks) return null;
    return networks.map((network: any) => network.NetworkName).join(", ");
}
