import { combineReducers } from 'redux';
import Auth from './Auth.Reducer';
import Config from './Config.Reducer';
import Ui from './Ui.Reducer';

export default combineReducers({
    Auth,
    Config,
    Ui
});
