import React from 'react';
import './App.css';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { AuthUserModel, Props, StateModel } from 'Redux/Models';
import { connect } from 'react-redux';
import { AuthLayout, DashboardLayout } from 'Layouts';
import { styles } from 'global-styles';
import { withStyles } from '@mui/styles';
import { ThemeProvider } from '@mui/material';
import { Theme } from 'Theme';
import { Socket, io } from 'socket.io-client';
import { DefaultEventsMap } from '@socket.io/component-emitter';
import Configs from './Configs';

const App = (props: Props) => {
  const { isAuthenticated } = props;
  let socket: Socket<DefaultEventsMap, DefaultEventsMap> = io(Configs.SOCKET_URL);

  return (
    <ThemeProvider theme={Theme}>
      <div>
        <Router>
          <Switch>
            {!isAuthenticated && <Route path='/' render={(rProps: any) => <AuthLayout {...rProps} {...props} />} />}
            {isAuthenticated && <Route path='/' render={(rProps: any) => <DashboardLayout {...rProps} {...props} socket={socket} />} />}
          </Switch>
        </Router>
      </div>
    </ThemeProvider>
  );
}

const mapStateToProps = (state: StateModel) => {
  return {
    isAuthenticated: state.Auth.isAuthenticated,
    user: new AuthUserModel(state.Auth.user),
    token: state.Auth.token,
    roles: state.Config.roles,
    isDark: state.Ui.isDark,
    isMobileOpen: state.Ui.isMobileOpen
  }
}

const mappedApp = connect(mapStateToProps)(App);

export default withStyles(styles)(mappedApp);
