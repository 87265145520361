import { styled as MuiStyle } from '@mui/material';
import { MuiBox } from 'Components/MUI';

export const RootContainer = MuiStyle(MuiBox)(({ theme }) => ({
    backgroundColor: '#EFECEC !important',
    '& .datatableMainWrap': {
        overflowX: 'auto',
        fontFamily: "'Be Vietnam Pro', sans-serif",
        '& th, td': {
            fontFamily: "'Be Vietnam Pro', sans-serif",
        },
        '& th': {
            backgroundColor: '#EFECEC !important',
            borderBottom: 'none',
            fontSize: 16,
            '&:first-of-type': {
                borderRadius: '8px 0 0 8px',
            },
            '&:last-child': {
                borderRadius: '0 8px 8px 0',
            },
            '& span': {
                color: theme.palette.secondary.dark,
                fontWeight: 600,
                '&:hover': {
                    // color: theme.palette.secondary.dark,
                }
            },
            '& b': {
                fontWeight: 600,
            }
        },
        '& td': {
            borderBottom: 'none',
            paddingTop: '10px',
            paddingBottom: '10px',
            '&:hover': {
                // backgroundColor: 'transparent !important',
            },
            '&:first-of-type': {
                borderRadius: '8px 0 0 8px',
            },
            '&:last-child': {
                borderRadius: '0 8px 8px 0',
            },
        },
        '& tr': {
            '&:nth-of-type(even)': {

                backgroundColor: '#EFECEC !important',

                '&:hover': {
                    // backgroundColor: '#fff !important',
                },
            },
            '&:nth-of-type(odd)': {
                backgroundColor: '#fff !important',
                '&:hover': {
                    // backgroundColor: '#EFECEC !important',
                },
            },
            '&:hover': {
                // backgroundColor: 'transparent !important',
            },
        },
        '& .tableActionsWrap': {
            '& .MuiIconButton-root': {
                marginRight: 5,
                color: theme.palette.primary.light,
                padding: 6,
                background: 'none',

                '&:hover': {
                    background: theme.palette.primary.dark,
                    color: theme.palette.primary.contrastText
                },
                '& svg': {
                    fontSize: 18,
                }
            }
        },

    },
    '& .MuiTablePagination-root': {
        '& .MuiToolbar-root': {
            [theme.breakpoints.down("sm")]: {
                flexWrap: 'wrap'
            }
        },
        '& p': {
            fontFamily: "'Be Vietnam Pro', sans-serif",
            fontSize: 13,
            [theme.breakpoints.up("xl")]: {
                fontSize: 16,
            }
        },
        '& .MuiInputBase-root': {
            marginRight: 10,
            marginLeft: 8,
            '& .MuiSelect-select': {
                backgroundColor: '#f7f7f7',
                borderRadius: '4px',
                padding: '3px 5px',
                paddingRight: '18px',
                border: '1px solid #efefef',
                fontSize: 13,
                [theme.breakpoints.up("xl")]: {
                    fontSize: 16,
                }
            },
            '& .MuiSvgIcon-root': {
                fontSize: 17,
                [theme.breakpoints.up("xl")]: {
                    fontSize: 20,
                }
            }
        },
        '& .MuiTablePagination-actions': {
            marginLeft: '10px',
            '& button': {
                backgroundColor: theme.palette.primary.main,
                padding: 2,
                '&.Mui-disabled': {
                    opacity: '0.8',
                },
                '&:last-child': {
                    marginLeft: '8px',
                }
            }
        }
    }
}));