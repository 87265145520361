import { MODULES } from "./Constants";
import { LightModeIcon, LogoutIcon, NotificationIcon, OrganizationIcon, SettingIcon, SimIcon, UsersIcon, WalletIcon } from 'Helpers/Icons';
import AssessmentIcon from '@mui/icons-material/Assessment';

export class Menu {
    public path: string;
    public icon!: any;
    public title!: string;
    public modules!: string[];
    public activeClass!: string;
    public key!: string;
    public submenus?: Menu[];
}

export class BasicMenu {
    public icon!: any;
    public title!: string;
    public key!: string;
}

export const MENUS: Array<Menu> = [
    { key: 'users', path: '/users', icon: UsersIcon, title: 'Users', modules: [MODULES.USER_MANAGEMENT, MODULES.GROUP_MANAGEMENT, MODULES.ROLE_MANAGEMENT], activeClass: 'active' },
    { key: 'prices', path: '/prices', icon: WalletIcon, title: 'Price', modules: [MODULES.PRICING_MANAGEMENT], activeClass: 'active' },
    { key: 'sims', path: '/sims', icon: SimIcon, title: 'Sims', modules: [MODULES.SIMS_MANAGEMENT], activeClass: 'active' },
    { key: 'companies', path: '/companies', icon: OrganizationIcon, title: 'Companies', modules: [MODULES.COMPANY_MANAGEMENT], activeClass: 'active' },
    { key: 'automation', path: '/automation', icon: SettingIcon, title: 'Automation', modules: [MODULES.AUTOMATION_MANAGEMENT], activeClass: 'active' },
    { key: 'reports', path: '/customer-usage-report', icon: AssessmentIcon, title: 'Reports', modules: [MODULES.REPORTS], activeClass: 'active', submenus: [
        {key: 'reports', path: '/customer-usage-report', icon: '', title: 'Customer Usage Report', modules: [MODULES.CUSTOMER_USAGE_REPORT], activeClass: 'activeLink' },
        {key: 'reports', path: '/provider-data-usage-report', icon: '', title: 'Provider Data Usage Report', modules: [MODULES.PROVIDER_DATA_USAGE_REPORT], activeClass: 'activeLink' },
        {key: 'reports', path: '/geographic-data-consumption-report', icon: '', title: 'Geographic Data Consumption Report', modules: [MODULES.GEOGRAPHIC_DATA_CONSUMPTION_REPORT], activeClass: 'activeLink' },
        {key: 'reports', path: '/data-usage-trends-report', icon: '', title: 'Data Usage Trend Report', modules: [MODULES.DATA_USAGE_TREND_REPORT], activeClass: 'activeLink' },
        {key: 'reports', path: '/device-usage-report', icon: '', title: 'Device Usage Report', modules: [MODULES.DEVICE_USAGE_REPORT], activeClass: 'activeLink' },
        {key: 'reports', path: '/customer-provider-preference-report', icon: '', title: 'Customer Provider Preference Report', modules: [MODULES.CUSTOMER_PROVIDER_PREFERENCE_REPORT], activeClass: 'activeLink' },
        {key: 'reports', path: '/cost-analysis-report', icon: '', title: 'Cost Analysis Report', modules: [MODULES.COST_ANALYSIS_REPORT], activeClass: 'activeLink' },
        {key: 'reports', path: '/high-usage-customer-report', icon: '', title: 'High Usage Customer Report', modules: [MODULES.HIGH_USAGE_CUSTOMER_REPORT], activeClass: 'activeLink' },
        {key: 'reports', path: '/provider-performance-report', icon: '', title: 'Provider Performance Report', modules: [MODULES.PROVIDER_PERFORMANCE_REPORT], activeClass: 'activeLink' },
        {key: 'reports', path: '/provider-cost-analysis-report', icon: '', title: 'Provider Cost Analysis Report', modules: [MODULES.PROVIDER_COST_ANALYSIS_REPORT], activeClass: 'activeLink' },
        { key: 'reports', path: '/customer-detail-report', icon: '', title: 'Customer Detail Report', modules: [MODULES.CUSTOMER_DETAIL_REPORT], activeClass: 'activeLink' },
        {key: 'reports', path: '/countrywise-total-consumption-report', icon: '', title: 'Countrywise Total Consumption Report', modules: [MODULES.COUNTRYWISE_TOTAL_CONSUMPTION_REPORT], activeClass: 'activeLink' },
        {key: 'reports', path: '/provider-total-consumption-report', icon: '', title: 'Provider Total Consumption Report', modules: [MODULES.PROVIDER_TOTAL_CONSUMPTION_REPORT], activeClass: 'activeLink' }
    ] },

];

export const BOTTOM_MENUS: Array<BasicMenu> = [
    { key: 'notifications', icon: NotificationIcon, title: 'Notifications' },
    { key: 'account', icon: SettingIcon, title: 'Account' },
    { key: 'mode', icon: LightModeIcon, title: 'Mode' },
    { key: 'logout', icon: LogoutIcon, title: 'Logout' },
]