import { Http } from 'Http';
import BaseService from './BaseService';
import { GroupModel, Options, Response } from 'Redux/Models';

export default class GroupService extends BaseService {
    public static getList(params: Object = {}, options: Options = {}): Promise<Response<GroupModel>> {
        let url: string = '/groups';
        if (options?.root) {
            url = options.root + url;
        }
        GroupService.initCancelToken();
        if (options?.source) {
            GroupService.source = options?.source;
        }
        return new Promise((resolve, reject) => {
            Http.get(url, { params, cancelToken: GroupService.source?.token })
                .then((res) => {
                    let data = new Response<GroupModel>(res.data);
                    return resolve(data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }

    public static groupSummary(params: Object = {}): Promise<Response<GroupModel>> {
        GroupService.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.get('/group-summary', params).then(resp => {
                let data = new Response<GroupModel>(resp.data);
                return resolve(data);
            }).catch(err => {
                return reject(err)
            })
        })
    }

}
