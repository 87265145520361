import { styled } from "@mui/material/styles";

export const StyledAccountTab = styled("div")(({ theme }) => ({
  paddingTop: "3.2rem",
  paddingLeft: "2rem",
  paddingRight: "2rem",
  height: "100%",
  position: "fixed",
  top: 0,
  left: "270px",
  width: "500px",
  zIndex: 999,
  backgroundColor: theme.palette.secondary.light,
  transition: "all 0.7s",
  overflowY: "auto",

  "& .active": {
    transition: "all 0.7s",
  },

  ".heading": {
    marginBottom: "1rem",

    ".page-title": {
      position: "relative",
      display: "inline-block",
      "& span": {
        position: "relative",
        zIndex: "9",
        fontSize: "48px",
        fontWeight: 700,
        color: theme.palette.primary.dark,
      },
      "&::after": {
        content: "''",
        position: "absolute",
        left: 0,
        bottom: "11px",
        width: "100%",
        height: "4px",
        backgroundColor: theme.palette.primary.light,
        borderRadius: "20px",
      },
    },
  },
  ".content": {
    ".account-img": {
      gap: "3rem",
      justifyContent: "flex-start",

      ".acc-img": {
        height: "80px",
        width: "80px",
      },
      ".change-text": {
        fontWeight: 300,
        // color: theme.palette.primary.dark,
        fontSize: "14px",
      },
    },
    ".acc-details": {
      marginTop: "2rem",

      ".title": {
        position: "relative",
        display: "inline-block",
        "& span": {
          position: "relative",
          zIndex: "9",
          fontSize: "22px",
          fontWeight: 600,
          color: theme.palette.primary.dark,
        },
        "&::after": {
          content: "''",
          position: "absolute",
          left: 0,
          bottom: "1px",
          width: "40%",
          height: "4px",
          backgroundColor: theme.palette.primary.light,
          borderRadius: "20px",
        },
      },
      ".details": {
        marginTop: "1.5rem",

        ".input_box": {
          display: "flex",
          alignItems: "center",

          ".MuiFormControl-root, .MuiAutocomplete-root": {
            marginBottom: 0,
            maxWidth: 200,

            input: {
              height: "24px",
            },
          },
          svg: {
            height: "18px",
            width: "18px",
          },
        },

        ".row": {
          gap: "1rem",
          justifyContent: "flex-start",
          marginBottom: ".5rem",

          ".first": {
            fontSize: "14px",
            fontWeight: 600,
            color: theme.palette.primary.dark,
            flex: "1",
          },
          ".second": {
            fontSize: "14px",
            fontWeight: 300,
            color: theme.palette.primary.dark,
            flex: "1",

            "& .MuiInputBase-input": {
              backgroundColor: theme.palette.secondary.light,
              fontSize: "14px",
            },

            ".edit-icon": {
              backgroundColor: theme.palette.secondary.light,
            },
          },
          ".email-icon": {
            svg: {
              width: "-3px",
              height: "1px",
            },
          },
        },
      },
    },
    ".security-details": {
      marginTop: "2rem",
      ".title": {
        position: "relative",
        display: "inline-block",
        "& span": {
          position: "relative",
          zIndex: "9",
          fontSize: "22px",
          fontWeight: 600,
          color: theme.palette.primary.dark,
        },
        "&::after": {
          content: "''",
          position: "absolute",
          left: 0,
          bottom: "1px",
          width: "75%",
          height: "4px",
          backgroundColor: theme.palette.primary.light,
          borderRadius: "20px",
        },
      },
      ".first-row": {
        display: "flex",
        gap: "4rem",
        marginTop: "1rem",

        ".icon": {
          "& .MuiSvgIcon-root": {
            color: theme.palette.secondary.main,
          },
        },
      },
      ".second-row": {
        marginTop: "1rem",
        display: "flex",
        gap: "3rem",

        ".btn": {
          padding: "5px 35px",
          fontSize: "14px",
          borderRadius: "10px",
          backgroundColor: theme.palette.secondary.light,
          color: theme.palette.primary.light,
          border: `1px solid ${theme.palette.primary.light}`,
        },
      },
    },
  },
}));
