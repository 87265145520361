import { Component, ReactNode } from 'react';
import { MuiBox } from 'Components/MUI';
import { RootContainer } from './styled';

export default class Loader extends Component {

    public render(): ReactNode {
        return (
            <RootContainer>
                <MuiBox className='loader'></MuiBox>
            </RootContainer>
        );
    }
}
