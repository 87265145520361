
import { DataTableModel, CompanyModel } from "../Models";
import { CustomerDetailReportModel } from "../Models/CustomerDetailReport.Models";

export class CustomerDetailReportState {
    public dataUsageReports: Array<CustomerDetailReportModel> = [];
    public companies: Array<CompanyModel> = [];
    public dataTable: DataTableModel = {
        pagination: true,
        limit: 10,
        active: 'createdAt',
        direction: 'desc',
        // actions: [
        //     { key: 'view', icon: VisibilityIcon },
        // ],
        request: () => { },
        //  <CUSTOMERID> and <CUSTOMERNAME>
        headers: [
            { key: 'CUSTOMERID', label: 'CUSTOMERID', isSort: true },
            { key: 'CUSTOMERNAME', label: 'CUSTOMERNAME', isSort: true }
        ]
    }
}
