const isDev: boolean = process.env.NODE_ENV === 'development';

const localUrl = 'http://localhost:5200';
const liveUrl = 'https://uatondigoapi.intellexio.com';

export default class Configs {
    public static API_ROOT: string = isDev ? localUrl : liveUrl;
    public static API_URL: string = isDev ? `${localUrl}/api` : `${liveUrl}/api`
    public static SOCKET_URL: string = 'https://uatondigoweb.intellexio.com/';
}

