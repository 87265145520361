import { Http } from "Http";
import BaseService from "./BaseService";
import { Response, UserModel } from "Redux/Models";

export default class AuthService extends BaseService {

    public static login(params: Object): Promise<Response<UserModel>> {
        return new Promise((resolve, reject) => {
            Http.post('/auth/login', params)
                .then((res) => {
                    if (res?.data.status === 200) {
                        return resolve(new Response(res?.data));
                    }
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }

    public static getMe(): Promise<Response<UserModel>> {
        return new Promise((resolve, reject) => {
            Http.get('/auth/me').then((res) => {
                if (res?.data.status === 200) {
                    return resolve(new Response(res?.data));
                }
            })
                .catch((err) => {
                    return reject(err);
                });
        });
    }

    public static requestPassword(params: Object): Promise<Response<UserModel>> {
        return new Promise((resolve, reject) => {
            Http.post('/auth/forgot-password', params).then((res) => {
                if (res?.data.status === 200) {
                    return resolve(new Response(res.data));
                }
            }).catch((err) => {
                return reject(err);
            })
        })
    }

    public static resetPassword(params: Object): Promise<Response<null>> {
        return new Promise((resolve, reject) => {
            Http.post('/auth/reset-password', params).then((res) => {
                if (res?.data.status === 200) {
                    return resolve(new Response(res.data));
                }
            }).catch((err) => {
                return reject(err);
            })
        })
    }

    public static logout(): Promise<Response<null>> {
        return new Promise((resolve, reject) => {
            Http.post('/auth/logout').then((res) => {
                if (res?.data.status === 200) {
                    return resolve(new Response(res.data));
                }
            }).catch((err) => {
                return reject(err);
            })
        })
    }

    public static updatePassword(params: Object): Promise<Response<null>> {
        return new Promise((resolve, reject) => {
            Http.put('/auth/update-password', params).then(res => {
                let data = new Response<null>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static getMFASecret(): Promise<Response<any>> {
        return new Promise((resolve, reject) => {
            Http.post('/auth/mfa/secret').then(res => {
                let data = new Response<UserModel>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static enableMFA(params: Object): Promise<Response<any>> {
        return new Promise((resolve, reject) => {
            Http.post('/auth/mfa/enable', params).then(res => {
                let data = new Response<UserModel>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static updateProfile(params: Object): Promise<Response<UserModel>> {
        return new Promise((resolve, reject) => {
            Http.put('/auth/update-profile', params).then(res => {
                let data = new Response<UserModel>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static uploadImage(params: FormData): Promise<Response<UserModel>> {
        return new Promise((resolve, reject) => {
            Http.post('/auth/profile-image', params).then(res => {
                let data = new Response<UserModel>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    console.error(err);
                    return reject(err);
                });
        });
    }

}