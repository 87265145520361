import { GetDateTime, convertToMb } from "Helpers/String";
import { BaseStateModel, BranchModel, CompanyModel, DataTableModel, NetworkModel, PriceModel, UsageModel, UserModel } from "Redux/Models";
import { CompanyService, EmployeeService } from "Services";
import BranchService from "Services/BranchService";

export class CompanyDetailsState extends BaseStateModel {
    public tabValue: string = "1"
}

export class CompanyListState extends BaseStateModel {
    public dataTable: DataTableModel = {
        pagination: true,
        searchable: true,
        limit: 10,
        active: 'createdAt',
        direction: 'desc',
        actions: [],
        request: CompanyService.getList,
        headers: [
            { key: 'company.name', label: 'Name', isSort: true },
            { key: 'email', label: 'Email', isSort: true },
            { key: 'phoneNumber', label: 'Phone number', isSort: true },
        ],
    };
}


export class CompanyEmployeeState extends BaseStateModel {
    public dataTable: DataTableModel = {
        pagination: true,
        searchable: true,
        limit: 10,
        active: 'createdAt',
        direction: 'desc',
        actions: [],
        request: EmployeeService.getList,
        headers: [
            { key: 'firstName', label: 'Name', isSort: true, render: (item: UserModel) => `${item.firstName} ${item.lastName}` },
            { key: 'email', label: 'Email', isSort: true },
            { key: 'phoneNumber', label: 'Phone number', isSort: true },
            { key: 'role.name', label: 'Role', isSort: false },
        ],
    };
}


export class CompanyBranchesState extends BaseStateModel {
    public isBranchCreateModalOpen: boolean = false;
    public dataTable: DataTableModel = {
        pagination: true,
        searchable: true,
        limit: 10,
        active: 'createdAt',
        direction: 'desc',
        actions: [],
        request: BranchService.getBranchsListByCompany,
        headers: [
            { key: 'name', label: 'Name', isSort: true },
            { key: 'postalCode', label: 'Postal code', isSort: false },
            { key: 'deliveryAddress', label: 'Delivery address', isSort: true },
            { key: 'country', label: 'Country', isSort: true },
            { key: 'city', label: 'City', isSort: true },
            { key: 'address', label: 'Address', isSort: true },
        ],
    };
}

export class CompanyBranchCreateState extends BaseStateModel {
    public branch: BranchModel = {
        name: '',
        postalCode: '',
        deliveryAddress: '',
        address: '',
        city: '',
        country: '',
    }
}

export class CompanyUsageState extends BaseStateModel {
    public dataTable: DataTableModel = {
        pagination: true,
        searchable: true,
        limit: 10,
        active: 'createdAt',
        direction: 'desc',
        actions: [],
        request: CompanyService.getUsageListByCompany,
        headers: [
            { key: 'IMSI', label: 'IMSI', isSort: true, render: (item: UsageModel) => `${item.IMSI}` },
            { key: 'cdrType', label: 'Type', isSort: true },
            { key: 'thingName', label: 'Thing Name', isSort: true },
            { key: 'usage', label: 'Usage', isSort: false, render: (item: UsageModel) => convertToMb(Number(item.usage)) },
            { key: 'timestamp', label: 'Time', isSort: false, render: (item: UsageModel) => item.timestamp && GetDateTime(item.timestamp) },
        ],
    };
}



export class CompanyInvoicesState extends BaseStateModel {
    public dataTable: DataTableModel = {
        pagination: true,
        searchable: true,
        limit: 10,
        active: 'createdAt',
        direction: 'desc',
        actions: [],
        request: CompanyService.getInvoicesListByCompany,
        headers: [
            { key: 'email', label: 'Email', isSort: true },
            { key: 'amount', label: 'Amount', isSort: true },
            { key: 'currency', label: 'Currency', isSort: true },
            { key: 'gatewayCharge', label: 'Gateway charge', isSort: false },
            { key: 'mode', label: 'Mode', isSort: false },
            { key: 'total_amount', label: 'Total amount', isSort: true },
            { key: 'vat', label: 'Vat', isSort: true },
            { key: 'status', label: 'Status', isSort: true },
        ],
    };
}


export class CompanyInfoState extends BaseStateModel {
    public company: CompanyModel = {};
    public admin: UserModel = {};
}

export class CompanyCreateState extends BaseStateModel {
    public networks: NetworkModel[] = [];
    public prices: PriceModel[] = [];
    public selectedHqCountry: any = {};
    public selectedAdminCountry: any = {};
    public company: CompanyModel = {
        administrativeEmail: '',
        administrativeFirstName: '',
        administrativeLastName: '',
        administrativePhoneNumber: '',
        administrativeCountry: '',
        hqAddress: '',
        hqCity: '',
        hqCountry: '',
        hqDeliveryAddress: '',
        hqPostalCode: '',
        invoicingAddress: '',
        invoicingEmail: '',
        invoicingName: '',
        invoicingPhone: '',
        name: '',
        VAT: '',
        network: '',
        selectprice: ''
    }
}