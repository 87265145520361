import { Http } from "Http";
import { AuthActionTypes, AuthActions } from "Redux/Actions";
import { InitialAuthStateModel } from "Redux/Models";

const initialState: InitialAuthStateModel = {
    token: '',
    isAuthenticated: false,
    user: {},
    isExpired: false
}

const Auth = (state = initialState, actions: AuthActions) => {
    const { type, payload } = actions;
    switch (type) {
        case AuthActionTypes.Login: {
            Http.defaults.headers.common['Authorization'] = `Bearer ${payload.token}`;
            return {
                ...state,
                ...payload,
                isAuthenticated: true,
                isExpired: state.isExpired
            };
        }
        case AuthActionTypes.Logout:
            return {
                ...state,
                ...initialState
            };
        case AuthActionTypes.LoadUser: {
            const user = Object.assign(state.user, payload);
            return {
                ...state,
                user: user
            };
        }
        case AuthActionTypes.CheckAuth:
            if (state.isAuthenticated) {
                Http.defaults.headers.common['Authorization'] = `Bearer ${state.token}`;
            }
            return state;
        default:
            return state;
    }
};

export default Auth;