import { MuiBox } from 'Components/MUI'
import React, { Component } from 'react'

export class Fallback extends Component<any, any> {
    render() {
        return (
            <MuiBox className="loader">
                <p>Loading....</p>
            </MuiBox>
        )
    }
}

export default Fallback
