import { Http } from 'Http';
import BaseService from './BaseService';
import { UserModel, Options, Response, ResponseList } from 'Redux/Models';

export default class EmployeeService extends BaseService {
    public static getList(params: Object = {}, options: Options = {}): Promise<Response<UserModel>> {
        let url: string = '/employees';
        if (options?.root) {
            url = options.root + url;
        }
        EmployeeService.initCancelToken();
        if (options?.source) {
            EmployeeService.source = options?.source;
        }
        return new Promise((resolve, reject) => {
            Http.get(url, { params, cancelToken: EmployeeService.source?.token })
                .then((res) => {
                    let data = new Response<UserModel>(res.data);
                    return resolve(data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }

    public static createEmployee(params: Object = {}, options: Options = {}): Promise<Response<UserModel>> {
        this.initCancelToken();
        let url: string = '/employees';
        if (options?.root) {
            url = options.root + url;
        }
        return new Promise((resolve, reject) => {
            Http.post(url, params, { cancelToken: this.source?.token })
                .then(res => {
                    let data = new Response<UserModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static updateEmployee(id: string, params: Object = {}, options: Options = {}): Promise<Response<UserModel>> {
        this.initCancelToken();
        let url: string = '/employees/' + id;
        if (options?.root) {
            url = options.root + url;
        }
        return new Promise((resolve, reject) => {
            Http.patch(url, params, { cancelToken: this.source?.token })
                .then(res => {
                    let data = new Response<UserModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static deleteEmployee(id: string, params: Object = {}, options: Options = {}): Promise<Response<null>> {
        this.initCancelToken();
        let url: string = '/employees/' + id;
        if (options?.root) {
            url = options.root + url;
        }
        return new Promise((resolve, reject) => {
            Http.delete(url, { params, cancelToken: this.source?.token })
                .then(res => {
                    let data = new Response<null>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static getById(id: String, params = {}, options: Options = {}): Promise<Response<UserModel>> {
        this.initCancelToken();
        let url: string = '/employees/' + id;
        if (options?.root) {
            url = options.root + url;
        }
        return new Promise((resolve, reject) => {
            Http.get(url, { params, cancelToken: this.source?.token }).then(res => {
                let data = new Response<UserModel>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static updateBlockStatus(id: string, params: Object = {}, options: Options = {}): Promise<Response<UserModel>> {
        this.initCancelToken();
        let url: string = `/employees/${id}/status`
        if (options?.root) {
            url = options.root + url;
        }
        return new Promise((resolve, reject) => {
            Http.patch(url, params, { cancelToken: this.source?.token })
                .then(res => {
                    let data = new Response<UserModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static getEmployeeListByCompany(id: string, params: Object = {}, options: Options = {}): Promise<ResponseList<UserModel>> {
        EmployeeService.initCancelToken();

        return new Promise((resolve, reject) => {
            Http.get('/companies/' + id + '/employees', { params, cancelToken: EmployeeService.source?.token }).then(resp => {
                let data = new ResponseList<UserModel>(resp.data);
                return resolve(data);
            }).catch(err => {
                return reject(err)
            })
        })
    }

}


