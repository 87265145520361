import { DialogProps } from "@mui/material";
import {
    MuiBox,
    MuiButton,
    MuiDialog,
    MuiDialogActions,
    MuiDialogContent,
    MuiDialogTitle,
    MuiIconButton,
    MuiTablePagination,
    MuiTypography,
} from "Components/MUI";
import { Map } from "Helpers/Array";
import { CloseIcon } from "Helpers/Icons";
import { Props, SimModel, States } from "Redux/Models";
import { ChangeEvent, Component } from "react";
import { StyledCreateModal } from "./style";

interface SimSummaryDialogProps extends DialogProps {
    items: Array<SimModel>;
}

const headers = [
    { key: "thingName", label: "Thing Name" },
    { key: "dataUsage", label: "Data Usage(MB)" },
];

class SimSummaryModal extends Component<Props> {
    constructor(props: Props) {
        super(props);
        this.state = {
            currentPage: 1,
            limit: 10,
        };
    }

    public handleChangePage = (event: any, value: any) => {
        this.setState((prevState: States) => ({
            currentPage: value + 1,
        }));
    };

    public handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        const limit = parseInt(event.target.value, 10);
        this.setState((prevState: States) => ({
            currentPage: 1,
            limit: limit,
        }));
    };

    public handleRedirectSimDetails = (simId: string) => {
        this.props.history.push(`/sims/${simId}`);
      }

    render() {
        const { open, onClose, title, items, ...restProps } = this.props;
        const { currentPage, limit }: States = this.state;
        const indexOfLastItem = currentPage * limit;
        const indexOfFirstItem = indexOfLastItem - limit;
        const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);
        return (
            <MuiDialog open={open} onClose={onClose} {...restProps}>
                <StyledCreateModal>
                    <MuiBox
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        className="heading"
                    >
                        <MuiDialogTitle className="title">{title}</MuiDialogTitle>
                        {onClose && (
                            <MuiIconButton onClick={() => onClose({}, "backdropClick")}>
                                <CloseIcon />
                            </MuiIconButton>
                        )}
                    </MuiBox>
                    <MuiDialogContent className="sim-table-content">
                        <MuiBox className="list-content">
                            <MuiBox className="list-title">
                                {Map(headers, (head, headIndex) => (
                                    <MuiTypography key={headIndex} className="list-title-text">
                                        {head.label}
                                    </MuiTypography>
                                ))}
                            </MuiBox>
                            {currentItems && currentItems.length > 0 ? (
                                Map(currentItems, (simSummry: any, simSummryIndex) => (
                                    <MuiBox key={simSummryIndex} className="list-content-result">
                                        {Map(headers, (headKey, headI) => (
                                            <MuiTypography key={headI} className="list-main-text">
                                                {/* {simSummry[headKey.key]} */}
                                                {simSummry ? <a onClick= {() => this.handleRedirectSimDetails(simSummry.id)} style={{ cursor: 'pointer' }} className="clickable-link">{simSummry[headKey.key]}</a> : ''}
                                            </MuiTypography>
                                        ))}
                                    </MuiBox>
                                ))
                            ) : (
                                <MuiBox py={3}>
                                    <MuiTypography textAlign="center">
                                        No summary found
                                    </MuiTypography>
                                </MuiBox>
                            )}
                        </MuiBox>
                        {currentItems && (
                            <MuiTablePagination
                                className="sim-table"
                                sx={{
                                    "& .MuiSelect-select": {
                                        fontSize: 16,
                                    },
                                }}
                                count={items.length}
                                page={currentPage - 1}
                                onPageChange={this.handleChangePage}
                                component="div"
                                rowsPerPage={limit}
                                rowsPerPageOptions={[10, 25, 100]}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                            />
                        )}
                    </MuiDialogContent>
                    <MuiDialogActions className="button">
                        <MuiBox className="submit-btn">
                            {onClose && (
                                <MuiButton
                                    variant="outlined"
                                    className="btn cancel-btn"
                                    onClick={() => onClose({}, "backdropClick")}
                                >
                                    Cancel
                                </MuiButton>
                            )}
                        </MuiBox>
                    </MuiDialogActions>
                </StyledCreateModal>
            </MuiDialog>
        );
    }
}

export default SimSummaryModal;
