import { MuiBox, MuiListItem, MuiListItemIcon, MuiListItemText } from 'Components/MUI'
import { BOTTOM_MENUS, BasicMenu, MENUS, Menu } from 'Configs/Menus'
import { Map } from 'Helpers/Array'
import { LoadUser, Logout, ToggleMode } from 'Redux/Actions'
import { Props, States } from 'Redux/Models'
import { AuthService } from 'Services'
import React, { Component, Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import Logo from '../../Assets/logos/logo.png'
import { PERMISSIONS } from 'Configs/Constants'
import { DashboardState } from 'Redux/States'
import NotificationTab from 'Components/Notification/NotificationTab'
import AccountTab from 'Components/Account/AccountTab'
import { ChangePasswordModal, MfaVerifyModal } from 'Components/Modals'
import { FormikHelpers } from 'formik'
import { DashboardIcon } from 'Helpers/CustomIcons'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export class Sidebar extends Component<Props, DashboardState> {
    public state: DashboardState = {
        ...new DashboardState(),
        openMenus: {} as { [key: number]: boolean },
    };

    public handleMenuToggle = (menuIndx: number) => {
        this.setState(prevState => ({
            openMenus: {
                ...prevState.openMenus,
                [menuIndx]: !prevState.openMenus[menuIndx],
            },
        }));
    };

    public renderMenus = (menuArray: Array<Menu>) => {
        const { user } = this.props;
        return menuArray.map((menu: Menu, menuIndx: number) => {
            const hasMenuPermission = menu.modules.some(module =>
                user.hasPermission(module, PERMISSIONS.READ)
            );

            if (!hasMenuPermission) return null;

            const MenuIcon = menu.icon;

            const visibleSubmenus = menu.submenus?.filter(submenu =>
                submenu.modules.some(module =>
                    user.hasPermission(module, PERMISSIONS.READ, PERMISSIONS.READ_WRITE)
                )
            ) || [];

            return (
                <Fragment key={menuIndx}>
                    <MuiListItem onClick={() => this.handleMenuToggle(menuIndx)}>
                        <NavLink activeClassName={menu.activeClass} to={menu.path}>
                            <MuiListItemIcon>{MenuIcon && <MenuIcon />}</MuiListItemIcon>
                            <MuiListItemText primary={menu.title} />
                        </NavLink>
                    </MuiListItem>
                    {visibleSubmenus.length > 0 && this.state.openMenus[menuIndx] && (
                        <ul className="submenu">
                            {visibleSubmenus.map((submenu, submenuIndx) => {

                                return (
                                    <li key={submenuIndx}>
                                        <NavLink className='submenuLink' activeClassName={menu.activeClass} to={submenu.path}>
                                            <MuiListItemIcon><FiberManualRecordIcon /></MuiListItemIcon>
                                            <MuiListItemText primary={submenu.title} />
                                        </NavLink>
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </Fragment>
            );
        });
    };

    public handleLogout = () => {
        const { dispatch } = this.props;
        AuthService.logout().then((resp) => {
            if (resp.isSuccess()) {
                dispatch(Logout());
            }
        }).catch((err) => {
            return err;
        })
    }

    public handleNotifications = () => {
        this.setState((prevState: DashboardState) => ({
            isNotificationBarOpen: !prevState.isNotificationBarOpen,
            isAccountBarOpen: false
        }))
    }

    public handleAccount = () => {
        this.setState((prevState: DashboardState) => ({
            isAccountBarOpen: !prevState.isAccountBarOpen,
            isNotificationBarOpen: false
        }))
    }

    public handleThemeMode = () => {
        const { dispatch, isDark } = this.props;
        dispatch(ToggleMode(!isDark));
    }

    public handleMenuClick = (bottomMenu: BasicMenu) => {
        switch (bottomMenu.key) {
            case 'logout':
                this.handleLogout();
                return;
            case 'notifications':
                this.handleNotifications();
                return;
            case 'account':
                this.handleAccount();
                return;
            case "mode":
                this.handleThemeMode();
                return;
            default:
                return;
        }
    }

    public handlePasswordModal = () => {
        this.setState((prevState: DashboardState) => ({
            isAccountBarOpen: false,
            isChangePasswordModalOpen: !prevState.isChangePasswordModalOpen
        }))
    }

    public handlePasswordFormSubmit = (data: States, actions: FormikHelpers<States>) => {
        AuthService.updatePassword(data).then((resp) => {
            if (resp.isSuccess()) {
                this.handlePasswordModal();
            }
        }).catch((err) => {
            actions.setSubmitting(false)
            return err;
        })
    }

    public handleMfaVerifyModal = () => {
        this.setState((prevState: DashboardState) => ({
            isMfaVerifyModalOpen: !prevState.isMfaVerifyModalOpen,
            isAccountBarOpen: false,
        }))
    }

    public handleVerifyCode = (values: States, actions: FormikHelpers<States>) => {
        const { dispatch } = this.props;
        actions.setSubmitting(true);
        AuthService.enableMFA({ code: values.code }).then((resp) => {
            if (resp.isSuccess()) {
                dispatch(LoadUser(resp.data));
                this.handleMfaVerifyModal();
            }
        }).catch((err) => {
            actions.setSubmitting(false);
            return err;
        })
    }

    render() {
        const { isNotificationBarOpen, isAccountBarOpen, isChangePasswordModalOpen, isMfaVerifyModalOpen } = this.state;
        return (
            <MuiBox className='sidebarMainWrap'>
                <MuiBox className='logoCon'>
                    <img src={Logo} alt="" />
                </MuiBox>
                <MuiBox className='sidebarContent'>
                    <MuiBox className='menuCon'>
                        <MuiBox>
                            <MuiBox component='ul'>
                                <MuiListItem>
                                    <NavLink activeClassName='active' to='/dashboard'>
                                        <MuiListItemIcon><DashboardIcon /></MuiListItemIcon>
                                        <MuiListItemText primary='Dashboard' />
                                    </NavLink>
                                </MuiListItem>
                                {this.renderMenus(MENUS)}
                            </MuiBox>
                        </MuiBox>
                        <MuiBox>
                            <MuiBox component='ul' className='bottomMenuCon'>
                                {Map(BOTTOM_MENUS, (bttmMenu: BasicMenu, bttmMenuIndex: number) => (
                                    <MuiListItem sx={{ cursor: 'pointer' }} key={bttmMenuIndex} onClick={() => this.handleMenuClick(bttmMenu)}>
                                        <MuiListItemIcon>{<bttmMenu.icon />}</MuiListItemIcon>
                                        <MuiListItemText primary={bttmMenu.title} />
                                    </MuiListItem>
                                ))}
                            </MuiBox>
                        </MuiBox>
                    </MuiBox>
                    {isNotificationBarOpen && <NotificationTab open onClose={this.handleNotifications} {...this.props} />}
                    {isAccountBarOpen &&
                        <AccountTab
                            open
                            onClose={this.handleAccount}
                            onChangePasswordClick={this.handlePasswordModal}
                            onMfaVerifyClick={this.handleMfaVerifyModal}
                            {...this.props}
                        />
                    }
                </MuiBox>

                {isMfaVerifyModalOpen &&
                    <MfaVerifyModal
                        open={isMfaVerifyModalOpen}
                        title='Scan QR code'
                        onClose={this.handleMfaVerifyModal}
                        onFormSubmit={this.handleVerifyCode}
                    />
                }

                {isChangePasswordModalOpen &&
                    <ChangePasswordModal
                        onFormSubmit={this.handlePasswordFormSubmit}
                        open={isChangePasswordModalOpen}
                        onClose={this.handlePasswordModal}
                        title='Change Password'
                    />
                }

            </MuiBox>

        )
    }
}

export default Sidebar;
