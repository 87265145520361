import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js'
import { RechargeService } from 'Services';
import React, { useEffect, useState } from 'react'

function PayPalCheckout(props: any) {

    const { balanceModel, history } = props;
    const [loading, setLoading] = useState(false);

    const options = {
        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID as string,
        currency: "EUR"
    }

    useEffect(() => {
        setLoading(true);
    }, []);

    const createOrder = (data: any, actions: any) => {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: balanceModel.total_amount,
                    },
                },
            ],
        }).then((orderId: any) => {
            return orderId;
        });
    }

    const onApprove = (data: any, actions: any) => {
        return actions.order.capture().then((details: any) => {
            RechargeService.initPayPalRecharge({ ...balanceModel, payment_id: details.id })
                .then((resp: any) => {
                    // 
                }).catch((err: any) => {
                    return err;
                });
            setLoading(false);
            history.goBack();
        });
    }

    return (
        <PayPalScriptProvider deferLoading={loading} options={options}>
            <PayPalButtons style={{
                layout: 'vertical',
                color: 'gold',
                shape: 'rect',
                label: 'paypal',
            }}
                createOrder={createOrder}
                onApprove={onApprove}
            />
        </PayPalScriptProvider>
    )
}

export default PayPalCheckout;