import { Checkbox, DialogProps, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput } from '@mui/material'
import { MuiBox, MuiButton, MuiDialog, MuiDialogActions, MuiDialogContent, MuiDialogTitle, MuiFormHelperText, MuiGrid, MuiIconButton, MuiInputField, MuiListField } from 'Components/MUI'
import { Component } from 'react'
import { StyledCreateModal } from './style';
import { CloseIcon } from 'Helpers/Icons';
import { AddSimToCompanyState } from 'Redux/States';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { States } from 'Redux/Models';
import { AddSimToCompanyValidator } from 'Helpers/Validators';
import { SimService } from 'Services';
import Select, { SelectChangeEvent } from '@mui/material/Select';
interface AssignSimsModalProps extends DialogProps {
    title: string,
    onFormSubmit: Function
}

class AddSimToCompany extends Component<AssignSimsModalProps> {
  public state: AddSimToCompanyState = new AddSimToCompanyState();

  public handleAddSimtoCompanySubmit = (values: States, actions: FormikHelpers<States>) => {
    const { onFormSubmit } = this.props;
    actions.setSubmitting(true);
    if (onFormSubmit) onFormSubmit(values.seletedSims, actions);
  };

  public simList = () => {
    SimService.getListForCompany()
      .then((result) => {
        if (result.isSuccess()) {
          this.setState((prevState: AddSimToCompanyState) => ({
            sims: result?.data,
          }));
        }
      })
      .catch((err) => err);
  };

  public componentDidMount(): void {
    this.simList();
  }

  public handleSelectSims = (event: SelectChangeEvent<typeof seletedSims>, props: FormikProps<States>) => {
    const {
      target: { value },
    } = event;
    const { companySimData: {seletedSims} } = this.state;
    let items = [...seletedSims];
    const newItems = value.filter((item: any) => !items.includes(item));
    const removedItems = items.filter((item) => !value.includes(item));
    items = items.filter((item) => !removedItems.includes(item));
    items.push(...newItems);
    props.setFieldValue('seletedSims', items);
  };

  render() {
    const { sims, companySimData } = this.state;
    const { open, onClose, title, ...restProps }: AssignSimsModalProps =
      this.props;
    return (
      <MuiDialog open={open} onClose={onClose} {...restProps}>
        <StyledCreateModal>
          <Formik
            initialValues={companySimData}
            onSubmit={this.handleAddSimtoCompanySubmit}
            validationSchema={AddSimToCompanyValidator}
          >
            {(props) => (
              <MuiBox component="form" noValidate onSubmit={props.handleSubmit}>
                <MuiBox
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  className="heading"
                >
                  <MuiDialogTitle className="title">{title}</MuiDialogTitle>
                  {onClose && (
                    <MuiIconButton onClick={() => onClose({}, "backdropClick")}>
                      <CloseIcon />
                    </MuiIconButton>
                  )}
                </MuiBox>
                <MuiDialogContent className="content">
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id="demo-multiple-checkbox-label">
                      Select sims
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={props.values.seletedSims}
                      onChange={(e: SelectChangeEvent<any>) => this.handleSelectSims(e, props)}
                      input={<OutlinedInput label="Assign sim" />}
                    //   renderValue={(selected) => selected.join(", ")}
                      renderValue={(selected) => (
                        selected.map((selectedId: string) => {
                          const foundSim = sims.find((sim) => sim._id === selectedId);
                          return foundSim ? foundSim.thingName : 'Unknown';
                        }).join(", ")
                      )}
                      name="seletedSims"
                    >
                      {sims.map((sim) => (
                        <MenuItem key={sim.name} value={sim._id}>
                          <Checkbox
                            checked={props.values.seletedSims.indexOf(sim._id) > -1}
                          />
                          <ListItemText primary={sim.thingName} />
                        </MenuItem>
                      ))}
                    </Select>
                    {props.touched.seletedSims && !!props.errors.seletedSims && 
                        <MuiFormHelperText error={props.touched.seletedSims && !!props.errors.seletedSims}>
                            {String(props.touched.seletedSims ? props.errors.seletedSims : "")}
                        </MuiFormHelperText>
                    }
                  </FormControl>
                </MuiDialogContent>
                <MuiDialogActions className="button">
                  <MuiBox className="submit-btn">
                    <MuiButton type="submit" className="btn create-btn">
                      Add
                    </MuiButton>
                    {onClose && (
                      <MuiButton
                        variant="outlined"
                        className="btn cancel-btn"
                        onClick={() => onClose({}, "backdropClick")}
                      >
                        Back
                      </MuiButton>
                    )}
                  </MuiBox>
                </MuiDialogActions>
              </MuiBox>
            )}
          </Formik>
        </StyledCreateModal>
      </MuiDialog>
    );
  }
}

export default AddSimToCompany;