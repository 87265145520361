export const SIM_STATES = {
    ACTIVE: 'ACTIVE',
    SUSPENDED: 'SUSPENDED'
}

export const MODULES = {
    PRICING_MANAGEMENT: 'PRICING_MANAGEMENT',
    SIMS_MANAGEMENT: 'SIMS_MANAGEMENT',
    GROUP_MANAGEMENT: 'GROUP_MANAGEMENT',
    ROLE_MANAGEMENT: 'ROLE_MANAGEMENT',
    EMPLOYEE_MANAGEMENT: 'EMPLOYEE_MANAGEMENT',
    COMPANY_MANAGEMENT: 'COMPANY_MANAGEMENT',
    RESELLER_MANAGEMENT: 'RESELLER_MANAGEMENT',
    BRANCH_MANAGEMENT: 'BRANCH_MANAGEMENT',
    USER_MANAGEMENT: 'USER_MANAGEMENT',
    PLAN_MANAGEMENT: 'PLAN_MANAGEMENT',
    AUTOMATION_MANAGEMENT: 'AUTOMATION_MANAGEMENT',
     // all 
     REPORTS: 'REPORTS',
     CUSTOMER_USAGE_REPORT: 'CUSTOMER_USAGE_REPORT',
     PROVIDER_DATA_USAGE_REPORT: 'PROVIDER_DATA_USAGE_REPORT',
     GEOGRAPHIC_DATA_CONSUMPTION_REPORT: 'GEOGRAPHIC_DATA_CONSUMPTION_REPORT',
     DATA_USAGE_TREND_REPORT: 'DATA_USAGE_TREND_REPORT',
     DEVICE_USAGE_REPORT: 'DEVICE_USAGE_REPORT',
     CUSTOMER_PROVIDER_PREFERENCE_REPORT: 'CUSTOMER_PROVIDER_PREFERENCE_REPORT',
     // for super admin 
     COST_ANALYSIS_REPORT: 'COST_ANALYSIS_REPORT',
     HIGH_USAGE_CUSTOMER_REPORT: 'HIGH_USAGE_CUSTOMER_REPORT',
     PROVIDER_PERFORMANCE_REPORT: 'PROVIDER_PERFORMANCE_REPORT',
     PROVIDER_COST_ANALYSIS_REPORT: 'PROVIDER_COST_ANALYSIS_REPORT',
     CUSTOMER_DETAIL_REPORT: 'CUSTOMER_DETAIL_REPORT',
     COUNTRYWISE_TOTAL_CONSUMPTION_REPORT: 'COUNTRYWISE_TOTAL_CONSUMPTION_REPORT',
     PROVIDER_TOTAL_CONSUMPTION_REPORT: 'PROVIDER_TOTAL_CONSUMPTION_REPORT'
};

export const USER_TYPES = {
    SUPRER_ADMIN: 'SUPRER_ADMIN',
    EMPLOYEE: 'EMPLOYEE',
    CUSTOMER: 'CUSTOMER',
    RESELLER: 'RESELLER',
};

export const PERMISSIONS = {
    READ: 'READ',
    READ_WRITE: 'READ_WRITE',
};

export const SUPERADMIN_MODULES = [
    MODULES.PRICING_MANAGEMENT,
    MODULES.SIMS_MANAGEMENT,
    MODULES.GROUP_MANAGEMENT,
    MODULES.ROLE_MANAGEMENT,
    MODULES.EMPLOYEE_MANAGEMENT,
    MODULES.RESELLER_MANAGEMENT,
    MODULES.COMPANY_MANAGEMENT,
    MODULES.PLAN_MANAGEMENT,
    MODULES.USER_MANAGEMENT,
    MODULES.BRANCH_MANAGEMENT,
    MODULES.AUTOMATION_MANAGEMENT,

    MODULES.REPORTS,
    MODULES.CUSTOMER_USAGE_REPORT,
    MODULES.PROVIDER_DATA_USAGE_REPORT,
    MODULES.GEOGRAPHIC_DATA_CONSUMPTION_REPORT,
    MODULES.DATA_USAGE_TREND_REPORT,
    MODULES.DEVICE_USAGE_REPORT,
    MODULES.CUSTOMER_PROVIDER_PREFERENCE_REPORT,
    MODULES.COST_ANALYSIS_REPORT,
    MODULES.HIGH_USAGE_CUSTOMER_REPORT,
    MODULES.PROVIDER_PERFORMANCE_REPORT,
    MODULES.PROVIDER_COST_ANALYSIS_REPORT,
    MODULES.CUSTOMER_DETAIL_REPORT,
    MODULES.COUNTRYWISE_TOTAL_CONSUMPTION_REPORT,
    MODULES.PROVIDER_TOTAL_CONSUMPTION_REPORT
];

export const CUSTOMER_MODULES = [
    MODULES.BRANCH_MANAGEMENT,
    MODULES.USER_MANAGEMENT,
    MODULES.SIMS_MANAGEMENT,
    MODULES.PLAN_MANAGEMENT,
    MODULES.GROUP_MANAGEMENT,
    MODULES.PRICING_MANAGEMENT,
    MODULES.ROLE_MANAGEMENT,
    MODULES.AUTOMATION_MANAGEMENT,

    MODULES.REPORTS,
    MODULES.CUSTOMER_USAGE_REPORT,
    MODULES.PROVIDER_DATA_USAGE_REPORT,
    MODULES.GEOGRAPHIC_DATA_CONSUMPTION_REPORT,
    MODULES.DATA_USAGE_TREND_REPORT,
    MODULES.DEVICE_USAGE_REPORT,
    MODULES.CUSTOMER_PROVIDER_PREFERENCE_REPORT
];

export const VALIDATIONS = {
    REQUIRED: 'required',
    NAME: 'required|min:2',
    PHONE: 'required|min:6|max:15',
    EMAIL: 'required|email',
    PASSWORD: 'required|min:6',
    VAT: 'required|min:8',
    IMEI_PREFIX: 'required|max:8'
}

export const INTELLIGENCE_PLATFORM = [
    { name: 'Payment Create', value: "payment_create" },
    { name: 'Tracker Register', value: "tracker_register" },
    { name: 'Tracker Configure', value: "tracker_configure" },
    { name: 'Tracker Update', value: "tracker_update" },
    { name: 'Tracker Set Otput', value: "tracker_set_output" },
    { name: 'Tracker Rule Update', value: "tracker_rule_update" },
    { name: 'Reports', value: "reports" },
    { name: 'Zone Update', value: "zone_update" },
    { name: 'Place Update', value: "place_update" },
    { name: 'Task Update', value: "task_update" },
    { name: 'Form Template Update', value: "form_template_update" },
    { name: 'Employee Update', value: "employee_update" },
    { name: 'Vehicle Update', value: "vehicle_update" },
    { name: 'Tag Update', value: "tag_update" },
    { name: 'Place Custom Field Update', value: "places_custom_fields_update" },
    { name: 'Video monitoring', value: "video_monitoring" }
];