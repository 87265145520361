import { Http } from "Http";
import BaseService from "./BaseService";
import { Response } from "Redux/Models";

export default class RechargeService extends BaseService {
    public static initStripeRecharge(params: object = {}): Promise<Response<any>> {
        RechargeService.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.post('/initrecharge', params).then(resp => {
                return resolve(resp.data);
            }).catch(err => {
                return reject(err)
            })
        })
    }

    public static initPayPalRecharge(params: object = {}): Promise<Response<any>> {
        RechargeService.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.post('/init-paypal-recharge', params).then(resp => {
                return resolve(resp.data);
            }).catch(err => {
                return reject(err)
            })
        })

    }
}