import BaseService from './BaseService';
import { Http } from '../Http';
import { Options, PriceModel, Response, TadigZoneModel } from 'Redux/Models';

export default class PriceService extends BaseService {

    public static getList(params: Object = {}, options: Options = {}): Promise<Response<PriceModel>> {
        let url: string = '/prices';
        if (options?.root) {
            url = options.root + url;
        }
        PriceService.initCancelToken();
        if (options?.source) {
            PriceService.source = options?.source;
        }
        return new Promise((resolve, reject) => {
            Http.get(url, { params, cancelToken: PriceService.source?.token })
                .then((res) => {
                    let data = new Response<PriceModel>(res.data);
                    return resolve(data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });

    }

    public static update(id: string, params: Object = {}): Promise<Response<PriceModel>> {
        PriceService.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.patch('/prices/' + id, params).then(resp => {
                let data = new Response<PriceModel>(resp.data);
                return resolve(data);
            }).catch(err => {
                return reject(err)
            })
        })
    }


    public static create(params: Object = {}, options: Options = {}): Promise<Response<PriceModel>> {
        PriceService.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.post('/prices', params).then(resp => {
                let data = new Response<PriceModel>(resp.data);
                return resolve(data);
            }).catch(err => {
                return reject(err)
            })
        })
    }

    public static delete(id: string, params: Object = {}): Promise<Response<null>> {
        PriceService.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.delete('/prices/' + id, params).then(resp => {
                let data = new Response<null>(resp.data);
                return resolve(data);
            }).catch(err => {
                return reject(err)
            })
        })
    }

    public static getTADIGZones(): Promise<Response<Array<TadigZoneModel>>> {
        PriceService.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.get('/zones', { cancelToken: PriceService.source?.token }).then(resp => {
                let data = new Response<Array<TadigZoneModel>>(resp?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static getPriceById(id: string, params: Object = {}): Promise<Response<PriceModel>> {
        PriceService.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.get('/prices/' + id, params).then(resp => {
                let data = new Response<PriceModel>(resp?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

}
