import { MuiAutocomplete, MuiAvatar, MuiBackdrop, MuiBox, MuiButton, MuiIconButton, MuiInputField, MuiListItem, MuiPhoneInput, MuiTypography } from "Components/MUI";
import { ChangeEvent, Component, RefObject, SyntheticEvent, createRef } from "react";
import { StyledAccountTab } from "./style";
import { EditIcon } from "Helpers/CustomIcons";
import { Props, UserModel } from "Redux/Models";
import { CountryToFlag, GetImage, stringAvatar } from "Helpers/String";
import { AccountState } from "Redux/States";
import { SaveIcon } from "Helpers/Icons";
import { AutocompleteChangeReason, BackdropProps, Button } from "@mui/material";
import { Country, countries } from "Helpers/Countries";
import { Find } from "Helpers/Array";
import { AuthService } from "Services";
import { LoadUser } from "Redux/Actions";

interface AccountTabProps extends BackdropProps, Props {
    onClose: () => void
}

export class AccountTab extends Component<AccountTabProps> {

    public state: AccountState = new AccountState();
    public inputRef: RefObject<any>;

    public componentDidMount(): void {
        const { user } = this.props;
        const userdata: UserModel = {
            firstName: user.firstName ? user.firstName : '',
            lastName: user.lastName ? user.lastName : '',
            email: user.email ? user.email : '',
            phoneNumber: user.phoneNumber ? user.phoneNumber : '',
            country: user.country ? user.country : '',
            image: user.image ? user.image : ''
        };
        this.setState((prevState: AccountState) => ({
            account: userdata
        }))
    }

    constructor(props: AccountTabProps) {
        super(props);
        this.inputRef = createRef();
    }

    public changeActiveInput = (name: string) => {
        if (!name) return;
        const { activeInput } = this.state;
        let fieldName: Array<string> = [];
        if (activeInput.includes(name)) {
            fieldName = activeInput.filter(item => item !== name);
        } else {
            fieldName = [...activeInput, name];
        }
        this.setState((prevState: AccountState) => ({
            activeInput: fieldName
        }))
    }

    public handleCountrySelect = (event: SyntheticEvent, value: Country | any, reason: AutocompleteChangeReason) => {
        const isPresent: Country | undefined = Find(countries, value);
        let countryVal: any = null;
        if (isPresent && reason === 'selectOption') {
            countryVal = value.name;
        } else if (reason === 'clear' || reason === 'removeOption') {
            countryVal = {};
        }
        this.setState((prevState: AccountState) => ({
            account: { ...prevState.account, country: countryVal }
        }))
    }

    public handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        this.setState((prevState: AccountState) => {
            prevState.account[name] = value;
            return prevState;
        })
    }

    public handleSave = (name: string) => {
        const { account } = this.state;
        const { dispatch, user } = this.props;
        this.changeActiveInput(name);
        const { image, ...rest } = account;
        if (user[name] === account[name]) return;
        else {
            AuthService.updateProfile({ ...rest }).then((resp) => {
                if (resp.isSuccess()) {
                    dispatch(LoadUser(resp.data));
                }
            }).catch((err) => {
                return err;
            })
        }
    }

    public onSaveOrEditClick = (name: string) => {
        const { activeInput } = this.state;
        if (activeInput.includes(name)) this.handleSave(name)
        else this.changeActiveInput(name);
    }

    public handleUploadImage = (event: ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;
        const { dispatch } = this.props;
        if (!files || files.length < 1) return;
        const file: any = files[0];
        const acceptedFormats = ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml'];
        if (file && acceptedFormats.includes(file.type)) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState((prev: AccountState) => ({
                    ...prev, account: {
                        ...prev.account, image: file, profileShowImage: reader.result?.toString()
                    }
                }))
            );
            reader.readAsDataURL(file);
        }
        const fData: FormData = new FormData();
        fData.append("image", files[0]);
        AuthService.uploadImage(fData).then((resp) => {
            if (resp.isSuccess()) {
                dispatch(LoadUser(resp.data));
            }
        }).catch((err) => {
            return err;
        })
        this.inputRef.current.value = null;
    }

    render() {
        const { onChangePasswordClick, onClose, onMfaVerifyClick, isMobileOpen, user } = this.props;
        const { account, activeInput } = this.state;
        const fullName = (user.firstName || '') + ' ' + (user.lastName || '');
        const country = account.country ? Find(countries, { name: account.country }) : null;
        return (
            <MuiBackdrop open onClick={onClose} sx={{
                width: 'calc(100% - 770px)',
                left: 'auto',
                zIndex: 1,
            }}>
                <StyledAccountTab sx={{ left: !isMobileOpen ? 0 : 270, width: !isMobileOpen ? 770 : 500 }} onClick={e => e.stopPropagation()}>
                    <MuiBox className='heading'>
                        <MuiTypography component="h2" className='page-title'>
                            <MuiTypography component="span">
                                Account
                            </MuiTypography>
                        </MuiTypography>
                    </MuiBox>
                    <MuiBox className='content'>
                        <MuiBox className='account-img' sx={{ display: 'flex', alignItems: 'center' }}>
                            <MuiAvatar
                                className="acc-img"
                                src={account.profileShowImage ? account.profileShowImage : GetImage(account.image)}
                                {...stringAvatar(fullName)}
                            />
                            <Button variant="text" className="change-text" component="label">
                                Change profile photo
                                <MuiBox>
                                    <input
                                        id="upload-image"
                                        hidden
                                        ref={this.inputRef}
                                        accept="image/*"
                                        type="file"
                                        onChange={this.handleUploadImage}
                                    />
                                </MuiBox>
                            </Button>
                        </MuiBox>

                        <MuiBox className='acc-details'>
                            <MuiTypography component="h2" className='title'>
                                <MuiTypography component="span">
                                    Account Details
                                </MuiTypography>
                            </MuiTypography>
                            <MuiBox className="details">

                                <MuiBox className="row input_box">
                                    <MuiTypography className="first">First Name</MuiTypography>
                                    {activeInput.includes('firstName') ?
                                        <MuiInputField
                                            name="firstName"
                                            InputProps={{ readOnly: !activeInput.includes('firstName') }}
                                            value={account.firstName || ''}
                                            onChange={this.handleChange}
                                        /> :
                                        <MuiTypography>{account.firstName || 'N/A'}</MuiTypography>
                                    }
                                    <MuiIconButton onClick={() => this.onSaveOrEditClick('firstName')}>
                                        {activeInput.includes('firstName') ? <SaveIcon /> : <EditIcon />}
                                    </MuiIconButton>
                                </MuiBox>

                                <MuiBox className="row input_box">
                                    <MuiTypography className="first">Last Name</MuiTypography>
                                    {activeInput.includes('lastName') ? <MuiInputField
                                        name="lastName"
                                        InputProps={{ readOnly: !activeInput.includes('lastName') }}
                                        value={account.lastName || ''}
                                        onChange={this.handleChange}
                                    />
                                        :
                                        <MuiTypography>{account.lastName || 'N/A'}</MuiTypography>
                                    }
                                    <MuiIconButton onClick={() => this.onSaveOrEditClick('lastName')}>
                                        {activeInput.includes('lastName') ? <SaveIcon /> : <EditIcon />}
                                    </MuiIconButton>
                                </MuiBox>

                                <MuiBox className="row input_box">
                                    <MuiTypography className="first">Email</MuiTypography>
                                    <MuiTypography>{account.email || 'N/A'}</MuiTypography>
                                    <MuiIconButton className="email-icon">
                                        <EditIcon />
                                    </MuiIconButton>
                                </MuiBox>

                                <MuiBox className="row input_box">
                                    <MuiTypography className="first">Phone Number</MuiTypography>
                                    {activeInput.includes('phoneNumber') ? <MuiPhoneInput
                                        name="phoneNumber"
                                        readOnly={!activeInput.includes('phoneNumber')}
                                        value={account.phoneNumber || 'N/A'}
                                        onChange={this.handleChange}
                                    />
                                        :
                                        <MuiTypography>{account.phoneNumber || 'N/A'}</MuiTypography>
                                    }
                                    <MuiIconButton onClick={() => this.onSaveOrEditClick('phoneNumber')}>
                                        {activeInput.includes('phoneNumber') ? <SaveIcon /> : <EditIcon />}
                                    </MuiIconButton>
                                </MuiBox>

                                <MuiBox className="row input_box">
                                    <MuiTypography className="first">Country</MuiTypography>
                                    {activeInput.includes('country') ?
                                        <MuiAutocomplete
                                            freeSolo
                                            fullWidth
                                            readOnly={!activeInput.includes('country')}
                                            value={country || ''}
                                            clearOnBlur={false}
                                            onChange={this.handleCountrySelect}
                                            options={countries || []}
                                            groupBy={(option: Country) => option.name[0].toUpperCase()}
                                            renderInput={(params) => (
                                                <MuiInputField
                                                    name="country"
                                                    {...params}
                                                    value={account.country}
                                                // error
                                                // helperText="This is a error"
                                                />
                                            )}
                                            isOptionEqualToValue={(option: Country, value: Country) => option.code === value.code && option.dialCode === value.dialCode}
                                            getOptionLabel={(option: Country | string | any) => (option.name && option.code) ? `${option.name} (${option.code})` : ""}
                                            renderOption={(props, option: Country) => (
                                                <MuiListItem sx={{ padding: '10px', fontSize: 15, cursor: 'pointer' }} className="countryLi" {...props}>
                                                    <img src={CountryToFlag(option.code)} alt={option.name} />
                                                    &nbsp; {option.name}
                                                </MuiListItem>
                                            )}
                                        />
                                        :
                                        <MuiTypography>{account.country || 'N/A'}</MuiTypography>
                                    }
                                    <MuiIconButton onClick={() => this.onSaveOrEditClick('country')}>
                                        {activeInput.includes('country') ? <SaveIcon /> : <EditIcon />}
                                    </MuiIconButton>
                                </MuiBox>
                            </MuiBox>
                        </MuiBox>

                        <MuiBox className="security-details">
                            <MuiTypography component="h2" className='title'>
                                <MuiTypography component="span">
                                    Security
                                </MuiTypography>
                            </MuiTypography>
                            <MuiBox className='first-row'>
                                <MuiTypography>Anti phising code</MuiTypography>
                                <MuiTypography>h1********NT</MuiTypography>
                                <div className="icon">
                                    <EditIcon />
                                </div>
                            </MuiBox>
                            <MuiBox className='second-row'>
                                <MuiButton onClick={onMfaVerifyClick} className="btn">Turn on MFA</MuiButton>
                                <MuiButton className="btn" onClick={onChangePasswordClick}>Change password</MuiButton>
                            </MuiBox>
                        </MuiBox>
                    </MuiBox>

                </StyledAccountTab>
            </MuiBackdrop>
        )
    }
}

export default AccountTab
