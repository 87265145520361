import { Props } from 'Redux/Models';
import { Component } from 'react'
import Chart from "react-apexcharts";

export class UsagePieChart extends Component {

    public data: any;

    constructor(props: Props) {
        super(props);
        this.data = {
            series: [44, 55],
            options: {
                chart: {
                    width: 380,
                    type: 'pie',
                },
                labels: ['Team A', 'Team B'],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },


        };
    }

    render() {
        return (
            <Chart
                options={this.data.options}
                series={this.data.series}
                type='pie'
                width={250}
            />
        )
    }
}

export default UsagePieChart