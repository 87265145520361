import { Country } from "Helpers/Countries";
import { BaseStateModel, GroupModel, SimModel, States, UserModel, AlertModel } from "Redux/Models";


export class DashboardState extends BaseStateModel {
    public isOpen: boolean = false;
    public isNotificationBarOpen: boolean = false;
    public isAccountBarOpen: boolean = false;
    public isChangePasswordModalOpen: boolean = false;
    public isMfaVerifyModalOpen: boolean = false;
    public mobileOpen: boolean = false;
    public openMenus: any = {};
    public dashboard: States = {
        stats: [
            {
                tracking: {
                    title: 'Tracking',
                    description: 'Track your assets an the map online and get the camplete trips history.',
                    data: [
                        { count: 0, label: 'Online', key: 'online' },
                        { count: 0, label: 'Offline', key: 'offline' },
                        { count: 0, label: 'GPS not updated', key: 'gpsNotUpdated' },
                        { count: 0, label: 'Other', key: 'other' },
                    ]
                }
            },
            {
                tasks: {
                    title: 'Tasks',
                    description: 'Schedule tasks to employees and control their implementation.',
                    data: [
                        { count: 0, label: 'Completed', key: 'completed' },
                        { count: 0, label: 'Failed', key: 'failed' },
                        { count: 0, label: 'Assigned', key: 'assigned' },
                        { count: 0, label: 'Other', key: 'other' },
                    ]
                }
            },
            {
                fleet: {
                    title: 'Fleet',
                    description: 'Control yaur vehicles, harsh driving and charges.',
                    data: [
                        { count: 0, label: 'Completed', key: 'completed' },
                        { count: 0, label: 'Expired', key: 'expired' },
                        { count: 0, label: 'Scheduled', key: 'scheduled' },
                        { count: 0, label: 'Other', key: 'other' },
                    ]
                }
            }
        ]
    }
}

export class ChangePasswordState extends BaseStateModel {
    public form: States = {
        currentPassword: "",
        password: "",
        confirmPassword: ""
    }
}

export class MfaVerifyState extends BaseStateModel {
    public mfaModal: States = {
        base32: "",
        qr: "",
        code: ""
    }
}

export class AccountState extends BaseStateModel {
    public activeInput: Array<string> = [];
    public selectedCountry: Country | null = null;
    public account: UserModel = {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        country: '',
        image: '',
        profileShowImage: ''
    }
}

export type CardType = 'CARD' | 'PAYPAL' | 'IDEAL';

export class BalanceState extends BaseStateModel {
    public isBalanceModalOpen: boolean = false;
    public modalType: string = '';
}

export class RechargeBalanceState extends BaseStateModel {
    public activeStep: number = 0;
    public cardType: CardType = 'CARD';
    public balanceModel = {
        amount: '',
        method: 'CARD',
        charge: '',
        vat: '',
        total_amount: ''
    }
    public clientSecret: string = "";
    public showPaypal: boolean = false;
    public loading: boolean = false;
    public paymentMethods: Array<{ key: string, label: string, icon: any }> = [];
}

export class SimSummaryState extends BaseStateModel {
    public count: number = 0;
    public simModel = {
        active: 0,
        provisioned: 0,
        retired: 0,
        suspended: 0
    }
    public simLists: Array<SimModel> = [];
    public isLoading: boolean = false;
    public isViewAllModalOpen: boolean = false;
}

export class GroupSummaryState extends BaseStateModel {
    public groups: Array<GroupModel> = [];
    public count: number = 0;
    public simGroups: Array<{ groupName: string, count: number }> = [];
    public isViewAllModalOpen: boolean = false;
    public id: string
}

    export class AlertSummaryState extends BaseStateModel {
    public total: number = 0;
    public alartData: Array<AlertModel> = [];
    public alertCountByType = {
        critical: 0,
        error: 0,
        info: 0
    };
    public isLoading: boolean = false;
    public isViewAllModalOpen: boolean = false;
}