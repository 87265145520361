import { DialogProps } from '@mui/material'
import { MuiBox, MuiButton, MuiCheckbox, MuiDialog, MuiDialogActions, MuiDialogContent, MuiDialogTitle, MuiFormControlLabel, MuiFormHelperText, MuiIconButton, MuiInputField, MuiSwitchButton, MuiTypography } from 'Components/MUI'
import { INTELLIGENCE_PLATFORM, USER_TYPES } from 'Configs/Constants';
import { Map } from 'Helpers/Array';
import { CloseIcon } from 'Helpers/Icons';
import { ModuleModel, PermissionModel, RoleModel, UserModel } from 'Redux/Models';
import { CreateRoleState } from 'Redux/States';
import { ModuleService } from 'Services';
import React, { Component, Fragment } from 'react'
import { Formik, FormikHelpers } from 'formik';
import { RoleCreateValidator } from 'Helpers/Validators';
import { StyledCreateModal } from './style';

interface RoleCreateDialogProps extends DialogProps {
    title: string,
    onFormSubmit: Function,
    isRoleEditing: boolean,
    editRoleData: any,
    user: UserModel
}


class RoleCreateModal extends Component<RoleCreateDialogProps> {

    public state: CreateRoleState = new CreateRoleState();

    public componentDidMount(): void {
        this.getModules();
        this.getPermissions();
    }

    public componentDidUpdate(prevProps: Readonly<RoleCreateDialogProps>, prevState: Readonly<{}>, snapshot?: any): void {
        const { isRoleEditing, editRoleData }: RoleCreateDialogProps = this.props;
        if (isRoleEditing && editRoleData !== prevProps.editRoleData) {
            this.setEditRoleData();
        }
    }

    public setEditRoleData = () => {
        const { editRoleData }: RoleCreateDialogProps = this.props;
        this.setState((prevState: CreateRoleState) => {
            prevState.role = editRoleData;
            return prevState;
        })
    }

    public getModules = () => {
        ModuleService.getModules().then((resp) => {
            this.setState((prevState: CreateRoleState) => ({
                modules: resp.data
            }))
        }).catch((err) => {
            return err;
        })
    }

    public getPermissions = () => {
        ModuleService.getPermissions().then((resp) => {
            this.setState((prevState: CreateRoleState) => ({
                permissions: resp.data
            }))
        }).catch((err) => {
            return err;
        })
    }

    public handleInsightToggle = (props: FormikHelpers<RoleModel>) => {
        const isChecked = this.state.role.isInsightsOn
        this.setState((prevState: CreateRoleState) => {
            prevState.role.isInsightsOn = !isChecked;
            return prevState;
        })
        props.setFieldValue('isInsightsOn', !isChecked);
    }

    public onDataChange = (event: React.ChangeEvent<HTMLInputElement>, props: FormikHelpers<RoleModel>) => {
        const { name, checked } = event.target;
        const { role } = this.state;
        let obj: any = { ipPrmissns: [...role.ipPermissions] };
        if (checked) {
            obj.ipPrmissns.push(name);
        } else {
            const index = obj.ipPrmissns.findIndex((prmssn: string) => prmssn === name);
            if (index > -1) obj.ipPrmissns.splice(index, 1);
        }
        this.setState((prevState: CreateRoleState) => {
            prevState.role.ipPermissions = obj.ipPrmissns;
            return prevState;
        });
        props.setFieldValue('ipPermissions', obj.ipPrmissns);
    }

    public onRolePermissionChange = (checked: boolean, permissionObj: PermissionModel, moduleObj: ModuleModel, props: FormikHelpers<RoleModel>) => {
        const { role } = this.state;
        let checkedPrmssns: Array<any> = [...role.moduleRolePermissions];
        const moduleIndex = checkedPrmssns.findIndex((prmmsn) => prmmsn.module === moduleObj._id);
        if (checked) {
            if (moduleIndex > -1) {
                checkedPrmssns[moduleIndex].permissions = [...checkedPrmssns[moduleIndex].permissions, permissionObj._id];
            } else {
                checkedPrmssns.push({
                    module: moduleObj._id,
                    permissions: [permissionObj._id],
                })
            }
        } else {
            const permssnIndex = checkedPrmssns[moduleIndex].permissions.findIndex((prmmsn: any) => prmmsn === permissionObj._id);
            if (checkedPrmssns[moduleIndex].permissions.length === 1) {
                checkedPrmssns.splice(moduleIndex, 1);
            } else checkedPrmssns[moduleIndex].permissions.splice(permssnIndex, 1);
        }

        this.setState((prevState: CreateRoleState) => {
            prevState.role.moduleRolePermissions = checkedPrmssns;
            return prevState;
        });
        props.setFieldValue('moduleRolePermissions', checkedPrmssns);
    }

    public handleFormSubmit = (values: RoleModel, actions: FormikHelpers<RoleModel>) => {
        const { isRoleEditing }: RoleCreateDialogProps = this.props;
        actions.setSubmitting(true);
        const { onFormSubmit } = this.props;
        if (onFormSubmit) {
            onFormSubmit(values, isRoleEditing, actions);
        }
    }

    render() {
        const { modules, permissions, role }: CreateRoleState = this.state;
        const { open, onClose, title, isRoleEditing, editRoleData, onFormSubmit, user, ...restProps }: RoleCreateDialogProps = this.props;
        const showInsights = user.hasType(USER_TYPES.CUSTOMER) && user.isIpUser;
        return (
            <>

                <MuiDialog open={open} onClose={onClose} {...restProps}
                    PaperProps={{
                        style: {
                            minWidth: role.isInsightsOn ? '1100px' : '850px',
                        }
                    }}
                >
                    <Formik initialValues={role} onSubmit={this.handleFormSubmit} validationSchema={RoleCreateValidator} enableReinitialize>
                        {(props) => (

                            <StyledCreateModal>
                                <MuiBox component='form' onSubmit={props.handleSubmit} noValidate>
                                    <MuiBox display='flex' alignItems='center' justifyContent='space-between' className='heading'>
                                        <MuiDialogTitle className='title'>{title}</MuiDialogTitle>
                                        {onClose &&
                                            <MuiIconButton onClick={() => onClose({}, 'backdropClick')}>
                                                <CloseIcon />
                                            </MuiIconButton>
                                        }
                                    </MuiBox>
                                    <MuiDialogContent className='content'>
                                        <MuiBox display='flex' className="first-row">
                                            <MuiBox>
                                                <MuiInputField
                                                    placeholder='Role name'
                                                    name='name'
                                                    value={props.values.name}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    error={props.touched.name && !!props.errors.name}
                                                    helperText={props.touched.name ? props.errors.name : ""}
                                                />
                                            </MuiBox>
                                            {showInsights &&
                                                <MuiBox>
                                                    <MuiFormControlLabel
                                                        label='Insights'
                                                        labelPlacement='start'
                                                        control={
                                                            <MuiSwitchButton
                                                                color='primary'
                                                                name='isInsightsOn'
                                                                checked={props.values.isInsightsOn}
                                                                onChange={() => this.handleInsightToggle(props)}
                                                            />
                                                        }
                                                    />
                                                </MuiBox>
                                            }
                                        </MuiBox>
                                        <MuiBox>
                                            <MuiTypography className='connectivity-text'>Connectivity</MuiTypography>
                                        </MuiBox>

                                        <MuiBox className={props.values.isInsightsOn ? 'secondRowCon secondRowLarge' : 'secondRowCon'}>
                                            {modules && modules.length > 0 && Map(modules, (module: ModuleModel, moduleIndex: number) => (

                                                <MuiBox key={moduleIndex} display='flex' alignItems='center' className='second-row'>
                                                    <MuiBox>
                                                        <MuiTypography className='module-name'>{module.name}</MuiTypography>
                                                    </MuiBox>

                                                    {permissions && permissions.length > 0 && Map(permissions, (permission: PermissionModel, prmssnIndex: number) => (
                                                        <MuiBox key={prmssnIndex}>
                                                            <MuiCheckbox
                                                                label={permission.name}
                                                                name={permission.name}
                                                                checked={props.values.moduleRolePermissions?.some((mdl: ModuleModel) => mdl.module === module._id && mdl?.permissions?.includes(permission._id))}
                                                                onChange={(e, checked: boolean) => this.onRolePermissionChange(checked, permission, module, props)}
                                                            />
                                                        </MuiBox>
                                                    ))}
                                                </MuiBox>
                                            ))}
                                        </MuiBox>
                                        <MuiFormHelperText
                                            error={props.touched.moduleRolePermissions && !!props.errors.moduleRolePermissions}
                                        >
                                            {props.touched.moduleRolePermissions ? props.errors.moduleRolePermissions : "" as any}
                                        </MuiFormHelperText>
                                        {showInsights &&
                                            <Fragment>
                                                {props.values.isInsightsOn &&
                                                    <MuiBox className='lower-part'>
                                                        <MuiBox>
                                                            <MuiTypography className='intel-text'>Intelligence platform</MuiTypography>
                                                        </MuiBox>
                                                        <MuiBox className='third-row'>
                                                            {Map(INTELLIGENCE_PLATFORM, (intlgncPltfrm, index: number) => (
                                                                <MuiBox key={index} display='flex'>
                                                                    <MuiFormControlLabel className='label'
                                                                        label={intlgncPltfrm.name}
                                                                        control={
                                                                            <MuiSwitchButton
                                                                                color='primary'
                                                                                name={intlgncPltfrm.value}
                                                                                checked={props.values.ipPermissions.includes(intlgncPltfrm.value)}
                                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onDataChange(e, props)}
                                                                            />
                                                                        }
                                                                    />
                                                                </MuiBox>
                                                            ))}
                                                        </MuiBox>
                                                        <MuiFormHelperText
                                                            error={props.touched.ipPermissions && !!props.errors.ipPermissions}
                                                        >
                                                            {props.touched.ipPermissions ? props.errors.ipPermissions : "" as any}
                                                        </MuiFormHelperText>
                                                    </MuiBox>
                                                }
                                            </Fragment>
                                        }
                                    </MuiDialogContent>
                                    <MuiDialogActions className='button'>
                                        <MuiBox className='submit-btn'>
                                            <MuiButton type='submit' className='btn create-btn' loading={props.isSubmitting} disabled={props.isSubmitting}>
                                                {isRoleEditing ? 'Update' : 'Create'}
                                            </MuiButton>
                                            {onClose &&
                                                <MuiButton variant="outlined" className='btn cancel-btn' onClick={() => onClose({}, 'backdropClick')}>
                                                    Back
                                                </MuiButton>
                                            }
                                        </MuiBox>
                                    </MuiDialogActions>
                                </MuiBox>
                            </StyledCreateModal>
                        )}
                    </Formik>
                </MuiDialog>
            </>
        )
    }
}

export default RoleCreateModal