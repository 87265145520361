import BaseService from './BaseService';
import { Http } from '../Http';
import { Options, NotificationModel, Response, ResponseList } from 'Redux/Models';


export default class NotificationService extends BaseService {
    public static getList(params: Object = {}, options: Options = {}): Promise<ResponseList<NotificationModel>> {
        let url = '/notification/list';
        if (options?.root) {
            url = options.root + url;
        }
        NotificationService.initCancelToken();
        if (options?.source) {
            NotificationService.source = options?.source;
        }
        return new Promise((resolve, reject) => {
            Http.get(url, { params, cancelToken: NotificationService.source?.token })
            .then((res) => {
                let data = new ResponseList<NotificationModel>(res.data);
                return resolve(data);
            })
            .catch((err) => {
                return reject(err);
            });
        })
    }
}