import { MuiBox } from 'Components/MUI';
import { Props, States } from 'Redux/Models';
import { Theme } from 'Theme';
import { Component } from 'react';
import Chart from "react-apexcharts";

interface UsageGaugeChartProps {
    value: string | number;
}

export class UsageGaugeChart extends Component<UsageGaugeChartProps, States> {

    constructor(props: UsageGaugeChartProps) {
        super(props);
        this.state = {
            series: [0],
            options: {
                chart: {
                    type: 'radialBar',
                    offsetY: -20,
                    sparkline: {
                        enabled: true
                    }
                },
                plotOptions: {
                    radialBar: {
                        startAngle: -90,
                        endAngle: 90,
                        track: {
                            background: "#e7e7e7",
                            strokeWidth: '97%',
                            margin: 5,
                        },
                        dataLabels: {
                            name: {
                                show: false
                            },
                            value: {
                                offsetY: -2,
                                fontSize: '22px'
                            }
                        }
                    }
                },
                grid: {
                    padding: {
                        top: -10
                    }
                },
                fill: {
                    type: 'solid',
                    colors: [Theme.palette.info.dark]
                },
                labels: ['Average Results'],
            },
        };
    }

    public componentDidUpdate(prevProps: Readonly<UsageGaugeChartProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if (this.props.value && prevProps?.value !== this.props?.value) {
          this.setState((prevState: States) => ({
            ...prevState,
            series: [this.props.value],
          }));
        }
      }

    render() {
        const { options, series }: States = this.state;
        return (
            <MuiBox my={2}>
                <Chart
                    options={options}
                    series={series}
                    type='radialBar'
                />
            </MuiBox>
        )
    }
}

export default UsageGaugeChart