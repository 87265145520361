import { DialogProps } from '@mui/material'
import { MuiBox, MuiButton, MuiDialog, MuiDialogActions, MuiDialogContent, MuiDialogTitle, MuiGrid, MuiIconButton, MuiInputField, MuiListField, MuiPhoneInput } from 'Components/MUI'
import React, { Component } from 'react'
import { StyledCreateModal } from './style';
import { CloseIcon } from 'Helpers/Icons';
import { EmployeeCreateState } from 'Redux/States';
import { RoleModel, UserModel } from 'Redux/Models';
import { Formik, FormikHelpers } from 'formik';
import { AddEmployeeValidator } from 'Helpers/Validators';

interface EmployeeCreateDialogProps extends DialogProps {
    title: string,
    roles: Array<RoleModel>,
    onFormSubmit: Function,
    isEmployeeEditing: boolean,
    editEmployeeData: any
}

class EmployeeCreateModal extends Component<EmployeeCreateDialogProps> {

    public state: EmployeeCreateState = new EmployeeCreateState();

    public handleSubmitForm = (values: UserModel, actions: FormikHelpers<UserModel>) => {
        const { isEmployeeEditing }: EmployeeCreateDialogProps = this.props;
        actions.setSubmitting(true);
        const { onFormSubmit } = this.props;
        if (onFormSubmit) {
            onFormSubmit(values, isEmployeeEditing, actions);
        }
    }

    public componentDidUpdate(prevProps: Readonly<EmployeeCreateDialogProps>, prevState: Readonly<{}>, snapshot?: any): void {
        const { isEmployeeEditing, editEmployeeData }: EmployeeCreateDialogProps = this.props;
        if (isEmployeeEditing && editEmployeeData !== prevProps.editEmployeeData) {
            this.setEditRoleData();
        }
    }

    public setEditRoleData = () => {
        const { editEmployeeData }: EmployeeCreateDialogProps = this.props;
        this.setState((prevState: EmployeeCreateState) => {
            prevState.employee = editEmployeeData;
            return prevState;
        })
    }

    render() {
        const { open, onClose, title, roles, editEmployeeData, isEmployeeEditing, onFormSubmit, ...restProps }: EmployeeCreateDialogProps = this.props;
        const { employee } = this.state;
        return (
            <MuiDialog open={open} onClose={onClose} {...restProps}>
                <StyledCreateModal>
                    <Formik initialValues={employee} onSubmit={this.handleSubmitForm} validationSchema={AddEmployeeValidator} enableReinitialize>
                        {(props) => (
                            <MuiBox component='form' noValidate onSubmit={props.handleSubmit}>
                                <MuiBox display='flex' alignItems='center' justifyContent='space-between' className='heading'>
                                    <MuiDialogTitle className='title'>{title}</MuiDialogTitle>
                                    {onClose &&
                                        <MuiIconButton onClick={() => onClose({}, 'backdropClick')}>
                                            <CloseIcon />
                                        </MuiIconButton>
                                    }
                                </MuiBox>
                                <MuiDialogContent className='content'>
                                    <MuiGrid container spacing={2}>
                                        <MuiGrid item sm={6} xs={12}>
                                            <MuiBox component="label" className="fieldTopLabel">First Name</MuiBox>
                                            <MuiInputField
                                                placeholder='First Name'
                                                name='firstName'
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={props.values.firstName}
                                                error={props.touched.firstName && !!props.errors.firstName}
                                                helperText={props.touched.firstName ? props.errors.firstName : ""}
                                            />
                                        </MuiGrid>
                                        <MuiGrid item sm={6} xs={12}>
                                            <MuiBox component="label" className="fieldTopLabel">Last Name</MuiBox>
                                            <MuiInputField
                                                placeholder='Last Name'
                                                name='lastName'
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={props.values.lastName}
                                                error={props.touched.lastName && !!props.errors.lastName}
                                                helperText={props.touched.lastName ? props.errors.lastName : ""}
                                            />
                                        </MuiGrid>
                                        <MuiGrid item sm={6} xs={12}>
                                            <MuiBox component="label" className="fieldTopLabel">Email</MuiBox>
                                            <MuiInputField
                                                placeholder='Email'
                                                name='email'
                                                disabled={isEmployeeEditing}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={props.values.email}
                                                error={props.touched.email && !!props.errors.email}
                                                helperText={props.touched.email ? props.errors.email : ""}
                                            />
                                        </MuiGrid>
                                        <MuiGrid item sm={6} xs={12}>
                                            <MuiBox component="label" className="fieldTopLabel">Phone Number</MuiBox>
                                            <MuiPhoneInput
                                                name="phoneNumber"
                                                onChange={props.handleChange}
                                                disabled={isEmployeeEditing}
                                                onBlur={props.handleBlur}
                                                value={props.values.phoneNumber}
                                                error={props.touched.phoneNumber && !!props.errors.phoneNumber}
                                                helperText={props.touched.phoneNumber ? props.errors.phoneNumber : ""}
                                            />
                                        </MuiGrid>
                                        <MuiGrid item sm={12} xs={12}>
                                            <MuiBox component="label" className="fieldTopLabel">Role</MuiBox>
                                            <MuiListField
                                                items={roles}
                                                titlefield='name'
                                                valuefield='_id'
                                                disabled={isEmployeeEditing}
                                                name="role"
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={props.values.role}
                                                error={props.touched.role && !!props.errors.role}
                                                helperText={props.touched.role && props.errors.role && String(props.errors.role)}
                                            />
                                        </MuiGrid>
                                    </MuiGrid>
                                </MuiDialogContent>
                                <MuiDialogActions className='button'>
                                    <MuiBox className='submit-btn'>
                                        <MuiButton type='submit' loading={props.isSubmitting} disabled={props.isSubmitting} className='btn create-btn'>
                                            {isEmployeeEditing ? 'Update' : 'Create'}
                                        </MuiButton>
                                        {onClose &&
                                            <MuiButton variant="outlined" className='btn cancel-btn' onClick={() => onClose({}, 'backdropClick')}>
                                                Back
                                            </MuiButton>
                                        }
                                    </MuiBox>
                                </MuiDialogActions>
                            </MuiBox>
                        )}
                    </Formik>
                </StyledCreateModal >
            </MuiDialog >
        )
    }
}

export default EmployeeCreateModal;