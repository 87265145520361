import { Http } from "Http";
import BaseService from "./BaseService";
import { BranchModel, Options, ResponseList } from "Redux/Models";

export default class BranchService extends BaseService {

    public static getBranchsListByCompany(id: string, params: Object = {}, options: Options = {}): Promise<ResponseList<BranchModel>> {
        BranchService.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.get('/companies/' + id + '/branches', { params, cancelToken: BranchService.source?.token }).then(resp => {
                let data = new ResponseList<BranchModel>(resp.data);
                return resolve(data);
            }).catch(err => {
                return reject(err)
            })
        })
    }
}