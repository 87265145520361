import { Http } from "Http";
import BaseService from "./BaseService";
import { AutomationRuleModel, Options, Response, ResponseList } from "Redux/Models";

export default class AutomationService extends BaseService {

    public static getList(params: Object = {}, options: Options = {}): Promise<ResponseList<AutomationRuleModel>> {
        let url: string = '/automation';
        if (options?.root) {
            url = options.root + url;
        }
        AutomationService.initCancelToken();
        if (options?.source) {
            AutomationService.source = options?.source
        }
        return new Promise((resolve, reject) => {
            Http.get(url, { params, cancelToken: AutomationService.source?.token })
                .then(res => {
                    let data = new ResponseList<AutomationRuleModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static updateStatus(id: string, params: Object = {}, options: Options = {}): Promise<Response<AutomationRuleModel>> {
        let url: string = '/automation/' + id + '/status';
        if (options?.root) {
            url = options.root + url;
        }
        AutomationService.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.patch(url, params)
                .then(res => {
                    let data = new Response<AutomationRuleModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static delete(id: string, params: Object = {}, options: Options = {}): Promise<Response<null>> {
        let url: string = '/automation/' + id;
        if (options?.root) {
            url = options.root + url;
        }
        AutomationService.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.delete(url, params)
                .then(res => {
                    let data = new Response<null>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static getAutomationById(id: string, params: Object = {}, options: Options = {}): Promise<Response<AutomationRuleModel>> {
        let url: string = '/automation/' + id;
        if (options?.root) {
            url = options.root + url;
        }
        AutomationService.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.get(url, { params }).then(res => {
                let data = new Response<AutomationRuleModel>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static create(params: AutomationRuleModel): Promise<ResponseList<AutomationRuleModel>> {
        AutomationService.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.post("/automation", params)
                .then(res => {
                    let data = new ResponseList<AutomationRuleModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static update(id: string, params: Object = {}, options: Options = {}): Promise<Response<AutomationRuleModel>> {
        this.initCancelToken();
        let url: string = '/automation/' + id;
        if (options?.root) {
            url = options.root + url;
        }
        return new Promise((resolve, reject) => {
            Http.patch(url, params, { cancelToken: this.source?.token })
                .then(res => {
                    let data = new Response<AutomationRuleModel>(res?.data);
                    return resolve(data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

}