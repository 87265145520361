import { BaseStateModel, UserModel } from '../Models'

export class LoginState extends BaseStateModel {
    public loading: boolean = false;
    public loginModel: UserModel = {
        email: "",
        password: "",
    };
}

export class ForgetState extends BaseStateModel {
    public loading: boolean = false;
    public forgetModel: UserModel = {
        email: ''
    }
}

export class ResetState extends BaseStateModel {
    public loading: boolean = false;
    public resetModel: UserModel = {
        token: '',
        password: '',
        confirmPassword: ''
    }
}