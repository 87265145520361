import { AutomationRuleModel, BaseStateModel, DataTableModel } from "Redux/Models";
import { AutomationService } from "Services";

export class AutomationListState extends BaseStateModel {
    public isAddModalOpen: boolean = false;
    public isEditing: boolean = false;
    public editData: AutomationRuleModel | null = null;
    public dataTable: DataTableModel = {
        pagination: true,
        searchable: true,
        limit: 10,
        active: 'createdAt',
        direction: 'desc',
        actions: [],
        request: AutomationService.getList,
        headers: [
            { key: 'name', label: 'Name', isSort: true },
            { key: 'updatedAt', label: 'Updated At', isSort: true },
        ],
    };
}

export class AddAutomationState extends BaseStateModel {
    public automation: AutomationRuleModel = {
        name: '',
        type: 'DATA_USAGE',
        action: '',
        event: 'LIMIT_MB',
        value: '',
        followUpAction: '',
        status: true,
        group: [],
        tags: [],
        user: [],
        sendEmail: false,
        emailIds: [],
    };
    public email: string = ''
}

