import { CancelTokenSource } from '../../Http';
import { History, Location } from 'history';

import {
    LoadUserModel,
    UserModel,
    AuthUserModel
} from './User.Models';

export class Props<T = unknown> {
    public user!: AuthUserModel;
    public title?: string;
    public history!: History<T>;
    public location!: Location<T>;
    public dispatch!: Function;
    [key: string]: any;
}

export class States {
    [key: string]: any;
}

export class Response<T>{
    public data: T;
    public status: number;
    public message: string;
    public success: boolean;
    public constructor(params = {}) {
        Object.assign(this, params);
    }

    public getStatus(): number {
        return this.status;
    }

    public isSuccess(): boolean {
        return this.success;
    }

    public getData(): T {
        return this.data;
    }
}

export class DataList<T extends {}> {
    public items: Array<T>;
    public total: number;
    public hasItem: boolean;
}

export class ResponseList<T extends {}>{
    public data: DataList<T>;
    public status: number;
    public message: string;
    public success: boolean;
    public constructor(params = {}) {
        Object.assign(this, params);
    }

    public getStatus(): number {
        return this.status;
    }

    public isSuccess(): boolean {
        return this.success;
    }

    public getItems(): Array<T> {
        return this.data.items;
    }

    public getTotal(): number {
        return this.data.total;
    }

    public getData(): DataList<T> {
        return this.data;
    }
}

export class Options {
    constructor(params: Options = {}) {
        Object.assign(this, params);
    }
    [key: string]: any;
    public params?: Object;
    public root?: string;
    public sources?: CancelTokenSource
}

export {
    LoadUserModel,
    UserModel,
    AuthUserModel
};

export {
    InitialAuthStateModel,
    BaseStateModel,
    StateModel,
    DataTableActionModel,
    DataTableHeaderModel,
    DataTableModel,
    InitialConfigStateModel,
    InitialUiStateModel
} from './Base.Models';

export {
    PermissionModel,
    RoleModel,

    ModuleModel,
    ModulePermissionModel
} from './Role.Models';

export {
    CompanyModel,
    BranchModel,
    NetworkModel,
    UsageModel,
    InvoicesModel,
} from './Company.Models'


export {
    PlanModel
} from './Plan.Models'


export {
    PriceModel,
    ZoneModel,
    TadigZoneModel

} from './Price.Models'

export {
    SimModel,
    SimDataTrafficModel,
    EventModel,
    TagModel,
    SimSearchModel,
    SimConnectivityEvent
} from './Sim.Models'

export {
    GroupModel,
} from './Group.Models'

export {
    AutomationRuleModel
} from './Automation.Models';

export {
    AlertModel
} from './Alert.Models';

export {
    NotificationModel
} from './Notification.Models';

export {
    CustomerUsageReportModel
} from './CustomerUsageReport.Models';

export {
    ProviderDataUsageReportModel
} from './ProviderDataUsageReport.Models';

export {
    HighUsageCustomerReportModel
} from './HighUsageCustomerReport.Models';

export {
    CountryWiseDataUsageReportModel
} from './CountryWiseDataUsageReport.Models';

export {
    GeographicDataConsumptionReportModel
} from './GeographicDataConsumptionReport.Models';

export {
    DataUsageTrendReportModel
} from './DataUsageTrendReport.Models';

export {
    DeviceUsageReportModel
} from './DeviceUsageReport.Model';

export {
    CustomerProviderPreferenceReportModel
} from './CustomerProviderPreferenceReport.Models';

export {
    CostAnalysisReportModel
} from './CostAnalysisReport.Models';

export {
    ProviderPerformanceReportModel
} from './ProviderPerformanceReport.Models';

export {
    ProviderCostAnalysisReportModel
} from './ProviderCostAnalysisReport.Models';

export {
    CustomerDetailReportModel
} from './CustomerDetailReport.Models';

export {
    ProviderTotalConsumptionReportModel
} from './ProviderTotalConsumptionReport.Models';

export {
    GroupSimsModel
} from './GroupSims.Models';

export {
    GroupDetailsModel
} from './GroupDetails.Models';
