import { DialogProps } from '@mui/material'
import { MuiBox, MuiButton, MuiDialog, MuiDialogActions, MuiDialogContent, MuiDialogTitle, MuiGrid, MuiIconButton, MuiInputField } from 'Components/MUI'
import { Component } from 'react'
import { StyledCreateModal } from './style';
import { CloseIcon } from 'Helpers/Icons';
import { CompanyBranchCreateState } from 'Redux/States';
import { Formik, FormikHelpers } from 'formik';
import { States } from 'Redux/Models';
import { CompanyBranchCreateValidator } from 'Helpers/Validators';

interface CreateCompanyBranchDialogProps extends DialogProps {
    title: string,
    onFormSubmit: Function
}

class CreateCompanyBranchesModal extends Component<CreateCompanyBranchDialogProps> {
    public state: CompanyBranchCreateState = new CompanyBranchCreateState();

    public handleCompanyBranchModal = (values: States, actions: FormikHelpers<States>) => {
        const { onFormSubmit } = this.props;
        actions.setSubmitting(true);
        if (onFormSubmit) onFormSubmit(values, actions);
    }

    render() {
        const { branch } = this.state;
        const { open, onClose, title, ...restProps }: CreateCompanyBranchDialogProps = this.props;
        return (
            <MuiDialog open={open} onClose={onClose} {...restProps}>
                <StyledCreateModal>
                    <Formik initialValues={branch} onSubmit={this.handleCompanyBranchModal} validationSchema={CompanyBranchCreateValidator}>
                        {(props) => (
                            <MuiBox component='form' noValidate onSubmit={props.handleSubmit}>
                                <MuiBox display='flex' alignItems='center' justifyContent='space-between' className='heading'>
                                    <MuiDialogTitle className='title'>{title}</MuiDialogTitle>
                                    {onClose &&
                                        <MuiIconButton onClick={() => onClose({}, 'backdropClick')}>
                                            <CloseIcon />
                                        </MuiIconButton>
                                    }
                                </MuiBox>
                                <MuiDialogContent className='content'>
                                    <MuiGrid container spacing={2}>
                                        <MuiGrid item sm={6} xs={12}>
                                            <MuiBox component="label" className="fieldTopLabel">Name</MuiBox>
                                            <MuiInputField
                                                placeholder='Name'
                                                name='name'
                                                value={props.values.name}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.name && !!props.errors.name}
                                                helperText={props.touched.name && props.errors.name && String(props.errors.name)}
                                            />
                                        </MuiGrid>
                                        <MuiGrid item sm={6} xs={12}>
                                            <MuiBox component="label" className="fieldTopLabel">Address</MuiBox>
                                            <MuiInputField
                                                placeholder='Address'
                                                name='address'
                                                value={props.values.address}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.address && !!props.errors.address}
                                                helperText={props.touched.address && props.errors.address && String(props.errors.address)}
                                            />
                                        </MuiGrid>
                                        <MuiGrid item sm={12} xs={12}>
                                            <MuiBox component="label" className="fieldTopLabel">Postal Code</MuiBox>
                                            <MuiInputField
                                                placeholder='Postal Code'
                                                name='postalCode'
                                                value={props.values.postalCode}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.postalCode && !!props.errors.postalCode}
                                                helperText={props.touched.postalCode && props.errors.postalCode && String(props.errors.postalCode)}
                                            />
                                        </MuiGrid>
                                        <MuiGrid item sm={12} xs={12}>
                                            <MuiBox component="label" className="fieldTopLabel">City</MuiBox>
                                            <MuiInputField
                                                placeholder='City'
                                                name='city'
                                                value={props.values.city}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.city && !!props.errors.city}
                                                helperText={props.touched.city && props.errors.city && String(props.errors.city)}
                                            />
                                        </MuiGrid>
                                        <MuiGrid item sm={12} xs={12}>
                                            <MuiBox component="label" className="fieldTopLabel">Country</MuiBox>
                                            <MuiInputField
                                                placeholder='Country'
                                                name='country'
                                            />
                                        </MuiGrid>
                                        <MuiGrid item sm={12} xs={12}>
                                            <MuiBox component="label" className="fieldTopLabel">Delivery Address</MuiBox>
                                            <MuiInputField
                                                placeholder='Delivery Address'
                                                name='deliveryAddress'
                                                value={props.values.deliveryAddress}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                error={props.touched.deliveryAddress && !!props.errors.deliveryAddress}
                                                helperText={props.touched.deliveryAddress && props.errors.deliveryAddress && String(props.errors.deliveryAddress)}
                                            />
                                        </MuiGrid>
                                    </MuiGrid>
                                </MuiDialogContent>
                                <MuiDialogActions className='button'>
                                    <MuiBox className='submit-btn'>
                                        <MuiButton type='submit' className='btn create-btn'>
                                            Update
                                        </MuiButton>
                                        {onClose &&
                                            <MuiButton variant="outlined" className='btn cancel-btn' onClick={() => onClose({}, 'backdropClick')}>
                                                Back
                                            </MuiButton>
                                        }
                                    </MuiBox>
                                </MuiDialogActions>
                            </MuiBox>
                        )}
                    </Formik>
                </StyledCreateModal>
            </MuiDialog>
        )
    }
}

export default CreateCompanyBranchesModal;