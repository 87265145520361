import { Map } from 'Helpers/Array';
import { LoginBanner, LoginBottomPattern, LoginTopPattern, Logo } from 'Helpers/Images';
import { Props } from 'Redux/Models';
import { RouteInterface, routes } from 'Routes/Auth';
import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom';
import { MuiBox, MuiGrid, MuiTypography } from 'Components/MUI';

export class AuthLayout extends Component<Props> {
    render() {
        const { classes } = this.props
        return (
            <>
                <MuiBox className={classes.authLayoutMainWrap}>
                    <MuiBox className="section-content">
                        <MuiBox className='form-container'>
                            <MuiGrid container spacing={0} className="form-holder">

                                <MuiGrid item lg={6} md={6} sm={12} className="left-part">
                                    <MuiBox className='top-pattern'>
                                        <img src={LoginTopPattern} alt="" />
                                    </MuiBox>

                                    <MuiBox className="left-inner">
                                        <MuiBox className="top-logo">
                                            <img width="200" height="66" className="img-fluid" src={Logo} alt="" />
                                        </MuiBox>
                                        <Switch>
                                            {Map(routes, (route: RouteInterface, index: number) => {
                                                const title = { title: route.name };
                                                return route.component ? (
                                                    <Route
                                                        key={index}
                                                        path={route.path}
                                                        exact={route.exact}
                                                        render={(rProps: any) => <route.component {...this.props} {...rProps} {...title} />}
                                                    />
                                                ) : null
                                            })}
                                            <Redirect exact to='/login' />
                                        </Switch>
                                    </MuiBox>
                                    <MuiBox className='bottom-pattern'>
                                        <img src={LoginBottomPattern} alt="" />
                                    </MuiBox>
                                </MuiGrid>

                                <MuiGrid item lg={6} md={6} sm={12} className="right-part">
                                    <MuiBox className="sec-bg">
                                        <img src={LoginBanner} alt="" />
                                    </MuiBox>
                                    <MuiBox className="sec-inner">
                                        <MuiBox className='inner-items'>
                                            <MuiTypography className='h1'>Welcome to Ondigo</MuiTypography>
                                            <MuiTypography className='p'>As you log in, envision a world of possibilities where efficiency meets innovation. Together, we're shaping the future of secure and efficient connectivity.</MuiTypography>
                                        </MuiBox>
                                    </MuiBox>
                                </MuiGrid>

                            </MuiGrid>
                        </MuiBox>
                    </MuiBox>
                </MuiBox>
            </>

        )
    }
}

export default AuthLayout;