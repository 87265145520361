import { IconButton, InputAdornment, TextField, TextFieldProps, Theme } from '@mui/material';
import { EyeCloseIcon, EyeIcon } from 'Helpers/Icons';
import { Component, ReactNode } from 'react';
import { States } from '../../Redux/Models';

export default class MuiPasswordField extends Component<TextFieldProps> {
    public state: States = {
        isPasswordShow: false
    };

    public handleShowPassword = (): void => {
        const { isPasswordShow } = this.state;
        this.setState((prevState: States) => (prevState.isPasswordShow = !isPasswordShow));
    };

    public render(): ReactNode {
        const { isPasswordShow } = this.state;
        const { InputProps } = this.props;
        return (
            <TextField
                {...this.props}
                placeholder={this.props.placeholder}
                autoComplete="new-password"
                label={this.props.label}
                type={isPasswordShow ? 'text' : 'password'}
                fullWidth
                InputProps={{
                    ...InputProps,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={this.handleShowPassword}>{isPasswordShow ? <EyeIcon /> : <EyeCloseIcon />}</IconButton>
                        </InputAdornment>
                    )
                }}
                sx={[
                    (theme: Theme) => ({
                        [theme.breakpoints.down("lg")]: {
                            '& .MuiInputBase-root': {
                                paddingRight: 0,
                            }
                        }
                    })
                ]}
            ></TextField>
        );
    }
}
