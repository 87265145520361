import { styled } from "@mui/material/styles";

export const StyledCreateModal = styled("div")(({ theme }) => ({
  padding: ".5rem 1rem",
  ".heading .title": {
    fontSize: "28px",
    fontWeight: 700,
  },
  '.placeHolder': {
    fontSize: '20px',
    top: '22px'
  },
  '.multiSelectFieldMainWrap': {
    '.MuiSelect-select': {
      padding: '8.5px 14px',
    }
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: `${theme.palette.primary.main} !important`,
  },
  "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
    backgroundColor: `${theme.palette.primary.main} !important`,
    opacity: "1 !important",
  },
  "& .MuiCheckbox-root.Mui-checked": {
    color: `${theme.palette.primary.main} !important`,
  },
  ".sim-table-content": {
    // "& .MuiTablePagination-root": {},
    ".list-content": {
      ".list-title": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "1rem",
        ".list-title-text": {
          fontSize: "14px",
          fontWeight: 700,
          color: theme.palette.primary.dark,
        },
      },
      ".list-content-result": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0.9rem",
        "&:nth-of-type(even)": {
          backgroundColor: theme.palette.secondary.light,
        },
        ".list-main-text": {
          fontSize: "14px",
          fontWeight: 300,
          color: theme.palette.primary.dark,
          textAlign: "end",
          width:'25%',

          "&:first-child": {
            width:'50%',
            textAlign: 'start',
          },
        },
      },
    },
  },

  ".content": {
    ".first-row": {
      justifyContent: "space-between",
      marginBottom: "1rem",
      "& .MuiOutlinedInput-root": {
        borderRadius: "10px",
        "& .editRollButton": {
          background: "none",
          minWidth: 0,
          padding: 0,
          color: "#BDBDBD",
        },
      },
    },
    ".secondRowCon": {
      columnCount: 2,
      "&.secondRowLarge": {
        columnCount: 3,
      },
    },
    ".connectivity-text": {
      fontSize: "22px",
      fontWeight: 600,
      color: theme.palette.primary.dark,
    },
    ".second-row": {
      justifyContent: "space-between",
      gap: "1rem",
      ".module-name": {
        fontSize: "14px",
        fontWeight: 600,
        color: theme.palette.primary.dark,
      },
      "& .MuiBox-root": {
        width: "150px",
      },
      "& .MuiFormHelperText-root": {
        fontSize: "16px",
        color: theme.palette.primary.dark,
        fontWeight: "300",
      },
    },
    ".lower-part": {
      marginTop: "2rem",
      marginBottom: ".5rem",
      ".intel-text": {
        fontSize: "22px",
        fontWeight: 600,
        color: theme.palette.primary.dark,
      },
      ".third-row": {
        marginTop: ".5rem",
        columnCount: "3",
        ".label": {
          paddingBottom: "5px",
          display: "flex",
          gap: "1rem",
          justifyContent: "center",

          "& .MuiTypography-root": {
            fontSize: "14px",
            fontWeight: 600,
            color: theme.palette.primary.dark,
          },
        },
      },
    },
  },
  ".save-btn": {
    padding: "5px 20px",
    borderRadius: "10px",
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
  ".back-btn": {
    padding: "5px 20px",
    borderRadius: "10px",
  },
  ".button .submit-btn": {
    display: "flex",
    gap: "1rem",

    ".btn": {
      padding: "5px 35px",
      fontSize: "18px",
      borderRadius: "10px",
    },
    ".create-btn": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
    },
    ".cancel-btn": {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.secondary.main,
      border: `1px solid ${theme.palette.secondary.main}`,
    },
  },

  ".balanceModal": {
    overflowX: "hidden",
    ".title": {
      ".h1": {
        fontSize: "28px",
        fontWeight: 700,
        color: theme.palette.primary.dark,
      },
    },
    ".details-body": {
      '.loadingBox': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '50px 0'
      },
      ".btn-grp": {
        "& .btnGrpGrid": {
          display: "flex",
          justifyContent: "center",
        },
        ".euro-btn": {
          minWidth: 200,
          marginTop: "18px",
          padding: "5px 45px",
          fontSize: "14px",
          borderRadius: "10px",
          backgroundColor: theme.palette.primary.contrastText,
          color: theme.palette.primary.dark,
          border: `1px solid ${theme.palette.secondary.main}`,
        },
        ".euro-btn.selected": {
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.primary.contrastText,
          border: "none",
        },
      },
      ".lower-content": {
        marginTop: "1rem",
        ".first-row": {
          ".euro-text": {
            fontSize: "26px",
            fontWeight: 700,
            color: theme.palette.primary.dark,
          },
          ".val-txt": {
            "& .MuiInputBase-root": {
              borderBottom: `1px solid ${theme.palette.secondary.main}`,
            },
            "& .MuiInputBase-input": {
              fontSize: "26px",
              marginTop: "19px",
              fontWeight: 700,
            },
          },
        },
        ".second-row": {
          ".normal-text": {
            fontSize: "14px",
            fontWeight: 600,
            color: theme.palette.primary.dark,
          },
          ".euro-text": {
            fontSize: "22px",
            fontWeight: 700,
            color: theme.palette.primary.dark,
          },
          ".value-text": {
            fontSize: "22px",
            fontWeight: 700,
            color: theme.palette.primary.dark,
            borderBottom: `1px solid ${theme.palette.secondary.main}`,

            "& .MuiInputBase-input": {
              fontSize: "22px",
              marginTop: "17px",
              fontWeight: 700,
            },
          },
        },
      },

      ".low-content": {
        marginTop: "1rem",
        '.secondRow': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: 130
        },
        ".right-box": {
          ".first-row": {
            ".first-txt": {
              fontSize: "14px",
              fontWeight: 600,
              color: theme.palette.primary.dark,
            },
            ".second-txt": {
              fontSize: "22px",
              fontWeight: 600,
              color: theme.palette.primary.dark,
            },
          },
          ".second-row": {
            ".first-txt": {
              fontSize: "14px",
              fontWeight: 600,
              color: theme.palette.primary.dark,
            },
            ".second-txt": {
              fontSize: "22px",
              fontWeight: 600,
              color: theme.palette.primary.dark,
            },
          },
        },
      },
    },
    ".btn": {
      ".bck-btn": {
        padding: "5px 45px",
        fontSize: "14px",
        borderRadius: "10px",
        backgroundColor: theme.palette.primary.contrastText,
        color: theme.palette.secondary.main,
        border: `1px solid ${theme.palette.secondary.main}`,
      },
      ".nxt-btn": {
        padding: "5px 45px",
        fontSize: "14px",
        borderRadius: "10px",
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
      },
    },
  },
}));
