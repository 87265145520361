import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { MuiBox, MuiFormHelperText } from 'Components/MUI';
import React, { useEffect, useState } from 'react'

const StripeCheckout = React.forwardRef((props: any, ref) => {

    const stripe = useStripe();
    const elements = useElements();

    const [state, setState] = useState({
        message: '',
        loading: false
    });

    React.useImperativeHandle(ref, () => ({
        handleSubmit,
        loading
    }));


    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }: any) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    setState((prev) => ({ ...prev, message: "Payment succeeded!", loading: false }));
                    break;
                case "processing":
                    setState((prev) => ({ ...prev, message: 'Your payment is processing', loading: false }));
                    break;
                case "requires_payment_method":
                    setState((prev) => ({ ...prev, message: 'Your payment was not successful, please try again', loading: false }));
                    break;
                default:
                    setState((prev) => ({ ...prev, message: 'Something went wrong', loading: false }));
                    break;
            }
        });
    }, [stripe]);

    const { loading, message } = state;

    const handleSubmit = async () => {
        if (!stripe || !elements) return;

        setState((prev) => ({ ...prev, loading: true }));

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: window.location.origin,
            }
        })

        if (error.type === 'card_error' || error.type === 'validation_error') {
            setState((prev) => ({ ...prev, loading: false, message: error.message ? error.message : '' }));
            return;
        } else setState((prev) => ({ ...prev, loading: false, message: 'Internal server error' }));
    }

    return (
        <MuiBox>
            <PaymentElement />
            {message && <MuiFormHelperText error={message ? true : false}>{message}</MuiFormHelperText>}
        </MuiBox>
    )
})

export default StripeCheckout;