import { MuiBox, MuiButton, MuiDialog, MuiDialogActions, MuiDialogContent, MuiDialogTitle, MuiIconButton, MuiTypography } from "Components/MUI";
import { Props } from "Redux/Models";
import { Component } from "react";
import { StyledCreateModal } from "./style";
import { Map } from "Helpers/Array";
import { CloseIcon } from "Helpers/Icons";


const headers = [
    { key: "country", label: "Country" },
    { key: "totalConsumption", label: "Data Usage(MB)" },
  ];

class SimUsageCountryModal extends Component<Props> {

    render() {
        const { open, title, onClose } = this.props;
        return (
            <MuiDialog open={open} onClose={onClose}>
                <StyledCreateModal>
                    <MuiBox
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        className="heading"
                    >
                        <MuiDialogTitle className="title">{title}</MuiDialogTitle>
                        {onClose && (
                            <MuiIconButton onClick={() => onClose({}, "backdropClick")}>
                                <CloseIcon />
                            </MuiIconButton>
                        )}
                    </MuiBox>
                    <MuiDialogContent className="sim-table-content">
                        <MuiBox className="list-content">
                            <MuiBox className="list-title">
                                {Map(headers, (head, headIndex) => (
                                    <MuiTypography key={headIndex} className="list-title-text">
                                        {head.label}
                                    </MuiTypography>
                                ))}
                            </MuiBox>
                            
                        </MuiBox>
                        
                    </MuiDialogContent>
                    <MuiDialogActions className="button">
                        <MuiBox className="submit-btn">
                        </MuiBox>
                    </MuiDialogActions>
                    <MuiDialogActions className="button">
                        <MuiBox className="submit-btn">
                            {onClose && (
                                <MuiButton
                                    variant="outlined"
                                    className="btn cancel-btn"
                                    onClick={() => onClose({}, "backdropClick")}
                                >
                                    Cancel
                                </MuiButton>
                            )}
                        </MuiBox>
                    </MuiDialogActions>
                </StyledCreateModal>
            </MuiDialog>
        );
    }
}

export default SimUsageCountryModal;