import React, { Component, SyntheticEvent } from 'react'
import { MuiAutocomplete, MuiBox, MuiChip, MuiInputField, MuiTypography } from './index';
import { AutocompleteChangeDetails, AutocompleteChangeReason, AutocompleteRenderInputParams } from '@mui/material';

interface MuiChipInputFieldProps {
    placeholder: string,
    options?: Array<any>,
    onChange: (event: SyntheticEvent<Element, Event>, value: any[], reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any> | undefined) => void,
    error?: any,
    helperText?: any,
    name: string,
    value?: any[],
    bottomText?: string | undefined,
}

export class MuiChipInputField extends Component<MuiChipInputFieldProps> {
    render() {
        const { placeholder, options = [], onChange, error = false, helperText = '', name = 'autocomplete', value, bottomText = '', ...restProps } = this.props;
        return (
            <MuiBox>
                <MuiAutocomplete
                    {...restProps}
                    multiple
                    options={options}
                    defaultValue={[]}
                    freeSolo
                    value={value}
                    onChange={onChange}
                    renderTags={(value: any[], getTagProps: (arg0: { index: any }) => JSX.IntrinsicAttributes) =>
                        value.map((option: any, index: number) => {
                            return (
                                <MuiChip
                                    color={Array.isArray(helperText) && helperText[index] ? 'error' : 'success'}
                                    key={index}
                                    // variant="outlined"
                                    label={option}
                                    {...getTagProps({ index })}
                                />
                            );
                        })
                    }
                    renderInput={(params: AutocompleteRenderInputParams) => (
                        <MuiInputField
                            {...params}
                            name={name}
                            placeholder={placeholder}
                            error={error}
                            helperText={(error && Array.isArray(helperText)) ? helperText[helperText.length - 1] : helperText}
                        />
                    )}
                />
                {bottomText &&
                    <MuiTypography
                        variant='subtitle2'
                        sx={{
                            marginTop: '-10px',
                            color: '#8e8b8b',
                            fontSize: '12px !important',
                        }}
                    >
                        {bottomText}
                    </MuiTypography>}
            </MuiBox>
        )
    }
}

export default MuiChipInputField