import { SvgIconComponent } from "@mui/icons-material";
import { PermissionModel, RoleModel } from "./Role.Models";
import { UserModel } from "./User.Models";

export class InitialAuthStateModel {
    [key: string]: any;
    public token: string;
    public isAuthenticated: boolean;
    public isExpired: boolean;
    public user: UserModel
}

export class InitialConfigStateModel {
    [key: string]: any;
    public roles: Array<RoleModel>;
    public permissions: Array<PermissionModel>;
}

export class InitialUiStateModel {
    [key: string]: any;
    public isMobileOpen: boolean;
    public isDark: boolean;
}

export abstract class BaseStateModel {
    public errors = {
        has: (e: string): boolean => {
            return false;
        },
        first: (e: string): string => {
            return '';
        }
    };
}

export class DataTableHeaderModel {
    public label: string;
    public key: string;
    public isSort: boolean;
    public style?: {
        [key: string]: any;
    };
    public render?: Function;
}

type Color = 'inherit' | 'primary' | 'secondary' | 'default';
type Direction = 'asc' | 'desc';

export class DataTableActionModel {
    public icon: SvgIconComponent | any;
    public key: string;
    public color?: Color;
    public tooltip?: string;
    public match?: any;
}

class Status {
    public key: string;
    public label: string;
    public width?: string;
}

export class DataTableModel {
    [key: string]: any;
    public request: Function;
    public status?: Status;
    public actionWidth?: string;
    public actions?: Array<DataTableActionModel>;
    public headers: Array<DataTableHeaderModel>;
    public pagination?: boolean;
    public limit?: number
    public active?: string
    public direction?: Direction
}

export class StateModel {
    public Auth: InitialAuthStateModel;
    public Config: InitialConfigStateModel;
    public Ui: InitialUiStateModel;
}

export abstract class BaseModel {
    [key: string]: any;
    public createdAt?: string;
    public UpdatedAt?: string;
    public deletedAt?: string;
}