export enum UiActionTypes {
    Mode = 'Mode',
    MenuToggle = 'MenuToggle'
}

export const ToggleMode = (isDark: boolean) => {
    return {
        type: UiActionTypes.Mode,
        payload: isDark
    }
}

export const ToggleMenu = (mode: boolean) => {
    return {
        type: UiActionTypes.MenuToggle,
        payload: mode
    }
}

export class UiActions {
    type: UiActionTypes;
    payload: any;
}