import { Http } from 'Http';
import BaseService from './BaseService';
import { Options, Response, RoleModel } from 'Redux/Models';

export default class RoleService extends BaseService {
    public static getList(params: Object = {}, options: Options = {}): Promise<Response<RoleModel>> {
        let url: string = '/roles';
        if (options?.root) {
            url = options.root + url;
        }
        RoleService.initCancelToken();
        if (options?.source) {
            RoleService.source = options?.source;
        }
        return new Promise((resolve, reject) => {
            Http.get(url, { params, cancelToken: RoleService.source?.token })
                .then((res) => {
                    let data = new Response<RoleModel>(res.data);
                    return resolve(data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }

    public static createRole(params: Object, options: Options = {}): Promise<Response<RoleModel>> {
        let url: string = '/roles';
        if (options?.root) {
            url = options.root + url;
        }
        return new Promise((resolve, reject) => {
            Http.post(url, params).then(res => {
                let data = new Response<RoleModel>(res?.data);
                return resolve(data);
            }).catch(err => {
                return reject(err);
            });
        });
    }

    public static updateRole(id: string, params: Object = {}, options: Options = {}): Promise<Response<RoleModel>> {
        let url: string = '/roles/' + id;
        if (options?.root) {
            url = options.root + url;
        }
        return new Promise((resolve, reject) => {
            Http.patch(url, params).then(res => {
                let data = new Response<RoleModel>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    public static deleteRole(id: string, params: Object, options: Options = {}): Promise<Response<null>> {
        let url: string = `/roles/${id}`;
        if (options?.root) {
            url = options.root + url;
        }
        return new Promise((resolve, reject) => {
            Http.delete(url, params).then(res => {
                let data = new Response<null>(res?.data);
                return resolve(data);
            }).catch(err => {
                return reject(err);
            });
        });
    }

    public static getRoleById(id: string, params: Object = {}, options: Options = {}): Promise<Response<RoleModel>> {
        let url: string = '/roles/' + id;
        if (options?.root) {
            url = options.root + url;
        }
        return new Promise((resolve, reject) => {
            Http.get(url, { params }).then(res => {
                let data = new Response<RoleModel>(res?.data);
                return resolve(data);
            })
                .catch(err => {
                    return reject(err);
                });
        });
    }

}
