import { Http } from "Http";
import BaseService from "./BaseService";
import { Response } from "Redux/Models";

export default class AlertService extends BaseService {

    public static alertSummary(): Promise<Response<any>> {
        AlertService.initCancelToken();
        return new Promise((resolve, reject) => {
            Http.get('/alert-summary').then(resp => {
                let data = new Response<any>(resp.data);
                return resolve(data);
            }).catch(err => {
                return reject(err)
            })
        })
    }

}