import { GetDateTime } from "Helpers/String";
import { BaseStateModel, DataTableModel, ModuleModel, PermissionModel, RoleModel } from "Redux/Models";
import { RoleService } from "Services";

export class RoleListState extends BaseStateModel {
    public isRoleCreateModalOpen: boolean = false;
    public isRoleEditing: boolean = false;
    public editRoleData: RoleModel | null = null;
    public dataTable: DataTableModel = {
        pagination: true,
        searchable: true,
        limit: 10,
        active: 'createdAt',
        direction: 'desc',
        actions: [],
        request: RoleService.getList,
        headers: [
            { key: 'name', label: 'Role Name', isSort: true },
            { key: 'UpdatedAt', label: 'Updated At', isSort: true, render: (item: RoleModel) => item.UpdatedAt ? GetDateTime(item.UpdatedAt) : '' },
        ],
    };
}

export class CreateRoleState extends BaseStateModel {
    public modules: Array<ModuleModel> = [];
    public permissions: Array<PermissionModel> = [];
    public role: RoleModel = {
        isInsightsOn: false,
        name: '',
        ipPermissions: [],
        moduleRolePermissions: []
    }
}