import { UiActionTypes, UiActions } from "Redux/Actions";
import { InitialUiStateModel } from "Redux/Models";

const initialState: InitialUiStateModel = {
    isDark: false,
    isMobileOpen: true
}

const Ui = (state = initialState, action: UiActions): InitialUiStateModel => {
    const { type, payload } = action;
    switch (type) {
        case UiActionTypes.Mode:
            return {
                ...state,
                isDark: payload,
            }
        case UiActionTypes.MenuToggle:
            return {
                ...state,
                isMobileOpen: payload,
            }
        default:
            return state;
    }
}

export default Ui;